import React, { Component } from 'react';
import './Styles/Input.scss';

export default class Input extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        let { onChange } = this.props;
        let value = event.target.value;
        if(onChange) {
            onChange(value);
        }
    }



    render() {

        let { value, label, type, className, disabled, error, errorMessage } = this.props;

        if(error) {
            className = className ? className + " error" : "error";
        }

        return <div className={`input ${className}`}>
            {/* <div className="input-label">{label}</div> */}
            { errorMessage && <div className="error-message">{errorMessage}</div>}
            <input disabled={disabled} type={type || "text"} placeholder={label} value={value || ""} onChange={this.onChange} />
            
        </div>



    }

}