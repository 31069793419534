import { LOAD_CAREDESCRIPTIONPARTS, UNLOAD_CAREDESCRIPTIONPARTS, UPDATE_CAREDESCRIPTIONPARTS } from '../Reducers/CareDescriptionParts';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';


export function loadCareDescriptionParts(params) {
    return (dispatch) => {

        dispatch({
            type: LOAD_CAREDESCRIPTIONPARTS,
            loading: true
        })
        EndpointService.get("/freelancerportal/api/caredescriptionparts").then((response) => {
            
            dispatch({
                type: LOAD_CAREDESCRIPTIONPARTS,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function getCareDescriptionParts(params, reload) {
    return dispatch => {
        // syncing logic
        dispatch(loadCareDescriptionParts(params));
    }
}

