import { LOAD_IRREGULARDAYS, UNLOAD_IRREGULARDAYS, UPDATE_IRREGULARDAYS } from '../Reducers/IrregularDays';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';


export function loadIrregularDays(params) {
    return (dispatch) => {

        dispatch({
            type: LOAD_IRREGULARDAYS,
            loading: true
        })
        return EndpointService.get("/freelancerportal/api/irregulardays").then((response) => {
            
            dispatch({
                type: LOAD_IRREGULARDAYS,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function getIrregularDays(params, reload) {
    return dispatch => {
        // syncing logic
        dispatch(loadIrregularDays(params));
    }
}

