import { LOAD_GENERATEDWEEKS, UNLOAD_GENERATEDWEEKS, UPDATE_GENERATEDWEEKS } from '../Reducers/GeneratedWeeks';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';
import { collection } from '../testdata/generatedweeks';



export function loadGeneratedWeeks(params) {
    return (dispatch) => {

        dispatch({
            type: LOAD_GENERATEDWEEKS,
            loading: true
        })
        EndpointService.get("/freelancerportal/api/generatedweeks").then((response) => {
            
            dispatch({
                type: LOAD_GENERATEDWEEKS,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function getGeneratedWeeks(params, reload) {
    return dispatch => {
        // syncing logic
        dispatch(loadGeneratedWeeks(params));
    }
}

