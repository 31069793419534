import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

export default function Modal ({ children, open, onClose }) {

    if(!open) {
        return null;
    }

    return ReactDOM.createPortal(<div className="modal-backdrop" onClick={onClose}>

        <div className="modal" onClick={(e) => e.stopPropagation()}>
            <div className="modal-close"><i className="zmdi zmdi-close" onClick={onClose}/></div>
                
            {children}
            

        </div>
        
    </div>, document.body);
}