import { LOAD_SUBJECTS, UNLOAD_SUBJECTS, UPDATE_SUBJECTS } from '../Reducers/Infotheek';
import { LOAD_ARTICLES, UNLOAD_ARTICLES, UPDATE_ARTICLES } from '../Reducers/Infotheek';
import { LOAD_ATTACHMENTS, UNLOAD_ATTACHMENTS, UPDATE_ATTACHMENTS } from '../Reducers/Infotheek';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';
import { collection } from '../testdata/generatedweeks';

export function loadSubjects(params) {
    return (dispatch) => {
        dispatch({
            type: LOAD_SUBJECTS,
            loading: true
        })
        EndpointService.get("/freelancerportal/api/subjects").then((response) => {
            dispatch({
                type: LOAD_SUBJECTS,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
        }).catch(() => {})
    }
}

export function getSubjects(params, reload) {
    return dispatch => {
        dispatch(loadSubjects(params));
    }
}

export function loadArticles(params) {
    return (dispatch) => {
        dispatch({
            type: LOAD_ARTICLES,
            loading: true
        })
        EndpointService.get("/freelancerportal/api/articles").then((response) => {
            dispatch({
                type: LOAD_ARTICLES,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
        }).catch(() => {})
    }
}

export function getArticles(params, reload) {
    return dispatch => {
        dispatch(loadArticles(params));
    }
}



export function loadAttachments(params) {
    return (dispatch) => {
        dispatch({
            type: LOAD_ATTACHMENTS,
            loading: true
        })
        EndpointService.get("/freelancerportal/api/attachments").then((response) => {
            dispatch({
                type: LOAD_ATTACHMENTS,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
        }).catch(() => {})
    }
}

export function getAttachments(params, reload) {
    return dispatch => {
        dispatch(loadAttachments(params));
    }
}

