export function generateDisplayName(input) {
    let fullname = "";
    if (input) {
        if (input.get('initials')) {
            fullname += input.get('initials') + " ";
        }
        if (!input.get('initials') && input.get('firstname')) {
            fullname += input.get('firstname') + " ";
        }
        if (input.get('insertion')) {
            fullname += input.get('insertion') + " ";
        }
        if (input.get('lastname')) {
            fullname += input.get('lastname');
        }
    }
    return fullname;
}

export function generateFormalDisplayName(input) {
    let aanhef = "Mw. ";
    if(input.get('gender') == 'M' || input.get('gender') == 'm') {
        aanhef = "Dhr. ";
    }
    return aanhef + generateDisplayName(input);
}

export function generateInformalDisplayName(input) {
    let fullname = "";
    if (input) {
        
        if (input.get('firstname')) {
            fullname += input.get('firstname') + " ";
        }
        if (input.get('insertion')) {
            fullname += input.get('insertion') + " ";
        }
        if (input.get('lastname')) {
            fullname += input.get('lastname');
        }
    }
    return fullname;
}

export function generateSemiFormalDisplayName(input) {
    let aanhef = "Mw. ";
    if(input.get('gender') == 'M' || input.get('gender') == 'm') {
        aanhef = "Dhr. ";
    }
    return aanhef + generateInformalDisplayName(input);
}