import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleMainMenu } from './UIState';

const mapStateToProps = (state) => ({
    menuOpened: state.getIn(['ui','mainMenuOpened'])
})

const mapDispatchToProps = (dispatch) => ({
    toggleMenuOpened: () => dispatch(toggleMainMenu())
})

class MenuToggle extends Component { 


    render() {

        let { toggleMenuOpened, menuOpened } = this.props;

        let className = "menu-toggle";
        if(menuOpened) {
            className += " open";
        }

        return <div className="menu-toggle-container">
            <div className={className} onClick={toggleMenuOpened}>
                <div className="menu-toggle-line top" />
                <div className="menu-toggle-line center" />
                <div className="menu-toggle-line bottom" />
            </div>
        </div>
        
        // <a href="javascript:;" onClick={toggleMenuOpened}>{menuOpened ? "sluit" : "open"}</a>

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(MenuToggle);