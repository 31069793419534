import { useState, createContext, useContext, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import swal from 'sweetalert2';
import { useConnection } from './Connection';

const MissedEntriesContext = createContext();

export const useMissedEntries = () => useContext(MissedEntriesContext);

export const withMissedEntries = (Component) => (props) => {
    const { currentMissedEntry } = useMissedEntries();
    return <Component {...props} currentMissedEntry={currentMissedEntry} />
}

const MissedEntriesProvider = ({ children }) => {
    
    const [currentMissedEntry, setCurrentMissedEntry] = useState(null);
    const {connected}  = useConnection(); 
    
    const systemComesBackOnline = () => {
        if(!connected) {
            return;
        }
        
        let unfinishedEntries = [];
        if(localStorage) {
            for(var key in localStorage) {
                if(typeof key === 'string' && key.startsWith('entry-')) {
                    unfinishedEntries.push(key.replace(/entry\-(.*?)/, '$1'));
                }
            }
        }

        if(unfinishedEntries.length > 0) {
            swal.fire({
                text: `Terug naar niet afgeronde rapportage bij client ${unfinishedEntries[0]}`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ja',
                cancelButtonText: 'Nee, verwijder deze rapportage'
            }).then((result) => {
                if (result.value) {
                    console.log("Go to unfinished entry: ", unfinishedEntries[0]);
                    setCurrentMissedEntry(() => unfinishedEntries[0]);
                    window.location.hash = '#/clients/' + unfinishedEntries[0];
                } else {
                    setCurrentMissedEntry(() => undefined);
                }
            })
        }
    }

    const checkOtherMissingEntries = systemComesBackOnline;


    useEffect(() => {
        window.addEventListener('online', systemComesBackOnline);
        return () => {
            window.removeEventListener('online', systemComesBackOnline);
        }
    }, []);

    console.log("MissedEntriesProvider: ", currentMissedEntry);

    return (
        <MissedEntriesContext.Provider value={{ currentMissedEntry: currentMissedEntry, setCurrentMissedEntry, checkOtherMissingEntries }}>
            {children}
        </MissedEntriesContext.Provider>
    )
}

export default MissedEntriesProvider;