import Immutable, { Map } from 'immutable';

export const LOAD_UNBOOKEDPLANNING = 'LOAD_UNBOOKEDPLANNING';
export const UNLOAD_UNBOOKEDPLANNING = 'UNLOAD_UNBOOKEDPLANNING';
export const UPDATE_UNBOOKEDPLANNING = 'UPDATE_UNBOOKEDPLANNING';


export default function BookingsReducer(state, action) {

    switch(action.type) {

        case LOAD_UNBOOKEDPLANNING:


            state = state.setIn(['profilePlanningLoading'], action.loading);
        

            if(action.collection) {
                
                state = state.setIn(['profileplanning'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['profilePlanningLastSync'], action.lastSynced);
            }

            return state;
        case UNLOAD_UNBOOKEDPLANNING:

            return state;
        case UPDATE_UNBOOKEDPLANNING:

            return state;
            default:

    }

    return state || Map();

}