import React, { Component } from 'react';
import './Styles/CheckBox.scss';

export default class CheckBox extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        let { onChange } = this.props;
        let value = event.target.value;
        if(onChange) {
            onChange(value);
        }
    }

    render() {

        let { value, label, className, children } = this.props;

        return <label className={`checkbox ${className}`}>
            <input type="checkbox" value={!!value} checked={!!value} onChange={this.onChange} />
            <div>
                {label || children}
            </div>
        </label>

    }

}