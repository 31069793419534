import Immutable, { Map } from 'immutable';

export const LOAD_GENERATEDWEEKS = 'LOAD_GENERATEDWEEKS';
export const UNLOAD_GENERATEDWEEKS = 'UNLOAD_GENERATEDWEEKS';
export const UPDATE_GENERATEDWEEKS = 'UPDATE_GENERATEDWEEKS';


export default function GeneratedWeeks(state, action) {

    switch(action.type) {

        case LOAD_GENERATEDWEEKS:

            if(action.loading) {
                state = state.setIn(['loading'], true);
            }

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            return state;
        case UNLOAD_GENERATEDWEEKS:

            return state;
        case UPDATE_GENERATEDWEEKS:

            return state;

            default:
    }

    return state || Map();

}