import React, { Fragment } from 'react';
import Immutable from 'immutable';
import { generateDisplayName } from '../../Utils/Person';
// import Page from 'Usc/Modules/FreelancerInvoicing/Components/Pages/Page';
// import PageContent from 'Usc/Modules/FreelancerInvoicing/Components/Pages/PageContent';
// import PageFooter from 'Usc/Modules/FreelancerInvoicing/Components/Pages/PageFooter';
import moment from 'moment';

function getNlDate(date) {



    return moment(Number(date)).format('DD-MM-YYYY');
    


}

class CareplanPage extends React.Component {
    getGroupFromParts(group){
        let {caredescriptionparts} = this.props;
        if (!caredescriptionparts) return undefined;

        return caredescriptionparts.filter((part) => {
            return part.get('id') == group.get('id');
        }).first();
    }

    isGroupAvailable(group){
        return this.getGroupFromParts(group);
    }

    isItemAvailable(group, item){
        return group.get('items').filter((groupitem) => {
            return item.get('id') == groupitem.get('id');
        }).size > 0;
    }

    getItemFromGroup(group, item) {
        return group.get('items').filter((groupitem) => {
            return item.get('id') == groupitem.get('id');
        }).first();
    }

    renderFieldIfAvailable(field) {
        let toRender = field;
        if(field == 0) toRender = "";
        if(!field) toRender = "";
        return toRender;
    }

    renderFieldWithBr(field) {
        let rendered = this.renderFieldIfAvailable(field);
        return rendered ? <data>rendered<br /></data> : rendered;
    }
    
    

    render() {
        let {selectedclient} = this.props;

        let { renderFieldIfAvailable, renderFieldWithBr } = this;

        let svbLabel = "Klantnr. SVB";
        if(selectedclient.getIn(['invoice','careoffice']) == 'zorgkantoor-haaglanden') {
            svbLabel = "Relatienummer";
        }

        return (<Fragment>
            <PurePage className="careplan-page" noHeader={true}>


                <PageContent>
                    <h1 className="careplan-header">Cli&euml;ntinformatie voor {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient)}</h1>
                    <div className="careplan-info-block">
                        <h2 className="careplan-sub-header">Gegevens cli&euml;nt</h2>
                        <p className="careplan-block-50">
                            <span>{selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient)}<br/></span>
                            <span>
                                {renderFieldIfAvailable(selectedclient.getIn(['address', 'street']))} {renderFieldIfAvailable(selectedclient.getIn(['address', 'number']))} {renderFieldIfAvailable(selectedclient.getIn(['address', 'addition']))}
                                { (selectedclient.getIn(['address', 'street']) || selectedclient.getIn(['address','number']) || selectedclient.get(['address', 'addition'])) && <br /> || "" }

                                {(() => {
                                    if((selectedclient.getIn(['address', 'postalcode']) || selectedclient.getIn(['address','location'])))
                                    return <data>{renderFieldIfAvailable(selectedclient.getIn(['address', 'postalcode']))}{selectedclient.getIn(['address', 'postalcode']) && <data>&nbsp;&nbsp;</data> || ""}{renderFieldIfAvailable(selectedclient.getIn(['address', 'location']))}<br /></data>;
                                    return ""
                                })()}
                                
                                {(selectedclient.get('phonenumbers')||[]).map((phonenumber) => {
                                    let numberInfo = "";
                                    if (phonenumber.get('type') && phonenumber.get('type') != ''){
                                        numberInfo = ` (${phonenumber.get('type')})`;
                                    }
                                    return <span>{phonenumber.get('number') + numberInfo}<br /></span>;
                                })}
                            Verzekering {selectedclient.get('insurance')} nummer {selectedclient.get('insurancenumber')}
                            </span>
                        </p>
                        <p className="careplan-block-50">
                            {/* Geboortedatum {getNlDate(selectedclient.get('dateofbirth'))}<br /> */}
                            Geboortedatum {selectedclient.get('dateofbirthstring')}<br />
                        BSN {selectedclient.get('bsn')}<br />
                    {selectedclient.get('invoiceforsvb') && selectedclient.get('svbnumber') && `${svbLabel} ${selectedclient.get('svbnumber')}` || ""}
                        </p>
                    </div>

                    {(function(){
                        if (selectedclient.getIn(['partner', 'iswidow']) || selectedclient.getIn(['partner', 'lastname'])){
                            return (
                                <div className="careplan-info-block">
                                    <h2 className="careplan-sub-header">Partner</h2>

                                    {(function(){
                                        if (selectedclient.getIn(['partner', 'iswidow'])){
                                            return (
                                                <p className="careplan-block-50">
                                                    {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} is { selectedclient.get('gender') == 'M' ? 'weduwnaar' : 'weduwe' } sinds {selectedclient.getIn(['partner', 'widowsince'])}
                                                </p>
                                            )
                                        }
                                        else{
                                            return (
                                                <Fragment>
                                                    <p className="careplan-block-50">
                                                        {selectedclient.getIn(['partner', 'gender']) == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient.getIn(['partner']))}
                                                    </p>
                                                    <p className="careplan-block-50">
                                                        {/* Geboortedatum {getNlDate(selectedclient.getIn(['partner', 'dateofbirth']))} */}
                                                        Geboortedatum {selectedclient.getIn(['partner', 'dateofbirthstring'])}
                                                    </p>
                                                </Fragment>
                                            )
                                        }
                                    }).call(this)}
                                </div>
                            )
                        }
                    }).call(this)}


                    <div className="careplan-info-block">
                        <h2 className="careplan-sub-header">Contactpersonen</h2>
                        {selectedclient.getIn(['contactpersons']).map((contactperson, index) => {
                            return (
                                <p key={index} className="careplan-block-50">
                                    {contactperson.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '}{generateDisplayName(contactperson)}<br />
                                    {renderFieldIfAvailable(contactperson.getIn(['street']))} {renderFieldIfAvailable(contactperson.getIn(['number']))} {renderFieldIfAvailable(contactperson.getIn(['addition']))}
                                    { (contactperson.getIn(['street']) || contactperson.getIn(['number']) || contactperson.get(['addition'])) && <br /> || "" }
                                    
                                    {(() => {
                                        if((contactperson.getIn(['postalcode']) || contactperson.getIn(['location'])))
                                        return <Fragment>{contactperson.get('postalcode', '')}{contactperson.getIn(['postalcode']) && <Fragment>&nbsp;&nbsp;</Fragment> || ""}{contactperson.get('location', '')}<br /></Fragment>;
                                        return ""
                                    })()}
                                    
                                    {contactperson.get('phonenumber', '')} {contactperson.get('mobilenumber', '')}
                                    { (contactperson.getIn(['phonenumber']) || contactperson.getIn(['mobilenumber'])) && <br /> || "" }
                                    {contactperson.get('email', '')}
                                    { contactperson.get('email') && <br /> || ""}
                                    {contactperson.get('type') == 'overig' ? contactperson.get('relationship', '') : contactperson.get('type', '')} {contactperson.get('firstname', '')}
                                </p>
                            )
                        })}
                    </div>
                    {(function(){
                        if (selectedclient.get('doctor')){
                            return (
                                <div className="careplan-info-block">
                                    <h2 className="careplan-sub-header">Huisarts</h2>
                                    <p className="careplan-block-50">
                                        Dr. {generateDisplayName(selectedclient.get('doctor'))}
                                        <br />
                                        {selectedclient.getIn(['doctor', 'street'], '')} {selectedclient.getIn(['doctor', 'number'], '') == 0 ? "" : selectedclient.getIn(['doctor', 'number'], '')} {selectedclient.getIn(['doctor', 'addition'], '')}
                                        <br />
                                        {selectedclient.getIn(['doctor', 'postalcode'], '')}&nbsp;&nbsp;{selectedclient.getIn(['doctor', 'location'], '')}
                                        <br />
                                        {(selectedclient.getIn(['doctor', 'contactdetails', 'phonenumbers']) || []).map((phonenumber) => {
                                            let typeInformation = "";
                                            if (phonenumber.get('type') && phonenumber.get('type') != ''){
                                                typeInformation = ` (${phonenumber.get('type')})`;
                                            }
                                            return <span>{phonenumber.get('number', '')}{typeInformation}<br /></span>
                                        })}
                                    </p>
                                </div>
                            );
                        }
                    }).call(this)}
                    {(function(){
                        if (selectedclient.get('pharmacy')){
                            return (
                                <div className="careplan-info-block">
                                    <h2 className="careplan-sub-header">Apotheek</h2>
                                    <p className="careplan-block-50">
                                        {selectedclient.getIn(['pharmacy', 'name'])}
                                        <br />
                                        {selectedclient.getIn(['pharmacy', 'street'])} {selectedclient.getIn(['pharmacy', 'number']) == 0 ? "" : selectedclient.getIn(['pharmacy', 'number'])} {selectedclient.getIn(['pharmacy', 'addition'])}
                                        <br />
                                        {selectedclient.getIn(['pharmacy', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['pharmacy', 'location'])}
                                        <br />
                                        {selectedclient.getIn(['pharmacy', 'phonenumbers'])}
                                    </p>
                                    <p className="careplan-block-50">
                                        <strong>Openingstijden</strong><br />
                                        {(selectedclient.getIn(['pharmacy', 'openingtimes']) || []).map((openingtime) => {
                                            return (
                                                <Fragment key={openingtime.get('dayname')}>
                                                    <span>{openingtime.get('starttime')} - {openingtime.get('endtime')} {openingtime.get('dayname')} <br /></span>
                                                </Fragment>
                                            );
                                        })}
                                    </p>
                                    <div className="careplan-full-block">
                                        Bel in geval van spoed buiten deze openingstijden de eigen apotheek om te weten welke de dienstdoende apotheek is.
                                    </div>
                                </div>
                            );
                        }
                    }).call(this)}

                    {(function(){
                        if (selectedclient.getIn(['generalpractice1'])){
                            return (
                                <div className="careplan-info-block">
                                    <h2 className="careplan-sub-header">Huisartsenpost</h2>
                                    <p className="careplan-block-50">
                                        {selectedclient.getIn(['generalpractice1', 'name'])}
                                        <br />
                                        {selectedclient.getIn(['generalpractice1', 'street'])} {selectedclient.getIn(['generalpractice1', 'number']) == 0 ? "" : selectedclient.getIn(['generalpractice1', 'number'])} {selectedclient.getIn(['generalpractice1', 'addition'])}
                                        <br/>
                                        {selectedclient.getIn(['generalpractice1', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['generalpractice1', 'location'])}
                                        <br/>
                                        {selectedclient.getIn(['generalpractice1', 'phonenumbers'])}
                                    </p>
                                </div>
                            )
                        }
                    }).call(this)}


                    <div className="careplan-info-block">
                        <h2 className="careplan-sub-header">Belangrijke telefoonnummers</h2>
                        <p className="careplan-full-block">
                            Vegro 0900 - 388 77 66
                            <br />
                            Alarm 112 (voor ambulance, politie en brandweer)
                            <br />
                            Politie 0900 - 8844 (geen spoed)
                            <br />
                            USC Tijdens kantoor uren 071 - 56 222 90. Buiten kantooruren 06 - 450 99 490
                        </p>
                    </div>

                    { selectedclient.getIn(['caredescription', 'careplan', 'importantinfo']) && <div className="careplan-info-block">
                        <h2 className="careplan-sub-header">Overige informatie</h2>
                        
                        <pre className="careplan-pre-style p-behaviour">{selectedclient.getIn(['caredescription', 'careplan', 'importantinfo'])}</pre>
                        

                    </div> || ''}

                    <div className="careplan-info-block">
                        <h2 className="careplan-sub-header">Administratie</h2>
                        {(function(){
                            let careStartDate = "";
                            if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'carestartdate'])){
                                careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'carestartdate']));
                            }

                            if (selectedclient.getIn(['caredescription', 'indication', 'wlz', 'carestartdate'])){
                                careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'carestartdate']));
                            }


                            if (selectedclient.getIn(['caredescription', 'indication', 'wmo', 'carestartdate'])){
                                careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'carestartdate']));
                            }

                            if (selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'carestartdate'])){
                                careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'carestartdate']));
                            }

                            if (selectedclient.getIn(['caredescription', 'indication', 'carestartdate'])){
                                careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'carestartdate']))
                            }

                            return (
                                <p className="careplan-full-block">
                                    <strong>Startdatum zorg</strong>
                                    <br />
                                    {careStartDate}
                                </p>
                            )
                        }).call(this)}
                        <div className="careplan-full-block">
                            {(function(){
                                if (selectedclient.getIn(['invoice', 'hasInvoicingAddress'])){
                                    return (
                                        <p>
                                            <strong>Factuuradres</strong>
                                            <br />
                                            {selectedclient.getIn(['invoiceaddress','name'])}
                                            <br />
                                            {selectedclient.getIn(['invoiceaddress', 'street'])} {selectedclient.getIn(['invoiceaddress', 'number']) == 0 ? "" : selectedclient.getIn(['invoiceaddress', 'number'])} {selectedclient.getIn(['invoiceaddress', 'addition'])}
                                            <br />
                                            {selectedclient.getIn(['invoiceaddress', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['invoiceaddress', 'location'])}
                                        </p>
                                    )
                                }
                                else{
                                    return (
                                        <p>
                                            <strong>Factuuradres</strong>
                                            <br />
                                            {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient)}
                                            <br />
                                            {selectedclient.getIn(['address', 'street'])} {selectedclient.getIn(['address', 'number']) == 0 ? "" : selectedclient.getIn(['address', 'number'])} {selectedclient.getIn(['address', 'addition'])}
                                            <br />
                                            {selectedclient.getIn(['address', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['address', 'location'])}
                                        </p>
                                    )
                                }
                            }).call(this)}

                            {(function(){
                                if (selectedclient.get('needsintermediar')){
                                    return (
                                        <p>
                                            <strong>Tekenbevoegde voor overeenkomsten</strong>
                                            <br />
                                            {selectedclient.getIn(['intermediar', 'gender']) == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient.getIn(['intermediar']))}
                                            <br />
                                            {selectedclient.getIn(['intermediar', 'street'])} {selectedclient.getIn(['intermediar', 'number']) == 0 ? "" : selectedclient.getIn(['intermediar', 'number'])} {selectedclient.getIn(['intermediar', 'addition'])}
                                            <br />
                                            {selectedclient.getIn(['intermediar', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['intermediar', 'location'])}
                                        </p>
                                    )
                                }
                                else{
                                    return (
                                        <p>
                                            <strong>Tekenbevoegde voor overeenkomsten</strong>
                                            <br />
                                            {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} is zelf bevoegd.
                                        </p>
                                    )
                                }
                            }).call(this)}

                        </div>

                        {(function(){
                            if (selectedclient.getIn(['administrationbyother'])){
                                return (
                                    <div className="careplan-info-block">
                                        <p className="careplan-full-block">
                                            <strong>Gewaarborgde hulp</strong>
                                            <br />
                                            {selectedclient.getIn(['administrativecontact', 'gender']) == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient.getIn(['administrativecontact']))}
                                            <br />
                                            {selectedclient.getIn(['administrativecontact', 'street'])} {selectedclient.getIn(['administrativecontact', 'number']) == 0 ? "" : selectedclient.getIn(['administrativecontact', 'number'])}{selectedclient.getIn(['administrativecontact', 'addition'])}
                                            <br />
                                            {selectedclient.getIn(['administrativecontact', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['administrativecontact', 'location'])}
                                            <br />
                                            {selectedclient.getIn(['administrativecontact', 'phonenumber'])} {selectedclient.getIn(['administrativecontact', 'mobilenumber'])}
                                        </p>
                                    </div>
                                )
                            }
                        }).call(this)}

                        {(function(){
                            let indicationZvw = "";
                            let indicationWlz = "";
                            let indicationIndividual = "";
                            let indicationWmoHome = "";
                            if (selectedclient.getIn(['caredescription','indication','hasZvw'])){
                                let pgbStartDate = "";
                                let startDateIndication = "";
                                let endDateIndication = "";
                                let pvHours = "";
                                let vpHours = "";
                                if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'pgbstartdate'])){
                                    pgbStartDate = (
                                        <span>Startdatum Pgb {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'pgbstartdate']))}<br /></span>
                                    )
                                }
                                if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'startdate'])){
                                    startDateIndication = (
                                        <span>Startdatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'startdate']))}<br /></span>
                                    )
                                }
                                if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'enddate'])){
                                    endDateIndication = (
                                        <span>Einddatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'enddate']))}<br /></span>
                                    )
                                }
                                if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'pv'])){
                                    pvHours = (
                                        <Fragment>
                                            Persoonlijke verzorging {selectedclient.getIn(['caredescription', 'indication', 'zvw', 'pv'])} uur per week
                                            <br />
                                        </Fragment>
                                    )
                                }
                                if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'vp'])){
                                    vpHours = (
                                        <Fragment>
                                            Verpleging {selectedclient.getIn(['caredescription', 'indication', 'zvw', 'vp'])} uur per week
                                            <br />
                                        </Fragment>
                                    )
                                }

                                indicationZvw = (
                                    <div className="careplan-info-block">
                                        <p className="careplan-full-block">
                                            <strong>Indicatie</strong>
                                            <br />
                                            Indicatie Zvw
                                            <br />
                                            Op basis van {selectedclient.getIn(['caredescription', 'indication', 'zvw', 'type']) == 'pgb' ? 'Pgb' : 'ZIN'}
                                            <br />
                                            {pvHours}
                                            {vpHours}
                                            {pgbStartDate}
                                            {startDateIndication}
                                            {endDateIndication}
                                        </p>
                                    </div>
                                )
                            }
                            if (selectedclient.getIn(['caredescription','indication','hasWlz'])){
                                let pgbStartDate = "";
                                let startDateIndication = "";
                                let endDateIndication = "";
                                if (selectedclient.getIn(['caredescription', 'indication', 'wlz', 'pgbstartdate'])){
                                    pgbStartDate = (
                                        <span>Startdatum Pgb {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'pgbstartdate']))}<br /></span>
                                    )
                                }

                                if (selectedclient.getIn(['caredescription', 'indication', 'wlz', 'startdate'])){
                                    startDateIndication = (
                                        <span>Startdatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'startdate']))}<br /></span>
                                    )
                                }
                                if (selectedclient.getIn(['caredescription', 'indication', 'wlz', 'enddate'])){
                                    endDateIndication = (
                                        <span>Einddatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'enddate']))}<br /></span>
                                    )
                                }

                                indicationWlz = (
                                    <div className="careplan-info-block">
                                        <p className="careplan-full-block">
                                            <strong>Indicatie</strong><br />
                                            Indicatie Wlz.<br />
                                            {selectedclient.getIn(['caredescription', 'indication', 'wlz', 'type']) == 'pgb' ? 'Hele indicatie in Pgb' : 'Deel van indicatie in Pgb'}
                                            <br />
                                            ZZP {selectedclient.getIn(['caredescription', 'indication', 'wlz', 'zzp'])}
                                            <br />
                                            {pgbStartDate}
                                            {startDateIndication}
                                            {endDateIndication}
                                        </p>
                                    </div>
                                )
                            }
                            if (selectedclient.getIn(['caredescription','indication','hasWmo'])) {
                                let pgbStartDate = "";
                                let startDateIndication = "";
                                let endDateIndication = "";
                                if (selectedclient.getIn(['caredescription', 'indication', 'wmo', 'pgbstartdate'])){
                                    pgbStartDate = (
                                        <span>Startdatum Pgb {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'pgbstartdate']))}<br /></span>
                                    )
                                }

                                if (selectedclient.getIn(['caredescription', 'indication', 'wmo', 'startdate'])){
                                    startDateIndication = (
                                        <span>Startdatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'startdate']))}<br /></span>
                                    )
                                }
                                if (selectedclient.getIn(['caredescription', 'indication', 'wmo', 'enddate'])){
                                    endDateIndication = (
                                        <span>Einddatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'enddate']))}<br /></span>
                                    )
                                }

                                indicationIndividual = (
                                    <div className="careplan-info-block">
                                        <p className="careplan-full-block">
                                            <strong>Indicatie</strong>
                                            <br />
                                            Indicatie Wmo (Individuele begeleiding)
                                            <br />
                                            {selectedclient.getIn(['caredescription', 'indication', 'wmo', 'type']) == 'pgb' ? 'Op basis van Pgb' : 'Op basis van ZIN'}
                                            <br />
                                            Individuele begeleiding {selectedclient.getIn(['caredescription', 'indication', 'wmo', 'zzp'])} uur per week
                                            <br />
                                            {pgbStartDate}
                                            {startDateIndication}
                                            {endDateIndication}
                                        </p>
                                    </div>
                                )
                            }
                            if (selectedclient.getIn(['caredescription','indication','hasWmoHome'])){
                                let pgbStartDate = "";
                                let startDateIndication = "";
                                let endDateIndication = "";
                                if (selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'pgbstartdate'])){
                                    pgbStartDate = (
                                        <span>Startdatum Pgb {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'pgbstartdate']))}<br /></span>
                                    )
                                }
                                if (selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'startdate'])){
                                    startDateIndication = (
                                        <span>Startdatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'startdate']))}<br /></span>
                                    )
                                }
                                if (selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'enddate'])){
                                    endDateIndication = (
                                        <span>Einddatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'enddate']))}<br /></span>
                                    )
                                }

                                indicationWmoHome = (
                                    <div className="careplan-info-block">
                                        <h2 className="careplan-sub-header"></h2>
                                        <p className="careplan-full-block">
                                            <strong>Indicatie</strong>
                                            <br />
                                            Indicatie Wmo (Individuele begeleiding)
                                            <br />
                                            {selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'type']) == 'pgb' ? 'Op basis van Pgb' : 'Op basis van ZIN'}
                                            <br />
                                            Individuele begeleiding {selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'zzp'])} uur per week
                                            <br />
                                            {pgbStartDate}
                                            {startDateIndication}
                                            {endDateIndication}
                                        </p>
                                    </div>
                                )
                            }
                            return (
                                <Fragment>
                                    {indicationZvw}
                                    {indicationWlz}
                                    {indicationIndividual}
                                    {indicationWmoHome}
                                </Fragment>
                            )
                        }).call(this)}

                    </div>


                    <div className="careplan-page-break"></div>
                </PageContent>
            </PurePage>
                    
            <PurePage className="careplan-page" noHeader={true}>

             

                <PageContent>
                    <h1 className="careplan-header">Zorgplan voor {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient)}</h1>
                    <div className="careplan-info-block">
                        <h2 className="careplan-sub-header">Sociale situatie</h2>
                        {(function(){
                            if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'children'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Kinderen / Kleinkinderen</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'children'])}</pre>
                                    </div>
                                );
                            }
                        }).call(this)}
                        {(function(){

                            // console.log("rendering anamnese part arbeidsverleden, interesses en hobby's");
                            // console.log(selectedclient.getIn(['caredescription', 'anamnese', 'social', 'workhistory']))

                            if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'workhistory'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Arbeidsverleden, interesses, hobby's</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'workhistory'])}</pre>
                                    </div>
                                )
                            }
                        }).call(this)}
                        {(function(){
                            if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'othercare'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Overige (mantel)zorg, huishoudelijke hulp</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'othercare'])}</pre>
                                    </div>
                                )
                            }
                        }).call(this)}
                        {(function(){
                            if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'partner'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Bijzonderheden partner</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'partner'])}</pre>
                                    </div>
                                )
                            }
                        }).call(this)}
                        {(function(){
                            if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'notes'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Bijzonderheden</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'notes'])}</pre>
                                    </div>
                                )
                            }
                        }).call(this)}
                    </div>

                    <div className="careplan-info-block">
                        <h2 className="careplan-sub-header">Medische situatie</h2>
                        {(function(){
                            if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'details'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Ziektebeeld</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'details'])}</pre>
                                    </div>
                                );
                            }
                        }).call(this)}
                        {(function(){
                            if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'physicalcondition'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Lichamelijke conditie</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'physicalcondition'])}</pre>
                                    </div>
                                )
                            }
                        }).call(this)}
                        {(function(){
                            if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'mentalcondition'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Geestelijke conditie</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'mentalcondition'])}</pre>
                                    </div>
                                )
                            }
                        }).call(this)}
                        {(function(){
                            if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'allergies'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Allergi&euml;n</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'allergies'])}</pre>
                                    </div>
                                )
                            }
                        }).call(this)}

                        <div className="careplan-full-block">
                            <strong>Reanimatie bij hartstilstand</strong>
                            <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'cpr']) ? 'Ja' : 'Nee. Svp verklaring hieromtrent in dossier doen.'}</pre>
                        </div>

                        {(function(){
                            if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'notes'])){
                                return (
                                    <div className="careplan-full-block">
                                        <strong>Bijzonderheden</strong>
                                        <pre className="careplan-pre-style">{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'notes'])}</pre>
                                    </div>
                                )
                            }
                        }).call(this)}
                    </div>

                

                    {(function(){
                        //Alle zorgplan onderdelen.
                        if (selectedclient.getIn(['caredescription', 'careplan', 'parts'])){
                            try{
                                
                                let parts = (selectedclient.getIn(['caredescription', 'careplan', 'parts']) || []);
                                parts = parts.sort((a,b) => {
                                    return Number(a.get("migrationnumber", a.get("migrationNumber", 100000))) - Number(b.get("migrationnumber", b.get("migrationNumber", 100000)))
                                })
                                parts = parts.filter(this.isGroupAvailable.bind(this));
                                parts = parts.map((part) => {
                                    let originalPart = this.getGroupFromParts(part);
                                    return part.set("items", part.get("items").filter(this.isItemAvailable.bind(this, originalPart)));
                                })

                                parts = parts.map(part => {
                                    return part.set("items", part.get("items").map((item) => {
                                        let originalPart = this.getGroupFromParts(part);
                                        let originalItem = this.getItemFromGroup(originalPart, item);
                                        return item.set("sortOrder", originalItem.get("order") || 999999);
                                    }))
                                })

                                parts = parts.map(part => {


                                    return part.set("items", part.get("items").sort((a,b) => {
                                        return Number(a.get("sortOrder")) - Number(b.get("sortOrder"));
                                    }))

                                })

                                parts = parts.map((part) => {
                                    let grouped = part.get("items").groupBy(x => x.get("header"));
                                    let processedGroupArray = [];
                                    let partItems = [];
                                    part.get("items").forEach((item) => {
                                        let renderItems = [];
                                        let headerUlStyle = {
                                            paddingLeft: "20px",
                                            listStyleType: "disc"
                                        }
                                        if(item.get("header")) {
                                            if(processedGroupArray.indexOf(item.get('header')) < 0 ) {
                                                let subs = grouped.get(item.get('header')).map((item) => (<li key={item.get('name')} className="careplan-li-style">{item.get('name')}</li>));
                                                partItems.push(<li className="careplan-li-header-group-style"><h4 className="careplan-sub-sub-header" style={{marginTop:"0px"}}>{item.get('header')}</h4><ul style={headerUlStyle} key={item.get("header")}>{subs}</ul></li>);
                                                processedGroupArray.push(item.get("header"))
                                            }
                                        } else {
                                            partItems.push(<li key={item.get('name')} className="careplan-li-style">{item.get('name')}</li>)
                                        }
                                    })
                                    if (partItems.length > 0){

                                        const ulGroupStyle = {
                                            paddingLeft: "20px"
                                        }

                                        return (
                                            <div className="careplan-info-block">
                                                <h2 className="careplan-sub-header">{part.get('name')}</h2>
                                                <ul style={ulGroupStyle}>
                                                    {partItems}
                                                </ul>
                                            </div>
                                        )
                                    }
                                });

                                return parts;
                            }
                            catch(e){
                                debugger;
                            }
                        }
                    }).call(this)}

                    {(function() { 
                        let safetyMeasures = selectedclient.getIn(['caredescription','safetymeasures']);
                        if(safetyMeasures && safetyMeasures.size > 0) {

                            const ulGroupStyle = {
                                paddingLeft: "20px"
                            }

                            return <div className="careplan-info-block">
                                <h2 className="careplan-sub-header">Veiligheidsmaatregelen</h2>
                                <ul style={ulGroupStyle}>
                                    { safetyMeasures.map((safetyMesasure) => <li key={safetyMesasure.get('name')} className="careplan-li-style">{safetyMesasure.get('name')}</li>) }
                        
                                </ul>
                                <em className="careplan-small-font">* Zie document veiligheidsmaatregelen</em>
                            </div>

                        }

                    }).call(this)}

                    {(function(){
                        let mondayTasks = "";
                        let tuesdayTasks = "";
                        let wednesdayTasks = "";
                        let thursdayTasks = "";
                        let fridayTasks = "";
                        let saturdayTasks = "";
                        let sundayTasks = "";

                        let oneFilled = false;
                        if (selectedclient.getIn(['caredescription', 'careplan', 'monday']) && selectedclient.getIn(['caredescription', 'careplan', 'monday']).trim() != ""){
                            mondayTasks = (
                                <p className="careplan-full-block">
                                    <strong>Maandag</strong>
                                    <pre className="careplan-pre-task-style">{selectedclient.getIn(['caredescription', 'careplan', 'monday'])}</pre>
                                </p>
                            );
                            oneFilled = true;
                        }
                        if (selectedclient.getIn(['caredescription', 'careplan', 'tuesday']) && selectedclient.getIn(['caredescription', 'careplan', 'tuesday']).trim() != ""){
                            tuesdayTasks = (
                                <p className="careplan-full-block">
                                    <strong>Dinsdag</strong>
                                    <pre className="careplan-pre-task-style">{selectedclient.getIn(['caredescription', 'careplan', 'tuesday'])}</pre>
                                </p>
                            );
                            oneFilled = true;
                        }
                        if (selectedclient.getIn(['caredescription', 'careplan', 'wednesday']) && selectedclient.getIn(['caredescription', 'careplan', 'wednesday']).trim() != ""){
                            wednesdayTasks = (
                                <p className="careplan-full-block">
                                    <strong>Woensdag</strong>
                                    <pre className="careplan-pre-task-style">{selectedclient.getIn(['caredescription', 'careplan', 'wednesday'])}</pre>
                                </p>
                            );
                            oneFilled = true;
                        }
                        if (selectedclient.getIn(['caredescription', 'careplan', 'thursday']) && selectedclient.getIn(['caredescription', 'careplan', 'thursday']).trim() != ""){
                            thursdayTasks = (
                                <p className="careplan-full-block">
                                    <strong>Donderdag</strong>
                                    <pre className="careplan-pre-task-style">{selectedclient.getIn(['caredescription', 'careplan', 'thursday'])}</pre>
                                </p>
                            );
                            oneFilled = true;
                        }
                        if (selectedclient.getIn(['caredescription', 'careplan', 'friday']) && selectedclient.getIn(['caredescription', 'careplan', 'friday']).trim() != ""){
                            fridayTasks = (
                                <p className="careplan-full-block">
                                    <strong>Vrijdag</strong>
                                    <pre className="careplan-pre-task-style">{selectedclient.getIn(['caredescription', 'careplan', 'friday'])}</pre>
                                </p>
                            );
                            oneFilled = true;
                        }
                        if (selectedclient.getIn(['caredescription', 'careplan', 'saturday']) && selectedclient.getIn(['caredescription', 'careplan', 'saturday']).trim() != ""){
                            saturdayTasks = (
                                <p className="careplan-full-block">
                                    <strong>Zaterdag</strong>
                                    <pre className="careplan-pre-task-style">{selectedclient.getIn(['caredescription', 'careplan', 'saturday'])}</pre>
                                </p>
                            );
                            oneFilled = true;
                        }
                        if (selectedclient.getIn(['caredescription', 'careplan', 'sunday']) && selectedclient.getIn(['caredescription', 'careplan', 'sunday']).trim() != ""){
                            sundayTasks = (
                                <p className="careplan-full-block">
                                    <strong>Zondag</strong>
                                    <pre className="careplan-pre-task-style">{selectedclient.getIn(['caredescription', 'careplan', 'sunday'])}</pre>
                                </p>
                            );
                            oneFilled = true;
                        }

                        if (oneFilled){
                            return (
                                <div className="careplan-info-block">
                                    <h2 className="careplan-sub-header">Taken</h2>
                                    {mondayTasks}
                                    {tuesdayTasks}
                                    {wednesdayTasks}
                                    {thursdayTasks}
                                    {fridayTasks}
                                    {saturdayTasks}
                                    {sundayTasks}
                                </div>
                            )
                        }
                    }).call(this)}

                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'careplan', 'notes'])){
                            return (
                                <div className="careplan-info-block">
                                    <h2 className="careplan-sub-header">Bijzonderheden</h2>
                                    <div className="careplan-full-block">
                                        <pre className="careplan-pre-task-style">{selectedclient.getIn(['caredescription', 'careplan', 'notes'])}</pre>
                                    </div>
                                </div>
                            )
                        }
                    }).call(this)}



                </PageContent>
            </PurePage>
            </Fragment>)

    }
}
/*

<div className="careplan-info-block">
    <p className="careplan-full-block">
        Er is goedkeuring, dat er namens {selectedclient.get('gender') == 'M' ? 'dhr' : 'mw'} in het belang van {selectedclient.get('gender') == 'M' ? 'dhr' : 'mw'} overleg plaatsvindt over de
        gezondheids- en zorgsituatie met andere betrokkenen zoals huisarts, specialist, fysiotherapeut,
        thuiszorginstelling, GGZ, CIZ, Zorgkantoor en familieleden. Alsmede is er goedkeuring,
        dat een auditor van een keurmerkinstituut inzage heeft in de administratieve behandeling
        van het digitale dossier van {selectedclient.get('gender') == 'M' ? 'dhr' : 'mw'} , dit ter controle voor het kwaliteitskeurmerk van USC.
    </p>
</div>

*/
export default CareplanPage;

export function PurePage({ children, className }) {
    return <div className={`${className} page-container`}>
        <div className="page">
            {children}
        </div>
    </div>

}

export function Page({ children, className, noHeader, noFooter }) {


    return <div className={`${className} page-container`}>
        <table className="page-table">
            <thead>
                <tr>
                    <td>
                        <div className={`page-header-space ${noHeader ? "no-header" : ""}`}></div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div className="page">
                            {children}
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div className={`page-footer-space ${noFooter ? "no-footer" : ""}`}></div>
                    </td>
                </tr>
            </tfoot>
        </table>

    </div>


}

export function PageContent({ children }) {
    return <div className="page-content">
       {children}
    </div>
}

export function PageFooter({ children, lastPage }) {
    if(lastPage) {
        return <div className="page-footer-last-page">
            {children}
        </div>
    }
    return <div className="page-footer">
       {children}
    </div>
}

export function PageHeader({ children }) {
    return <div className="page-header">
       {children}
    </div>
}







