import React from 'react';
// import {getNlDate} from 'Core/Utils/Display';
import moment from 'moment'

class SingleClientCarePlanPrintFooter extends React.Component{
    render(){
        let {client} = this.props;
        let selectedclient = client;
        return (
            <div style={{ clear: 'both', textAlign: 'right', fontFamily: 'Arial', fontSize: '10px' }}>
                Blad {'{{page}}'} van {'{{pages}}'}&nbsp;&nbsp;&nbsp;{moment(Number(selectedclient.getIn(['caredescription', 'careplan', 'date']) || Date.now())).format('DD-MM-YYYY')} {selectedclient.get('id')}
            </div>
        )
    }
}

export default SingleClientCarePlanPrintFooter;
