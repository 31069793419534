import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import Immutable, { OrderedMap, List, Map } from 'immutable';
import './Bookings.scss';
import moment from 'moment-timezone';
import ReactSelect from 'react-select';
import Input from '../Controls/Input';
import CheckBox from '../Controls/CheckBox';
import Select from '../Controls/Select';
import { generateDisplayName } from '../../Utils/Person';
import Modal from '../Modal';
import { getIrregularDays } from '../../Actions/IrregularDays';
import { getProductCategories } from '../../Actions/ProductCategories';
import { getUnbookedPlanning, saveBookings } from '../../Actions/Bookings';
import { sendCareMessage } from '../../Actions/Mail';
import FreelancersBookingConfirmedMail from '../../MailTemplates/FreelancersBookingConfirmedMail';
import ClientBookingConfirmedMail from '../../MailTemplates/ClientBookingConfirmedMail';
import PdfService from '../../Service/PdfService';
import swal from 'sweetalert2';

const CURRENT_BOOKING_VERSION = "0.9.7PORTAL";
const CURRENT_BOOKING_AGREEDTIMES_VERSION = "0.9.7PORTAL";
const CURRENT_BOOKING_DETAILS_VERSION = "0.9.7PORTAL";

window.moment = moment;

const mapStateToProps = (state) => ({
    productcategories: state.getIn(['productcategories','collection'], OrderedMap()),
    profileplanning: state.getIn(['bookings','profileplanning'], OrderedMap()),
    profilePlanningLoading: state.getIn(['bookings','profilePlanningLoading'], false),
    clients: state.getIn(['clients','collection'], OrderedMap()),
    freelancer: state.getIn(['freelancers','single'], Map()),
    irregulardays: state.getIn(['irregulardays','collection'], OrderedMap())
});

const mapDispatchToProps = (dispatch) => ({
    getUnbookedPlanning: () => dispatch(getUnbookedPlanning()),
    getIrregularDays: () => dispatch(getIrregularDays()),
    getProductCategories: () => dispatch(getProductCategories()),
    saveBookings: (bookings) => dispatch(saveBookings(bookings)),
    sendCareMessage: (mail) => dispatch(sendCareMessage(mail))
});

// sendCareMessage(Immutable.Map({subject: 'Bevestigingsmail n.a.v. accorderen', message: PdfService.renderToStatic(<FreelancerBookingConfirmedMail selectedclient={client} selectedfreelancer={selectedfreelancer} selectedbooking={selectedbooking}/>), to: selectedfreelancer.get('email')}))

class Bookings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookings: OrderedMap(),
            savingBookings: false,
            currentBooking: undefined
        }
        this.closeBookingDialog = this.closeBookingDialog.bind(this);
        this.changeCurrentBooking = this.changeCurrentBooking.bind(this);
    }

    changeCurrentBooking(booking) {
        this.setState({
            currentBooking: booking
        })
    }

    closeBookingDialog() {
        this.setState({
            currentBooking: undefined
        })
    }

    openBookingDialog(booking) {
        this.setState({
            currentBooking: booking
        })
    }

    hasErrors(booking) {
        return booking.getIn(['$error','kilometers']) || booking.getIn(['$error','caremoments']);
    }

    getHours(booking) {

        let filledStart = booking.getIn(['agreedtimes', 'start']);
        let filledEnd = booking.getIn(['agreedtimes', 'end']);

        let start = moment(Number(booking.getIn(['details','planningrecord','starttime']))).format('DD-MM-YYYY');
        let end = moment(Number(booking.getIn(['details','planningrecord','endtime']))).format('DD-MM-YYYY');

        start = start + " " + filledStart;
        end = end + " " + filledEnd;
        
        let bookedStartStamp = Number(moment(start, 'DD-MM-YYYY HH:mm').format('x'));
        let bookedEndStamp = Number(moment(end, 'DD-MM-YYYY HH:mm').format('x'));

        let diff = bookedEndStamp - bookedStartStamp;

        return diff / 1000 / 60 / 60;

    }

    saveBooking(booking) {

        let { irregulardays, freelancer, clients, productcategories, saveBookings, getUnbookedPlanning, sendCareMessage } = this.props;
        
        booking = booking.delete('$error');
        
        // console.log("client", clients.get(booking.get('clientid')));
        // return console.log('saving booking debug', booking);

        

        this.setState({
            savingBookings: true
        })

        let filledStart = booking.getIn(['agreedtimes', 'start']);
        let filledEnd = booking.getIn(['agreedtimes', 'end']);

        let client = booking.get('client');
        booking = booking.set('freelancer', freelancer);
        booking = booking.setIn(['agreedtimes', 'confirmedStart'], filledStart);
        booking = booking.setIn(['agreedtimes', 'confirmedEnd'], filledEnd);
        booking = booking.setIn(['agreedtimes', 'serviceStart'], filledStart);
        booking = booking.setIn(['agreedtimes', 'serviceEnd'], filledEnd);

        booking = booking.setIn(['agreedtimes', 'calculatedHours'], this.getHours(booking));

        booking = setUseAgreedHours(booking);
        booking = booking.set('booked', 1);
        let bookings = List().push(booking);

        saveBookings && saveBookings(bookings).then(() => {
            let addToSubject = "";
            if(booking.getIn(['details','correctedBooking'])) {
                addToSubject += " (CORRECTIE)";
            }
            
            let mail = Map({
                subject: 'Bevestigingsmail n.a.v. accorderen' + addToSubject, 
                message: PdfService.renderToStatic(
                    <FreelancersBookingConfirmedMail 
                        selectedclient={client} 
                        selectedfreelancer={freelancer} 
                        selectedbooking={booking}
                    />
                ), 
                to: freelancer.get('email')}
            )


            let clientMails = List();
            client && (client.get('clientportal', List()) || List()).forEach((portal) => {

                let aanhef = { 'M': 'Meneer', 'V': 'Mevrouw' };
                let aanhefKort = {'M': 'dhr', 'V': 'mw' };

                let frl = freelancer;
                let cli = client;
        
                let freelancerLastname = frl.get('insertion') ? frl.get('insertion') + " " + frl.get('lastname') : frl.get('lastname');
                let clientName = generateDisplayName(cli);

                let onderwerp = `Dienst ${aanhefKort[cli.get('gender')]} ${clientName} ${moment(Number(booking.get('instancedate'))).format('DD/MM/YYYY')}`;
                let onderwerpAnnulering = `Accorderen - Dienst geannuleerd: ${aanhefKort[cli.get('gender')]} ${clientName} ${moment(Number(booking.get('instancedate'))).format('DD/MM/YYYY')}`;

                if(booking.getIn(['details','correctedBooking'])) {
                    onderwerp += " (CORRECTIE)";
                    onderwerpAnnulering += " (CORRECTIE)"; 
                }

                if(portal.get('receivingmailsevery') == 'aftershift') {
                    clientMails = clientMails.push(Map({
                        subject: booking.get('cancelled') ? onderwerpAnnulering : onderwerp, 
                        message: PdfService.renderToStatic(
                            <ClientBookingConfirmedMail 
                                selectedclient={client} 
                                selectedfreelancer={freelancer} 
                                selectedbooking={booking}
                            />
                        ), 
                        to: portal.get('email')}
                    ));
                }

            })
            
            if(sendCareMessage) {

                let promise = sendCareMessage(mail);

                clientMails.forEach((cMail) => {

                    promise = promise.then(() => sendCareMessage(cMail));

                })
                
                console.log(clientMails);
                let additionalText = "";
                if(!client) {
                    additionalText += "Je client krijgt hierover geen bevestiging omdat je overeenkomst inactief is.";
                }

                promise.then(() => {
                    getUnbookedPlanning && getUnbookedPlanning();
                    swal.fire({
                        title: 'Dienst geaccordeerd',
                        text: 'Je dienst is succesvol geaccordeerd, je ontvangt ook een bevestigingsmail' + additionalText,
                        type: 'success'
                    })
                    this.setState({
                        savingBookings: false,
                        currentBooking: undefined
                    })
                    
                }).catch(() => {
                    getUnbookedPlanning && getUnbookedPlanning();
                    swal.fire({
                        title: 'Dienst geaccordeerd',
                        text: 'Je dienst is geaccordeerd, echter er trad een fout op bij het sturen van de bevestigingsmail',
                        type: 'warning'
                    })
                    this.setState({
                        savingBookings: false,
                        currentBooking: undefined
                    })
                    
                })

            }

           


        }).catch((err) => {
            console.log("Bookings.saveBooking reported bookings saved");
            console.error(err);
             getUnbookedPlanning && getUnbookedPlanning();
            this.setState({
                savingBookings: false, 
                currentBooking: undefined
            })
            swal.fire({
                title: 'Fout bij het accorderen',
                text: 'Er trad een fout op bij het accorderen van je dienst, neem contact op met kantoor om je dienst te accorderen',
                type: 'error'
            })
        });

    }

    onError(field, value) {
        
        this.setState(({
            bookings
        }) => ({
            bookings: bookings.updateIn([field[0], '$error'], Map(), x => x).setIn(field, value)
        }))

    }

    onChange(field, value) {


        // console.log("onChange field", field);
        // console.log("onChange value", value);
        // console.log("onChagen typeof value", typeof value);

        value = value == "true" || value == "false" ? !(value == "true") : value;
        value = value.value ? value.value : value;

        this.setState(({ bookings }) => ({
            bookings: bookings.setIn(field, value)
        }), () => {

            // console.log("booking after change", this.state.bookings.get(field[0]));

        })

    }

    bookingChanged(index, booking) {
        this.setState(({ bookings }) => ({
            bookings: bookings.set(index, booking)
        }))
    } 

    componentDidMount() {
        let { getUnbookedPlanning, getIrregularDays, getProductCategories } = this.props;
        getUnbookedPlanning && getUnbookedPlanning();
        getIrregularDays && getIrregularDays();
        getProductCategories && getProductCategories();
    }

    componentDidUpdate(prevProps, prevState) {

        let profileplanningLoaded = prevProps.profileplanning != this.props.profileplanning;
        let productcategoriesLoaded = prevProps.productcategories != this.props.productcategories;
        let clientsLoaded = prevProps.clients != this.props.clients;

        if(
            (profileplanningLoaded && this.props.productcategories && this.props.clients) ||
            (productcategoriesLoaded && this.props.profileplanning && this.props.clients) ||
            (clientsLoaded && this.props.profileplanning && this.props.productcategories)
        ) {
            // conceptBookings = conceptBookings.sort((a,b) => a.get("instancedate") - b.get("instancedate") > 0 ? -1 : 1);
            this.setState({
                bookings: this.generateBookings(this.props.profileplanning).sort((a,b) => a.get("instancedate") - b.get("instancedate") > 0 ? -1 : 1)
            });
        }
    }

    generateBookings(profileplanning) {



        let { productcategories } = this.props;

        let currentDay = Number(moment().format('x'));
        profileplanning = profileplanning.filter(x => x.get("clientid"));
        profileplanning = profileplanning.filter(x => x.get("endtime") < currentDay);
        let bookings = profileplanning.map((item) => {

            let productcategory = productcategories.get(item.get('productcategoryid'));

            return Map({
                version: CURRENT_BOOKING_VERSION,
                bookingphase: 1,
                instancedate: Number(moment(item.get("starttime"))),
                bookingdate: Number(moment(item.get("starttime"))),
                cancelled: false,
                reasoncancellation: "",
                agreedtimes: Map({
                    version: CURRENT_BOOKING_AGREEDTIMES_VERSION,
                    initialStart: moment(Number(item.get("starttime"))).tz('Europe/Amsterdam').format("HH:mm"),
                    initialEnd: moment(Number(item.get("endtime"))).tz('Europe/Amsterdam').format("HH:mm"),
                    start: moment(Number(item.get("starttime"))).tz('Europe/Amsterdam').format("HH:mm"),
                    end: moment(Number(item.get("endtime"))).tz('Europe/Amsterdam').format('HH:mm'),
                    initialServiceStart: moment(Number(item.get("starttime"))).tz('Europe/Amsterdam').format("HH:mm"),
                    initialServiceEnd: moment(Number(item.get("endtime"))).tz('Europe/Amsterdam').format("HH:mm"),
                    serviceStart: moment(Number(item.get("starttime"))).tz('Europe/Amsterdam').format("HH:mm"),
                    serviceEnd: moment(Number(item.get("endtime"))).tz('Europe/Amsterdam').format('HH:mm')
                }),
                productid: item.get("productid"),
                product: item.get('product'),
                productcategoryid: item.get("productcategoryid"),
                productcategory: productcategory,
                shiftid: item.get("id"),
                shift: item,
                freelancerid: item.get("freelancerid"),
                clientid: item.get("clientid"),
                client: item.getIn(['clientInfo'], Map()).set('id', item.get('clientid')).set('clientportal', item.getIn(['recipients'])),
                agreementid: item.get("agreementid"),
                details: Map({
                    version: CURRENT_BOOKING_DETAILS_VERSION,
                    planningrecord: Map({
                        id: item.get('id'),
                        agreementid: item.get('agreementid'),
                        productcategoryid: item.get('productcategoryid'),
                        productid: item.get('productid'),
                        year: item.get('year'),
                        week: item.get('week'),
                        dow: item.get('dow'),
                        starttime: item.get('starttime'),
                        endtime: item.get('endtime')
                    }),
                    correctedBooking: item.getIn(['details', 'bookingRemoved'])
                })
            })
        });
        return bookings;
    }

    render() {

        let { profileplanning, clients, profilePlanningLoading } = this.props;
        let { currentBooking } = this.state;
        // console.log("clients", clients);
        let { bookings } = this.state;
        // console.log("profileplanning", profileplanning);
        // console.log("bookings", bookings);

        let startDeviationReasonOptions = reasonsStartDeviation.map((r,i) => ({
            label: r.get('name'),
            value: i
        })).toList().toJS();

        let endDeviationReasonOptions = reasonsEndDeviation.map((r,i) => ({
            label: r.get('name'),
            value: i
        })).toList().toJS();

        console.log("ProfilePlanning loadding", profilePlanningLoading)

        return <div className="card bookings">

            <CancelBookingDialog 
                booking={currentBooking}
                onChange={this.changeCurrentBooking}
                onClose={this.closeBookingDialog}
                savingBookings={this.state.savingBookings}
                profilePlanningLoading={profilePlanningLoading}
                onSave={this.saveBooking.bind(this)}
            />

            <div className="card-header" style={{marginBottom:'20px'}}>
                <h1>Accorderen</h1>
                <span>In dit overzicht worden jouw reeds gewerkte diensten aangeboden om te accorderen. Accordeer binnen 8 uur na afronden van een dienst. Alle diensten moeten geaccordeerd zijn voor correcte facturatie.</span>
            </div>

            <div className="card-body relative">

                { profilePlanningLoading && <div className="absolute w-full h-full top-0 left-0 bg-[rgba(255,255,255,0.8)] z-30">
                    <div className="mt-10 mx-auto w-max">
                        <svg className="animate-spin h-20 w-20 mx-auto " viewBox="0 0 24 24">
                            {/* <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>    */}
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path> 
                        </svg>    
                        <div className="text-center text-bold text-xl mt-3">
                            Bezig met laden van de diensten...
                        </div>
                    </div>
                    
                </div> }

                <div className="planningrecords">

                    { bookings.map((booking, index) => <div className="booking" key={index.toString()}>

                        <header>
                            <div>
                                {moment(booking.get('instancedate')).format('dddd DD MMMM YYYY')}
                            </div>
                            <div>
                                <strong>Client:</strong>{clients.get(booking.get('clientid'), Map()).get('gender') == 'M' ? 'Dhr.' : 'Mw.'} {generateDisplayName(clients.get(booking.get('clientid'), Map())) || booking.get('clientid') + " (inactieve overeenkomst)"}<br />
                                <strong>Product categorie:</strong> {booking.getIn(['productcategory','name'])}
                            </div>
                            <div>
                                <button 
                                    onClick={this.openBookingDialog.bind(this, booking)}
                                    className="btn btn-danger btn-cancel-service">Klik hier als de dienst niet is doorgegaan.</button>
                            </div>  
                        </header>
                        <section>
                            <div>
                                <h3>WERKELIJKE GEWERKTE TIJDEN</h3>
                                <div className="times">

                                    <div className="starttime">
                                        <Select 
                                            value={booking.getIn(['agreedtimes','start'])} 
                                            onChange={this.onChange.bind(this, [index, 'agreedtimes', 'start'])} 
                                            options={getTimeOptions()} 
                                            placeholder="starttijd" />
                                    </div>

                                    <div className="endtime">
                                        <Select 
                                            value={booking.getIn(['agreedtimes','end'])}
                                            onChange={this.onChange.bind(this, [index, 'agreedtimes', 'end'])} 
                                            options={getTimeOptions()} 
                                            placeholder="eindtijd" />
                                    </div>

                                </div>
                               
                               
                                <div className="times-reasons">

                                    
                                    { booking.getIn(['agreedtimes', 'start']) != booking.getIn(['agreedtimes', 'initialStart']) && 
                                        <React.Fragment>
                                            <h3>REDEN AFWIJKENDE STARTTIJD</h3>
                                            <div>
                                                <Select 
                                                    value={booking.getIn(['agreedtimes','reasonstartdeviation'])}
                                                    onChange={this.onChange.bind(this, [index, 'agreedtimes', 'reasonstartdeviation'])}
                                                    options={startDeviationReasonOptions} 
                                                    placeholder="Kies reden van afwijkende starttijd" />
                                            </div>
                                        </React.Fragment>
                                    } 

                                    { booking.getIn(['agreedtimes', 'end']) != booking.getIn(['agreedtimes', 'initialEnd']) && 
                                        <React.Fragment>
                                            <h3>REDEN AFWIJKENDE EINDTIJD</h3>
                                            <div>
                                                <Select 
                                                    value={booking.getIn(['agreedtimes','reasonenddeviation'])}
                                                    onChange={this.onChange.bind(this, [index, 'agreedtimes', 'reasonenddeviation'])}
                                                    options={endDeviationReasonOptions} 
                                                    placeholder="Kies reden van afwijkende eindtijd" />
                                            </div>  
                                        </React.Fragment>
                                    } 

                                </div>
                                

                                

                            </div>
                            <div>
                                <h3>VOORGESCHOTEN BEDRAGEN</h3>
                                <div>
                                    <Backpayments booking={booking} bookingChanged={this.bookingChanged.bind(this, index)} />
                                </div>
                                <h3>KILOMETERS TEN BEHOEVE VAN ZORG:</h3>
                                <div>
                                    <NumberInput 
                                        errorEvent={this.onError.bind(this, [index, '$error', 'kilometers'])}
                                        value={booking.getIn(['agreedtimes','kilometers'])} 
                                        onChange={this.onChange.bind(this, [index, 'agreedtimes','kilometers'])} />
                                </div>
                                <h3>AANTAL ZORGMOMENTEN IN SLAAPDIENST:</h3>
                                <div>
                                    <NumberInput
                                        errorEvent={this.onError.bind(this, [index, '$error', 'caremoments'])}
                                        value={booking.getIn(['agreedtimes','caremoments'])} 
                                        onChange={this.onChange.bind(this, [index, 'agreedtimes','caremoments'])} />
                                </div>
                                {(function() {
                                    if (booking.getIn(['agreedtimes', 'caremoments']) && booking.getIn(['agreedtimes', 'caremoments']) > 3) {
                                        return (
                                            <div style={{ textAlign: 'right', padding: "5px" }}>
                                                <strong style={{
                                                    fontStyle: 'italic',
                                                    color: 'red'
                                                }}>
                                                    <italic>Let op: het aantal zorgmomenten moet te herleiden zijn uit rapportageschrift cliënt</italic>
                                                </strong>
                                            </div>
                                        )
                                    }
                                }).call(this)}
                            </div>
                        </section>
                        <footer>
                            <div>
                                <CheckBox 
                                    label="Ik heb alles naar waarheid ingevuld"
                                    value={booking.getIn(['agreedtimes','confirmed'])} 
                                    onChange={this.onChange.bind(this, [index, 'agreedtimes', 'confirmed'])} />
                            </div>
                            <div>
                                <button 
                                    className="btn btn-success"
                                    onClick={this.saveBooking.bind(this, booking)}
                                    disabled={this.hasErrors(booking) || profilePlanningLoading || this.state.savingBookings || !booking.getIn(['agreedtimes', 'confirmed']) || ((booking.getIn(['agreedtimes', 'start']) != booking.getIn(['agreedtimes', 'initialStart']) && !booking.getIn(['agreedtimes', 'reasonstartdeviation'])) || (booking.getIn(['agreedtimes', 'end']) != booking.getIn(['agreedtimes', 'initialEnd']) && !booking.getIn(['agreedtimes', 'reasonenddeviation'])))}
                                    >Akkoord</button>
                            </div>
                        </footer>
                    </div> ).toList() }
                </div>
            </div>
        </div>

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);

function NumberInput({ value, onChange, errorEvent }) {
    const [stringValue, setStringValue] = useState((value || "0").toString());
    const [validNumber, setValidNumber] = useState(true); 
    const setNumber = (value) => {
        setStringValue(value);
        value = (value || "").replace(/(.*)(?:\,|\.)([0-9]{0,2})$/,'$1SPLIT$2').replace(/(\,|\.)*/g,'').replace('SPLIT','.');
        if(Number.isNaN(Number(value))) {
            setValidNumber(false);
            errorEvent(true);
        }
        if(!Number.isNaN(Number(value))) {
            setValidNumber(true);
            onChange(Number(value));
            errorEvent(false);
        }
    }

    let errorMessage = !validNumber ? "Let op! Je hebt een ongeldig numerieke waarde ingevoerd." : "";

    return <Input error={!validNumber} errorMessage={errorMessage} value={stringValue} onChange={setNumber} />
}

class CancelBookingDialog extends Component {


    render() {

        let { booking, onSave, onClose, onChange } = this.props;
        let { savingBookings, profilePlanningLoading } = this.props;

        let cancelReasonOptions = reasonsCancellation.map((r,i) => ({
            label: r.get('name'),
            value: i
        })).toList().toJS();

        let dialogOpen = !!booking;
        booking = booking || Map();

        

        return <Modal open={dialogOpen} onClose={onClose}>

            <div>
                <h4>Dienst is niet doorgegaan</h4>

                <Select 
                    placeholder="REDEN WAAROM DEZE DIENST NIET DOOR GING..."
                    options={cancelReasonOptions} 
                    onChange={(value) => onChange(booking.set('reasoncancellation', value)) }
                    value={booking.get('reasoncancellation')} />


                    <div>
                        <CheckBox 
                            label="Ik heb alles naar waarheid ingevuld"
                            value={booking.getIn(['agreedtimes','confirmed'])} 
                            onChange={(value) => onChange(booking.setIn(['agreedtimes', 'confirmed'], value))} />
                    </div>
                    <div>
                        <button 
                            className="btn btn-success"
                            onClick={() => { onSave(booking.set('cancelled', true)) }}
                            disabled={profilePlanningLoading || savingBookings || !booking.getIn(['agreedtimes', 'confirmed']) || !booking.getIn(['reasoncancellation'])}
                            >Akkoord</button>
                    </div>
                   

            </div>

        </Modal>



    }

}

class Backpayments extends Component{
    valueChanged(index, field, value){
        let {booking, bookingChanged} = this.props;
        bookingChanged(booking.setIn(['agreedtimes', 'backpayments', index, ...field], value));
    }

    addBackpayment(){
        let {booking, bookingChanged} = this.props;
        if (!booking.getIn(['agreedtimes', 'backpayments'])){
            booking = booking.setIn(['agreedtimes', 'backpayments'], List([]));
        }
        bookingChanged(booking.updateIn(['agreedtimes', 'backpayments'], arr => arr.push(Immutable.Map({}))));
    }

    render(){
        let {booking} = this.props;

        return (
            <div className="backpayments">
                {(function(){
                    return (booking.getIn(['agreedtimes', 'backpayments']) || []).map((backpayment, index) => {
                        return (
                            <div className="backpayment" key={index}>
                                {/* <div className="type">
                                    <Select
                                        placeholder="Kies soort"
                                        value={backpayment.get('type')}
                                        options={[
                                            { value: 'Consumpties', label: 'Consumpties' },
                                            { value: 'Boodschappen', label: 'Boodschappen' },
                                            { value: 'Overige', label: 'Overige' }
                                        ]}
                                        onChange={this.valueChanged.bind(this, index, ['type'])}
                                    />
                                </div> */}
                                <div className="description">
                                    <Input label="Omschrijving" value={backpayment.get('description')} onChange={this.valueChanged.bind(this, index, ['description'])} />
                                </div>
                                <div className="amount">
                                    <NumberInput label="Bedrag" value={backpayment.get('amount')} onChange={this.valueChanged.bind(this, index, ['amount'])} />
                                </div>
                            </div>
                        )
                    })
                }).call(this)}
                <div className="new-backpayment">
                    <button className="btn btn-success" onClick={this.addBackpayment.bind(this)}>Nieuwe regel toevoegen</button>
                    
                </div>
            </div>
        )
    }
}


function getTimeOptions() {

    let timeOptions = [];

    for(var h = 0; h <= 23; h++) {

        for(var m = 0; m < 4; m++) {

            let hour = h < 10 ? "0" + h.toString() : h.toString();
            let q = m * 15;
            let minute = q < 10 ? "0" + q.toString() : q.toString();
            
            let time = hour + ":" + minute;

            timeOptions.push({
                label: time,
                value: time
            })
        }

    }

    return timeOptions;

}


// export const reasonsStartDeviation = OrderedMap({
//     "Freelancer op eigen initiatief eerder begonnen (door prive redenen)": Map({
//         name: "Freelancer op eigen initiatief eerder begonnen (door prive redenen)",
//         useagreedhours: false
//     }),
//     "Freelancer op eigen initiatief later begonnen (door prive redenen)": Map({
//         name: "Freelancer op eigen initiatief later begonnen (door prive redenen)",
//         useagreedhours: false
//     }),
//     "Op verzoek van client/vertegenwoordiger eerder begonnen": Map({
//         name: "Op verzoek van client/vertegenwoordiger eerder begonnen",
//         useagreedhours: false
//     }),
//     "Op verzoek van client/vertegenwoordiger later begonnen daardoor korter gewerkt, tijdig gewijzigd (24 uur vooraf)": Map({
//         name: "Op verzoek van client/vertegenwoordiger later begonnen daardoor korter gewerkt, tijdig gewijzigd (24 uur vooraf)",
//         useagreedhours: false
//     }),
//     "Op verzoek van client/vertegenwoordiger later begonnen daardoor korter gewerkt, niet tijdig gewijzigd": Map({
//         name: "Op verzoek van client/vertegenwoordiger later begonnen daardoor korter gewerkt, niet tijdig gewijzigd",
//         useagreedhours: true
//     }),
//     "Op verzoek van en/of in overleg met collega freelancer eerder begonnen": Map({
//         name: "Op verzoek van en/of in overleg met collega freelancer eerder begonnen",
//         useagreedhours: false
//     }),
//     "Op verzoek van en/of in overleg met collega freelancer later begonnen": Map({
//         name: "Op verzoek van en/of in overleg met collega freelancer later begonnen",
//         useagreedhours: false
//     }),
//     "Dit is de juiste aanvangstijd, aanvangstijd stond niet correct in de planning": Map({
//         name: "Dit is de juiste aanvangstijd, aanvangstijd stond niet correct in de planning",
//         useagreedhours: false
//     })
// });

export const reasonsStartDeviation = OrderedMap({
    "Freelancer op eigen initiatief en/of na collegiaal overleg en/of omwille van de zorg eerder begonnen": Map({
        name: "Freelancer op eigen initiatief en/of na collegiaal overleg en/of omwille van de zorg eerder begonnen",
        useagreedhours: false
    }),
    "Freelancer op eigen initiatief en/of na collegiaal overleg later begonnen": Map({
        name: "Freelancer op eigen initiatief en/of na collegiaal overleg later begonnen",
        useagreedhours: false
    }),
    "Op verzoek van client/vertegenwoordiger eerder begonnen": Map({
        name: "Op verzoek van client/vertegenwoordiger eerder begonnen",
        useagreedhours: false
    }),
    "Op verzoek van client/vertegenwoordiger later begonnen, tijdig gewijzigd, meer dan 24 uur voorafgaand": Map({
        name: "Op verzoek van client/vertegenwoordiger later begonnen, tijdig gewijzigd, meer dan 24 uur voorafgaand",
        useagreedhours: false
    }),
    "Op verzoek van client/vertegenwoordiger later begonnen, niet tijdig gewijzigd, minder dan 24 uur voorafgaand": Map({
        name: "Op verzoek van client/vertegenwoordiger later begonnen, niet tijdig gewijzigd, minder dan 24 uur voorafgaand",
        useagreedhours: true
    }),
    "Dit is de juiste aanvangstijd, aanvangstijd stond niet correct in de planning": Map({
        name: "Dit is de juiste aanvangstijd, aanvangstijd stond niet correct in de planning",
        useagreedhours: false
    })
});

// export const reasonsEndDeviation = OrderedMap({
//     "Freelancer op eigen initiatief eerder weggegaan (door prive redenen)": Map({
//         name: "Freelancer op eigen initiatief eerder weggegaan (door prive redenen)",
//         useagreedhours: false
//     }),
//     "Freelancer op eigen initiatief langer gebleven, omdat freelancer op eigen initiatief later begonnen is": Map({
//         name: "Freelancer op eigen initiatief langer gebleven, omdat freelancer op eigen initiatief later begonnen is",
//         useagreedhours: false
//     }),
//     "Freelancer op eigen initiatief eerder weggegaan, omdat freelancer op eigen initiatief eerder begonnen is": Map({
//         name: "Freelancer op eigen initiatief eerder weggegaan, omdat freelancer op eigen initiatief eerder begonnen is",
//         useagreedhours: false
//     }),
//     "Freelancer uit eigen inzicht omwille van de zorg langer gebleven": Map({
//         name: "Freelancer uit eigen inzicht omwille van de zorg langer gebleven",
//         useagreedhours: false
//     }),
//     "Op verzoek van client/vertegenwoordiger, tijdens de dienst, eerder weggegaan": Map({
//         name: "Op verzoek van client/vertegenwoordiger, tijdens de dienst, eerder weggegaan",
//         useagreedhours: true
//     }),
//     "Op verzoek van client/vertegenwoordiger eerder weggegaan, tijdig gemeld": Map({
//         name: "Op verzoek van client/vertegenwoordiger eerder weggegaan, tijdig gemeld",
//         useagreedhours: false
//     }),
//     'Op verzoek van client/vertegenwoordiger langer gebleven': Map({
//         name: 'Op verzoek van client/vertegenwoordiger langer gebleven',
//         useagreedhours: false
//     }),
//     'Op verzoek van en/of in overleg met collega freelancer langer gebleven': Map({
//         name: 'Op verzoek van en/of in overleg met collega freelancer langer gebleven',
//         useagreedhours: false
//     }),
//     'Op verzoek van en/of in overleg met collega freelancer eerder weggegaan': Map({
//         name: 'Op verzoek van en/of in overleg met collega freelancer eerder weggegaan',
//         useagreedhours: false
//     }),
//     'Dit is de juiste eindtijd, eindtijd stond niet correct in de planning': Map({
//         name: 'Dit is de juiste eindtijd, eindtijd stond niet correct in de planning',
//         useagreedhours: false
//     })
// })

export const reasonsEndDeviation = OrderedMap({
    "Freelancer op eigen initiatief en/of na collegiaal overleg eerder weggegaan": Map({
        name: "Freelancer op eigen initiatief en/of na collegiaal overleg eerder weggegaan",
        useagreedhours: false
    }),
    "Freelancer op eigen initiatief langer gebleven, omdat freelancer op eigen initiatief later begonnen is": Map({
        name: "Freelancer op eigen initiatief langer gebleven, omdat freelancer op eigen initiatief later begonnen is",
        useagreedhours: false
    }),
    "Freelancer op eigen initiatief eerder weggegaan, omdat freelancer op eigen initiatief eerder begonnen is": Map({
        name: "Freelancer op eigen initiatief eerder weggegaan, omdat freelancer op eigen initiatief eerder begonnen is",
        useagreedhours: false
    }),
    "Freelancer op eigen initiatief en/of na collegiaal overleg omwille van de zorg langer gebleven": Map({
        name: "Freelancer op eigen initiatief en/of na collegiaal overleg omwille van de zorg langer gebleven",
        useagreedhours: false
    }),
    "Op verzoek van client/vertegenwoordiger, tijdens de dienst, eerder weggegaan": Map({
        name: "Op verzoek van client/vertegenwoordiger, tijdens de dienst, eerder weggegaan",
        useagreedhours: true
    }),
    "Op verzoek van client/vertegenwoordiger tijdens de dienst eerder weggegaan, tijdig gemeld, meer dan 24 uur voorafgaand": Map({
        name: "Op verzoek van client/vertegenwoordiger tijdens de dienst eerder weggegaan, tijdig gemeld, meer dan 24 uur voorafgaand",
        useagreedhours: false
    }),
    'Op verzoek van client/vertegenwoordiger langer gebleven': Map({
        name: 'Op verzoek van client/vertegenwoordiger langer gebleven',
        useagreedhours: false
    }),
    'Dit is de juiste eindtijd, eindtijd stond niet correct in de planning': Map({
        name: 'Dit is de juiste eindtijd, eindtijd stond niet correct in de planning',
        useagreedhours: false
    })
})

// export const reasonsCancellation = OrderedMap({
//     "Cliënt is opgenomen binnen 24 uur voor aanvang van deze dienst": Map({
//         name: "Cliënt is opgenomen binnen 24 uur voor aanvang van deze dienst",
//         useagreedhours: true
//     }),
//     "Cliënt is overleden tijdens deze dienst": Map({
//         name: "Cliënt is overleden tijdens deze dienst",
//         useagreedhours: true
//     }),
//     "Cliënt is overleden voordat de dienst moest beginnen": Map({
//         name: "Cliënt is overleden voordat de dienst moest beginnen",
//         useagreedhours: false
//     }),
//     "Collega freelancer heeft deze dienst overgenomen. Geef direct via mail door aan USC wie de dienst heeft overgenomen": Map({
//         name: "Collega freelancer heeft deze dienst overgenomen. Geef direct via mail door aan USC wie de dienst heeft overgenomen",
//         useagreedhours: false
//     }),
//     "Cliënt/vertegenwoordiger heeft deze dienst tijdig afgemeld ( 24 uur vooraf)": Map({
//         name: "Cliënt/vertegenwoordiger heeft deze dienst tijdig afgemeld ( 24 uur vooraf)",
//         useagreedhours: false
//     }),
//     "Cliënt/vertegenwoordiger heeft deze dienst niet tijdig afgemeld": Map({
//         name: "Cliënt/vertegenwoordiger heeft deze dienst niet tijdig afgemeld",
//         useagreedhours: true
//     }),
//     "Freelancer heeft door privé redenen deze dienst niet gewerkt; geen vervanging geregeld": Map({
//         name: "Freelancer heeft door privé redenen deze dienst niet gewerkt; geen vervanging geregeld",
//         useagreedhours: false
//     }),
//     "Dienst stond dubbel in mijn accordeerlijst": Map({
//         name: "Dienst stond dubbel in mijn accordeerlijst",
//         useagreedhours: false
//     }),
//     "Deze dienst is mij onbekend": Map({
//         name: "Deze dienst is mij onbekend",
//         useagreedhours: false
//     }),
//     "Op verzoek van cliënt/vertegenwoordiger heeft deze dienst op een andere dag plaatsgevonden. Geef direct via mail door aan USC op welke dag dienst wel heeft plaatsgevonden": Map({
//         name: "Op verzoek van cliënt/vertegenwoordiger heeft deze dienst op een andere dag plaatsgevonden. Geef direct via mail door aan USC op welke dag dienst wel heeft plaatsgevonden",
//         useagreedhours: false
//     })
// })

export const reasonsCancellation = OrderedMap({
    "Cliënt is opgenomen binnen 24 uur voor aanvang van deze dienst": Map({
        name: "Cliënt is opgenomen binnen 24 uur voor aanvang van deze dienst",
        useagreedhours: true
    }),
    "Cliënt is overleden tijdens deze dienst": Map({
        name: "Cliënt is overleden tijdens deze dienst",
        useagreedhours: true
    }),
    "Cliënt is overleden voordat de dienst aanving": Map({
        name: "Cliënt is overleden voordat de dienst aanving",
        useagreedhours: false
    }),
    "Collega freelancer heeft deze dienst overgenomen. Geef direct via mail door wie de dienst heeft overgenomen": Map({
        name: "Collega freelancer heeft deze dienst overgenomen. Geef direct via mail door wie de dienst heeft overgenomen",
        useagreedhours: false
    }),
    "Cliënt/vertegenwoordiger heeft deze dienst tijdig afgemeld, meer dan 24 uur voorafgaand": Map({
        name: "Cliënt/vertegenwoordiger heeft deze dienst tijdig afgemeld, meer dan 24 uur voorafgaand",
        useagreedhours: false
    }),
    "Cliënt/vertegenwoordiger heeft deze dienst niet tijdig afgemeld, minder dan 24 uur voorafgaand": Map({
        name: "Cliënt/vertegenwoordiger heeft deze dienst niet tijdig afgemeld, minder dan 24 uur voorafgaand",
        useagreedhours: true
    }),
    "Freelancer heeft door privé redenen deze dienst niet gewerkt": Map({
        name: "Freelancer heeft door privé redenen deze dienst niet gewerkt",
        useagreedhours: false
    }),
    "Deze dienst stond dubbel in mijn accordeerlijst": Map({
        name: "Deze dienst stond dubbel in mijn accordeerlijst",
        useagreedhours: false
    }),
    "Deze dienst is mij onbekend": Map({
        name: "Deze dienst is mij onbekend",
        useagreedhours: false
    }),
    "Op verzoek van cliënt/vertegenwoordiger heeft deze dienst op een andere dag plaatsgevonden. Geef direct via mail door welke dag de dienst wel heeft plaatsgevonden": Map({
        name: "Op verzoek van cliënt/vertegenwoordiger heeft deze dienst op een andere dag plaatsgevonden. Geef direct via mail door welke dag de dienst wel heeft plaatsgevonden",
        useagreedhours: false
    })
})

export function setUseAgreedHours(booking) {
    let cancelledReason = reasonsCancellation.get(booking.get('reasoncancellation'));
    let startDeviationReason = reasonsStartDeviation.get(booking.getIn(['agreedtimes','reasonstartdeviation']));
    let endDeviationReason = reasonsEndDeviation.get(booking.getIn(['agreedtimes','reasonenddeviation']));

    let startDeviated = booking.getIn(['agreedtimes','start']) != booking.getIn(['agreedtimes', 'initialStart']);
    let endDeviated = booking.getIn(['agreedtimes', 'end']) != booking.getIn(['agreedtimes','initialEnd']);

    let startUseAgreedHours = startDeviationReason && startDeviated ? startDeviationReason.get('useagreedhours') : true;
    let endUseAgreedHours = endDeviationReason && endDeviated ? endDeviationReason.get('useagreedhours') : true;
    let cancelUseAgreedHours = booking.get('cancelled') && cancelledReason ? cancelledReason.get('useagreedhours') : true;

   if(booking.get('cancelled')) {
        booking = booking.set('useagreedhours', cancelUseAgreedHours);
   } else {
        booking = booking.set('useagreedstart', startUseAgreedHours);
        booking = booking.set('useagreedend', endUseAgreedHours);
   }

    return booking;

}

export function startDebugGroup(debug, description) {
    if(debug) {
        let args = [...arguments];
        
        console.groupCollapsed(description, ...args.slice(2));
    }
}

export function debugMessage(debug, message) {
    if(debug) {
        let args = [...arguments];
        console.log(message, ...args.slice(2))
    }
}

export function endDebugGroup(debug) {
    if(debug) {
        console.groupEnd();
    }
}

export function convertTimeToMinutesAfter12(timeString) {
    let parts = timeString.split(":");
    
    let hours = Number(parts[0]) * 60;
    let minutes = Number(parts[1]);
    return hours + minutes;
    
}