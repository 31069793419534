import Immutable, { Map } from 'immutable';

export const LOAD_PORTAL_ADDRESSES = 'LOAD_PORTAL_ADDRESSES';
export const UNLOAD_PORTAL_ADDRESSES = 'UNLOAD_PORTAL_ADDRESSES';
export const UPDATE_PORTAL_ADDRESSES = 'UPDATE_PORTAL_ADDRESSES';


export default function PortalAddresses(state, action) {

    switch(action.type) {

        case LOAD_PORTAL_ADDRESSES:

            if(action.loading) {
                state = state.setIn(['loading'], true);
            }

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            return state;
        case UNLOAD_PORTAL_ADDRESSES:

            return state;
        case UPDATE_PORTAL_ADDRESSES:

            return state;

            default:
    }

    return state || Map();

}