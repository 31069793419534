import Immutable, { Map } from 'immutable';

export const LOAD_CAREDESCRIPTIONGROUPS = 'LOAD_CAREDESCRIPTIONGROUPS';
export const UNLOAD_CAREDESCRIPTIONGROUPS = 'UNLOAD_CAREDESCRIPTIONGROUPS';
export const UPDATE_CAREDESCRIPTIONGROUPS = 'UPDATE_CAREDESCRIPTIONGROUPS';


export default function CareDescriptionGroupsReducer(state, action) {

    switch(action.type) {

        case LOAD_CAREDESCRIPTIONGROUPS:

            if(action.loading) {
                state = state.setIn(['loading'], true);
            }

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            return state;
        case UNLOAD_CAREDESCRIPTIONGROUPS:

            return state;
        case UPDATE_CAREDESCRIPTIONGROUPS:

            return state;
        default:

    }

    return state || Map();

}