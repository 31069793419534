import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getWhoIsWho } from '../../Actions/Freelancers';
import {generateDisplayName} from "../../Utils/Person";
import { List } from 'immutable';
import { apiUrl } from '../../Service/EndpointService';
import './Styles/WhoIsWho.scss';

export default function WhoIsWho() {

    const dispatch = useDispatch();
    const freelancers = useSelector(state => state.getIn(['freelancers','whoiswho']));
    const freelancersLoading = useSelector(state => state.getIn(['freelancers','whoiswho_loading']));

    useEffect(() => {
        if(!freelancers && !freelancersLoading) {
            dispatch(getWhoIsWho());
        }
    })

    //getWhoIsWho
    let sortedFreelancers = (freelancers || List()).sort((a,b) => {
        let aFirstname = a.get('firstname') || "";
        let bFirstname = b.get('firstname') || "";
        return aFirstname.localeCompare(bFirstname);
    })

    return <div className="card">

        <div className="card-header">
            <h1>Wie is wie</h1>
        </div>

        <div className="card-body">

            <div className="who-is-who">

                { ( sortedFreelancers || List() ).map((freelancer) => <div className="freelancer">
                    <div>
                        <h2>{generateDisplayName(freelancer)} ({freelancer.get('id')})</h2>
                        <div dangerouslySetInnerHTML={{ __html: freelancer.getIn(['socialDetails','description'])}} />
                    </div>
                    <div>
                        { freelancer.getIn(['socialDetails','picture']) && <img src={`${apiUrl}/assets/freelancers/${freelancer.getIn(['socialDetails','picture'])}`} /> }
                    </div>
                </div>)  }
            </div>


        </div>

    </div>

}