import React, { useLayoutEffect, useState, useRef, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Map } from 'immutable';
import { useSwipeable } from 'react-swipeable';
import { generateDisplayName, generateFormalDisplayName, generateSemiFormalDisplayName, generateInformalDisplayName } from '../../Utils/Person';
import { getRedBookEntries, saveRedBookEntry, registerRead } from '../../Actions/RedBookEntries';
import RedBookEntriesReducer from '../../Reducers/RedBookEntries';
import swal from 'sweetalert2';
import { useMissedEntries } from '../../Context/MissedEntries';

const entryMainClassName = "relative py-2";
const entryStickTresholdClassName = "-top-10 h-10"
const entryStickyPartClassName = "w-full h-10 z-20 top-0 p-1 px-2 font-bold text-md sticky";
const entryStickyClassName = "bg-[rgba(255,255,255,0.4)]"
const entryServiceHeaderClassName = "p-1 bg-[#f5efdf] rounded-lg border mx-auto w-max border-[#ccc]";
const entrySubjectContainerClassName = "relative";
const entryMessageClassName = "p-2 px-4 text-md";

const entryListClassName = "relative";

const theRedBookContainerMainClassName = "fixed top-0 left-0 w-full h-full z-10 md:bg-[rgba(0,0,0,0.5)]";
const theRedBookContainerMainInnerClassName = "w-full h-full bg-white md:mx-auto md:w-[40vw] md:h-[80vh] md:mt-[10vh]";
const theRedBookContainerSavedContainerClassName = "flex-none bg-[green] flex";
const theRedBookContainerSavedClassName = "flex-1 p-1 font-bold text-center";
const theRedBookContainerSavedMessageClassName = "text-md text-[#ffffff]";
const theRedBookContainerMainBodyClassName = "relative";
const theRedBookContainerLoadingContainerClassName = "absolute w-full h-full top-0 left-0 bg-[rgba(255,255,255,0.8)] z-30";
const theRedBookContainerLoadingClassName = "mt-10 mx-auto w-max";
const theRedBookContainerLoadingSvgClassName = "animate-spin h-20 w-20 mx-auto ";
const theRedBookContainerLoadingSvgCircleClassName = "opacity-25";
const theRedBookContainerLoadingSvgPathClassName = "opacity-75";
const theRedBookContainerLoadingMessageClassName = "text-center text-2xl mt-3";
const theRedBookContainerFooterButtonClassName = "bg-[#421757] text-[#FFFFFF] text-lg font-bold p-2 w-full";

const theRedBookButtonClassName = "bg-[#dc0c22] text-[#FFFFFF] text-md uppercase  p-2 px-3";

const dayEntryScreenBodyClassName = "flex flex-col";
const dayEntryContainerClassName = "flex flex-col flex-1";
const dayEntryDateContainerClassName = "p-2";
const dayEntryDateLabelClassName = "font-bold";
const dayEntryDateInputExampleClassName = "font-normal italic text-[#aaa]";
const dayEntryDateInputClassName = "mt-1 sm:mt-0 sm:col-span-2";
const dayEntrySwipableClassName = "flex flex-1 flex-col";
const dayEntryFooterButtonClassName = "bg-[#421757] text-[#FFFFFF] text-lg font-bold p-2 w-full disabled:opacity-75";

const dateInputClassName = "input";
const dateInputErroClassName = "border-[red] border";

const subjectScreenContainerClassName = "flex-1 flex flex-col";
const subjectScreenNavigationClassName = "flex flex-none items-center";
const subjectScreenLeftArrowContainerClassName = "p-2 flex-none";
const subjectScreenLeftArrowClassName = "zmdi zmdi-arrow-left text-2xl cursor-pointer";
const subjectScreenCenterContainerClassName = "p-2 flex-1";
const subjectScreenCenterClassName = "text-2xl text-center";
const subjectScreenRightArrowContainerClassName = "p-2 flex-none";
const subjectScreenRightArrowClassName = "zmdi zmdi-arrow-right text-2xl cursor-pointer";
const subjectScreenTextAreaContainerClassName = "flex p-2 flex-1 flex-col";
const subjectScreenTextAreaClassName = "border flex-1 border-[#999] p-2";

const screenContainerClassName = "flex flex-col h-full w-full";

const screenHeaderContainerClassName = "flex-none bg-[#dc0c22] flex";
const screenHeaderArrowContainerClassName = "flex-none p-3";
const screenHeaderArrowClassName = "zmdi zmdi-arrow-left text-[#FFFFFF] text-2xl";
const screenHeaderChildrenContainerClassName = "flex-1 p-3";
const screenHeaderChildrenClassName = "text-2xl text-[#FFFFFF]";

const screenSubHeaderContainerClassName = "flex-none bg-[#db424b] flex";
const screenSubHeaderClassName = "flex-1 p-1 font-bold";
const screenSubHeaderChildrenClassName = "text-md text-[#ffffff]";

const screenBodyClassName = "flex-1 overflow-y-scroll";

const screenFooterClassName = "flex-none";

const stickyPartClassName = "absolute";

const popopContainerClassName = `z-10 w-full h-full top-0 left-0 absolute bg-[rgba(0,0,0,0.4)]`;
const popupContentClassName = `mt-[30%] mx-auto w-[90%] md:w-[30%] h-max flex flex-col bg-[white]`;
const popupMainPartClassName = `flex-1`;
const popupMessageClassName = `p-3 text-lg`;
const popupBottomPartClassName = `flex-none`;
const popupCloseButtonClassName = `w-full bg-[green] uppercase p-2 text-xl text-[#FFF]`;

const confirmationPopupContainerClassName = `z-10 w-full h-full top-0 left-0 absolute bg-[rgba(0,0,0,0.4)]`;
const confirmationPopupContentClassName = `mt-[30%] md:mt-[15vh] md:max-h-[70vh] mx-auto w-[90%] md:w-[30%] h-max flex flex-col bg-[white]`;
const confirmationPopupHeaderContainerClassName = `flex-none`;
const confirmationPopupHeaderClassName = `w-full bg-[#dc0c22] p-2 text-xl text-[#FFF]`;
const confirmationPopupBodyContainerClassName = `flex-1 max-h-[60vh] overflow-y-scroll `;
const confirmationPopupFooterContainerClassName = `flex-none`;
const confirmationPopupFooterClassName = `flex`;
const confirmationPopupNoButtonClassName = `w-full bg-[red] uppercase p-1 text-xl text-[#FFF]`;
const confirmationPopupYesButtonClassName = `w-full bg-[green] uppercase p-1 text-xl text-[#FFF]`;

const entryMainServiceInfoClassName = `px-4 italic text-center`;

const serviceSelectorSwipableClassName = "flex flex-none flex-col bg-[#DDD]";
const serviceSelectorContainerClassName = "flex-1 flex flex-col";
const serviceSelectorNavigationClassName = "flex flex-none max-h-min items-center";
const serviceSelectorLeftArrowContainerClassName = "px-1 pl-2 flex-none max-h-min";
const serviceSelectorLeftArrowClassName = "zmdi zmdi-arrow-left text-2xl cursor-pointer";
const serviceSelectorCenterContainerClassName = "px-1 flex-1 max-h-min";
const serviceSelectorRightArrowContainerClassName = "px-1 pr-2 flex-none max-h-min";
const serviceSelectorRightArrowClassName = "zmdi zmdi-arrow-right text-2xl cursor-pointer";

// LET OP: als er hier iets veranderd, moet ook het sorteer mechanisme veranderder worden, want deze er vanuit dat de waardes met een van de onderstaande keys in de subject object te vinden is.
const subjects = [
    "Algemeen welzijn",
    "Nachtrust / rust overdag / dagnacht ritme",
    "Persoonlijke verzorging / kleding",
    "Lichamelijke bijzonderheden / transfers",
    "Geestelijke welzijn",
    "Medicatie",
    "Eten en drinken",
    "Hulpmiddelen en alarmering",
    "Activiteiten",
    "Huishoudelijk / boodschappen",
    "Mantelzorger / partner",
    "Huisarts / ziekenhuisbezoek",
    "Overig"
]

function Entry({ entry, onRead }) {

    const element = useRef(null)
    const read = useRef(null)

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(function (e) {

            if (e[0].isIntersecting === true) {

                if (!read.current && onRead) {
                    read.current = true;
                    console.log("element is intersecting", entry.serviceTime);
                    console.log("calling on read");
                    onRead(entry);
                }
            }
        }, { threshold: [0] })

        observer.observe(element.current)

        return () => {
            observer.disconnect();
        }
    }, [element.current])

    let freelancerOrOffice = entry && entry.owner && (entry.owner.type == 'freelancer' || entry.owner.type == "office");
    let startTimeString = entry && entry.serviceInfo && (moment(Number(entry.serviceInfo.starttime)).format('ddd DD-MM-YYYY HH:mm')).replace('.', '');
    let endTimeString = entry && entry.serviceInfo && (moment(Number(entry.serviceInfo.endtime)).format('HH:mm'));
    let serviceTimeString = freelancerOrOffice && `${startTimeString} - ${endTimeString}`;
    let serviceFreelancer = freelancerOrOffice && entry.serviceInfo && entry.owner.type == 'freelancer' ? entry.serviceInfo.freelancerid : 'USC';
    return <div className={entryMainClassName} ref={element}>
        <StickyPart stickTreshold={entryStickTresholdClassName} className={entryStickyPartClassName} stickyClassName={entryStickyClassName}>
            <div className={entryServiceHeaderClassName}>
                {!freelancerOrOffice && <Fragment>{entry.serviceTime} {(entry.owner) && entry.owner.name || ""} </Fragment>}
                {freelancerOrOffice && entry.serviceInfo && <Fragment>Dienst: {serviceTimeString} {serviceFreelancer}</Fragment>}
                {freelancerOrOffice && !entry.serviceInfo && <Fragment>{entry.serviceTime} {(entry.owner) && entry.owner.name || ""} </Fragment>}
            </div>
        </StickyPart>
        {freelancerOrOffice && entry.serviceInfo && <div className={entryMainServiceInfoClassName}>
            Rapportage {entry.serviceTime} {(entry.owner) && entry.owner.name || ""}
        </div>}
        {subjects.map((subject) => {
            let message = entry.subjects[subject];
            if (message) {
                return <div className={entrySubjectContainerClassName} key={subject}>
                    {/* <StickyPart stickTreshold="-top-20 h-10" className="w-full h-10 z-10 p-1 px-3 font-bold top-8 sticky" stickyClassName="bg-[rgba(255,255,255,0.4)]"><div className="p-1 bg-[white] rounded-lg border mx-auto w-max border-[#ccc]">{subject.subject}</div></StickyPart> */}
                    <div className={entryMessageClassName}>{message}</div>
                </div>
            }
            return null;
        })}
    </div>

}

function EntryList({ entries, onRead }) {

    // console.log("entries", entries);

    entries.forEach((entry) => {
        let baseTime = entry.created;
        if(entry.serviceInfo && entry.serviceInfo.endtime) {
            baseTime = entry.serviceInfo.endtime;
        }
        entry.baseTime = baseTime;
    })

    entries.sort((a,b) => {
        return a.baseTime - b.baseTime;
    })

    return <div className={entryListClassName}>
        {entries.map((entry, index) => {
            return <Entry key={index.toString()} onRead={onRead} entry={entry} />
        })}
    </div>
}





function TheRedBookContainer({ onClose, client, freelancer, profileplanning }) {

    const { currentMissedEntry } = useMissedEntries();
    const dispatch = useDispatch();
    const { checkOtherMissingEntries, setCurrentMissedEntry } = useMissedEntries();
    const _entries = useSelector(state => state.getIn(['redbook', 'collection']));
    const hasRetrieveError = useSelector(state => state.getIn(['redbook', 'error']));
    const loading = useSelector(state => state.getIn(['redbook', 'loading']));
    const [entryToCreate, setEntryToCreate] = useState(undefined)
    const [savedMessage, setSavedMessage] = useState(false);
    const entries = _entries ? _entries.toJS() : [];

    const onAddButtonClick = () => setEntryToCreate({})

    useEffect(() => {
        if(currentMissedEntry) {
            setEntryToCreate({})
        }
    }, [currentMissedEntry])

    const onEntryClose = () => {
        setEntryToCreate(undefined);
        setCurrentMissedEntry(undefined);
    }

    const onRead = async (entry) => {
        registerRead({ email: freelancer.get('email'), entryid: entry.id, freelancerid: freelancer.get('id') })
    }

    const onSave = async (entry) => {
        entry.clientid = client.get('id');
        entry.client = generateDisplayName(client);
        try {
            let response = await saveRedBookEntry(entry);
            dispatch(getRedBookEntries({ clientid: client.get('id') }));
            setEntryToCreate(undefined);
            setSavedMessage(true);
            removeEntryCache(client.get('id'));
            checkOtherMissingEntries();
            setTimeout(() => {
                setSavedMessage(false);
            }, 2000)
            
            
        } catch (err) {
            console.log("request failed");
            swal.fire({
                title: 'Rapportage niet gelukt',
                text: 'Je rapportage is niet geplaatst, er is geen internetverbinding, probeer het later nogmaals',
                type: 'error'
            })
        }
    }

    useEffect(() => {
        dispatch(getRedBookEntries({ clientid: client.get('id') }));
    }, [])



    return <div className={theRedBookContainerMainClassName} onClick={onClose}>
        <div className={theRedBookContainerMainInnerClassName} onClick={(e) => e.stopPropagation()}>
            {!entryToCreate && <ScreenContainer>
                <ScreenHeader onClose={onClose}>Het rode schriftje</ScreenHeader>
                <ScreenSubHeader>{generateFormalDisplayName(client)} ({client.get('id')})</ScreenSubHeader>
                {savedMessage && <div className={theRedBookContainerSavedContainerClassName}>
                    <div className={theRedBookContainerSavedClassName}>
                        <span className={theRedBookContainerSavedMessageClassName}>Je rapportage is succesvol geplaatst</span>
                    </div>
                </div>}
                <ScreenBody automaticallyScrollToBottom={true}>
                    <div className={theRedBookContainerMainBodyClassName}>
                        {loading && <div className={theRedBookContainerLoadingContainerClassName}>
                            <div className={theRedBookContainerLoadingClassName}>
                                <svg className={theRedBookContainerLoadingSvgClassName} viewBox="0 0 24 24">
                                    <circle className={theRedBookContainerLoadingSvgCircleClassName} cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className={theRedBookContainerLoadingSvgPathClassName} fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <div className={theRedBookContainerLoadingMessageClassName}>
                                    Bezig met laden...
                                </div>
                            </div>
                        </div>}
                        <EntryList onRead={onRead} entries={entries} />
                    </div>
                </ScreenBody>
                <ScreenFooter>
                    <button className={theRedBookContainerFooterButtonClassName} onClick={onAddButtonClick}>
                        Schrijf rapportage
                    </button>
                </ScreenFooter>
            </ScreenContainer>}
            {entryToCreate && <DayEntry key={client && client.get('id') || "-"} profileplanning={profileplanning} onSave={onSave} freelancer={freelancer} client={client} onClose={onEntryClose} />}
        </div>
    </div>
}

export function TheReadBookButton({ client, freelancer, profileplanning }) {
    const missedEntriesContext = useMissedEntries();
    const { currentMissedEntry, setCurrentMissedEntry } = missedEntriesContext;
    const [expanded, setExpanded] = useState();

    useEffect(() => {
        if(currentMissedEntry) {
            setExpanded(() => true);
        }
    }, [currentMissedEntry])

    return <div>
        {!expanded && <button className={theRedBookButtonClassName} onClick={() => setExpanded(true)}>Het rode schriftje</button>}
        {expanded && <TheRedBookContainer profileplanning={profileplanning} freelancer={freelancer} client={client} onClose={() => { setExpanded(false);setCurrentMissedEntry(undefined) }} />}
    </div>
}

function setEntryCache(key, entry, expiredIn, cacheOwner) {
    let cache = localStorage.getItem('entryCache');
    if(!cache) {
        cache = {};
    } else {
        cache = JSON.parse(cache);
    }
    cache = {
        ...entry,
        cacheOwner,
        expiredAt: moment().add(expiredIn, 'seconds').format('DD-MM-YYYY HH:mm')
    }
    localStorage.setItem(`entry-${key}`, JSON.stringify(cache));
}

function removeEntryCache(key) {
    localStorage.removeItem(`entry-${key}`);
}

function getEntryCache(key, cacheOwner) {
    let cache = localStorage.getItem(`entry-${key}`);
    if(!cache) {
        return undefined;
    }
    cache = JSON.parse(cache);
    if(moment().isAfter(cache.expiredAt)) {
        localStorage.removeItem(`entry-${key}`);
        return undefined;
    }
    if(cacheOwner && cache.cacheOwner != cacheOwner) {
        return undefined;
    }
    return cache;
}

// moment
function DayEntry({ onClose, client, onSave, freelancer, profileplanning }) {
    const cacheOwner = freelancer.get('id');
    const cacheEntry = getEntryCache(`${client.get('id')}`, cacheOwner) || {};
   
    console.log("DayEntry cacheEntry", cacheEntry);
    const [receivedPopup, setReceivedPopup] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [selectedProfilePlanning, _setSelectedProfilePlanning] = useState(cacheEntry && cacheEntry.profileplanning || profileplanning.get(0));

    const dateExp = /([0-9]{1,2})\-([0-9]{1,2})\-([0-9]{4})\s*([0-9]{1,2}):([0-9]{1,2})/;

    useEffect(() => {
       if(cacheEntry && cacheEntry.profileplanning) {
            let cachedEntryPlanning = profileplanning.find((planning) => planning.get('id') == cacheEntry.profileplanning.id);
            if(cachedEntryPlanning) {
                _setSelectedProfilePlanning(cachedEntryPlanning);
            }
        }


    }, [profileplanning]);

    let [date, _setDate] = useState(cacheEntry && cacheEntry.date|| moment().format('DD-MM-YYYY HH:mm'));
    let [entrySubjects, _setEntrySubjects] = useState(cacheEntry && cacheEntry.entrySubjects || {});
    let [currentSubject, _setCurrentSubject] = useState(cacheEntry && cacheEntry.currentSubject || subjects[0])

    const onCache = (newData) => {
        setEntryCache(`${client.get('id')}`, { 
            ...cacheEntry, 
            date, entrySubjects, currentSubject
            ,...newData  
        }, 60 * 60 * 24, cacheOwner);
        // setEntryCache(`${client.get('id')}`, { ...cacheEntry, date  }, 60 * 60 * 24);
        // setEntryCache(`${client.get('id')}`, { ...cacheEntry, entrySubjects }, 60 * 60 * 24);
        // setEntryCache(`${client.get('id')}`, {  ...cacheEntry, currentSubject }, 60 * 60 * 24);
    }

    const setSelectedProfilePlanning = (profileplanning) => {
        onCache({ profileplanning });
        _setSelectedProfilePlanning(profileplanning);
    }

    const setDate = (date) => {
        onCache({ date });
        _setDate(date);
    }

    const setEntrySubjects = (entrySubjects) => {
        onCache({ entrySubjects });
        _setEntrySubjects(entrySubjects);
    }

    const setCurrentSubject = (currentSubject) => {
        onCache({ currentSubject });
        _setCurrentSubject(currentSubject);
    }


    const nextSubject = () => {
        console.log("is next subject working?")
        let index = subjects.indexOf(currentSubject);
        setCurrentSubject(subjects[((index + 1) % subjects.length)])
    }

    const previousSubject = () => {
        let index = subjects.indexOf(currentSubject);
        index = index == 0 ? subjects.length - 1 : (index - 1);
        setCurrentSubject(subjects[index])
    }

    const onSubjectEntry = (v) => {
        setEntrySubjects({ ...entrySubjects, [currentSubject]: v })
    }

    const onProfilePlanningSelected = (profileplanning) => {
        setSelectedProfilePlanning(profileplanning)
    }

    const pad = (value, amount, char) => {
        let str = value.toString();
        while (str.length < amount) {
            str = char.toString() + str;
        }
        return str;
    }
    
    const formatTimeString = (date) => {
        const [fullDate, day, month, year, hours, minutes] = date.match(dateExp);
        const dateString = `${pad(day, 2, "0")}-${pad(month, 2, "0")}-${pad(year, 4, "0")} ${pad(hours, 2, "0")}:${pad(minutes, 2, "0")}`;
        console.log(`formatted ${date} to ${dateString}`)
        return dateString;
    }

    const saveEntry = () => {
        let serviceTimeStamp = undefined;
        if (!(date || "").match(dateExp)) {
            return;
        }

        let dateString = formatTimeString((date));
        let dateObject = moment(dateString, 'DD-MM-YYYY HH:mm');
        if (!dateObject.isValid()) {
            return;
        }

        serviceTimeStamp = Number(dateObject.format('x'))
        let entryToSave = {
            serviceTime: date,
            serviceTimeStamp: serviceTimeStamp,
            owner: { // NOTE THAT EMAIL AND TYPE WILL BE REEENTERED ON THE SERVER FOR SECURITY PURPOSES
                name: generateInformalDisplayName(freelancer),
                email: freelancer.get('email'),
                type: "freelancers"

            },
            serviceInfo: {
                profileplanningid: selectedProfilePlanning.get('id'),
                starttime: selectedProfilePlanning.get('starttime'),
                endtime: selectedProfilePlanning.get('endtime')
            },
            subjects: entrySubjects,
            freelancer: generateInformalDisplayName(freelancer)

        };
        setConfirmation(entryToSave);
    }

    const saveEntryAfterConfirmation = () => {
        let entryToSave = confirmation;
        onSave(entryToSave);
        setConfirmation(false);
    }

    const onConfirmationCancel = () => {
        console.log("on confimation cancel");
        setConfirmation(false);
    }

    const dateValidPattern = (date || "").match(dateExp);
    let validDate = false;
    if (dateValidPattern) {
        let timeString = formatTimeString(date);
        let dateObject = moment(timeString, 'DD-MM-YYYY HH:mm');
        if (dateObject.isValid()) {
            validDate = true;
        }
    }

    let subjectNumber = subjects.indexOf(currentSubject) + 1;

    return <ScreenContainer>
        <ScreenHeader onClose={onClose}>Rapportage</ScreenHeader>
        <ScreenSubHeader>{generateFormalDisplayName(client)} ({client.get('id')})</ScreenSubHeader>
        <ScreenBody className={dayEntryScreenBodyClassName}>
            {!receivedPopup && <Popup onClose={() => setReceivedPopup(true)}></Popup>}
            {confirmation && <ConfirmationPopup onConfirm={saveEntryAfterConfirmation} onCancel={onConfirmationCancel}><Entry entry={confirmation} /></ConfirmationPopup>}
            <div className={dayEntryContainerClassName}>
                <div className={dayEntryDateContainerClassName}>
                    <label htmlFor="service-date" className={dayEntryDateLabelClassName}>
                        Datum/tijd
                        {/* <span className={dayEntryDateInputExampleClassName}>(voorbeeld 01-01-1970 00:00)</span> */}
                    </label>
                    <div className={dayEntryDateInputClassName}>
                        <DateInput error={!validDate} name={"service-date"} value={date} id={"service-date"} onChange={(v) => { setDate(v) }} />
                    </div>
                </div>
                <ServiceSelector profileplanning={profileplanning} selected={selectedProfilePlanning} onSelect={onProfilePlanningSelected} />
                <Swipeable
                    className={dayEntrySwipableClassName}
                    onSwipedLeft={nextSubject}
                    onSwipedRight={previousSubject}>
                    <SubjectScreen
                        onPrevious={previousSubject}
                        onNext={nextSubject}
                        subjectNumber={subjectNumber}
                        onChange={onSubjectEntry}
                        subject={currentSubject}
                        value={entrySubjects[currentSubject] || ""}
                    />
                </Swipeable>
            </div>
        </ScreenBody>
        <ScreenFooter>
            <button className={dayEntryFooterButtonClassName} disabled={!validDate} onClick={saveEntry}>Opslaan</button>
        </ScreenFooter>
    </ScreenContainer>
}

function ServiceSelector({ profileplanning, onSelect, selected }) {

    const onPrevious = () => {
        let currentIndex = profileplanning.indexOf(selected);
        currentIndex = currentIndex < 0 ? 0 : currentIndex;
        if ((currentIndex + 1) != profileplanning.size) {
            let newIndex = (currentIndex + 1) % profileplanning.size
            onSelect(profileplanning.get(newIndex));
        }
    }

    const onNext = () => {
        let currentIndex = profileplanning.indexOf(selected);
        currentIndex = currentIndex < 0 ? 0 : currentIndex;
        if ((currentIndex - 1) > -1) {
            let newIndex = (currentIndex - 1) > -1 ? currentIndex - 1 : profileplanning.size - 1;
            onSelect(profileplanning.get(newIndex));
        }
    }

    let currentIndex = profileplanning.indexOf(selected);
    currentIndex = currentIndex < 0 ? 0 : currentIndex;

    let current = profileplanning.get(currentIndex);
    let serviceStart = (moment(Number(current.get('starttime'))).format('ddd DD-MM-YYYY HH:mm')).replace('.', '');
    let serviceEnd = moment(Number(current.get('endtime'))).format('HH:mm');

    return <Swipeable
        className={serviceSelectorSwipableClassName}
        onSwipedLeft={onNext}
        onSwipedRight={onPrevious}>
        <div className={serviceSelectorContainerClassName}>
            <div className={serviceSelectorNavigationClassName}>
                <div className={serviceSelectorLeftArrowContainerClassName}>
                    {(currentIndex + 1) != profileplanning.size && <i className={serviceSelectorLeftArrowClassName} onClick={onPrevious} />}
                </div>
                <div className={serviceSelectorCenterContainerClassName}>
                    <div className="text-center pt-2">
                        Kies met pijltje betreffende dienst
                    </div>
                    <h2 className={`text-center leading-1`}>Dienst: {serviceStart} - {serviceEnd}</h2>
                </div>
                <div className={serviceSelectorRightArrowContainerClassName}>
                    {(currentIndex - 1) > -1 && <i className={serviceSelectorRightArrowClassName} onClick={onNext} />}
                </div>
            </div>
        </div>
    </Swipeable>

}

function Popup({ isOpen, onClose, children, buttonLabel }) {
    return <div className={popopContainerClassName} onClick={onClose}>
        <div className={popupContentClassName}>
            <div className={popupMainPartClassName}>
                <div className={popupMessageClassName}>
                    Opslaan als over betreffende onderwerpen gerapporteerd is.<br />
                    NB De naam van het onderwerp wordt niet opgenomen in de rapportage.
                </div>
            </div>
            <div className={popupBottomPartClassName}>
                <button className={popupCloseButtonClassName} onClick={onClose}>{buttonLabel || 'Ok'}</button>
            </div>
        </div>
    </div>
}

function ConfirmationPopup({ isOpen, onClose, children, buttonLabel, onCancel, onConfirm }) {
    return <div className={confirmationPopupContainerClassName} onClick={onClose}>
        <div className={confirmationPopupContentClassName}>
            <div className={confirmationPopupHeaderContainerClassName}>
                <div className={confirmationPopupHeaderClassName}>Weet je het zeker?</div>
            </div>
            <div className={confirmationPopupFooterContainerClassName}>
                <div className={confirmationPopupFooterClassName}>
                    <button className={confirmationPopupNoButtonClassName} onClick={onCancel}>Nee</button>
                    <button className={confirmationPopupYesButtonClassName} onClick={onConfirm}>Ja</button>
                </div>
            </div>
            <div className={confirmationPopupBodyContainerClassName}>
                {children}
            </div>
            <div className={confirmationPopupFooterContainerClassName}>
                <div className={confirmationPopupFooterClassName}>
                    <button className={confirmationPopupNoButtonClassName} onClick={onCancel}>Nee</button>
                    <button className={confirmationPopupYesButtonClassName} onClick={onConfirm}>Ja</button>
                </div>

            </div>
        </div>
    </div>
}

function SubjectScreen({ onChange, value, subject, onPrevious, onNext, subjectNumber }) {
    return <div className={subjectScreenContainerClassName}>
        <div className={subjectScreenNavigationClassName}>
            <div className={subjectScreenLeftArrowContainerClassName}>
                <i className={subjectScreenLeftArrowClassName} onClick={onPrevious} />
            </div>
            <div className={subjectScreenCenterContainerClassName}>
                <h2 className={subjectScreenCenterClassName}>{subjectNumber}. {subject}</h2>
            </div>
            <div className={subjectScreenRightArrowContainerClassName}>
                <i className={subjectScreenRightArrowClassName} onClick={onNext} />
            </div>
        </div>
        <div className={subjectScreenTextAreaContainerClassName}>
            <textarea className={subjectScreenTextAreaClassName} onChange={(e) => onChange(e.target.value)} value={value} />
        </div>
    </div>
}

function DateInput({ onChange, value, error, label, name, id }) {
    let className = "";
    if (error) {
        className += dateInputErroClassName;
    }
    return <div className={`${dateInputClassName} ${className}`}>
        <input placeholder={label} value={value || ""} onChange={(e) => onChange(e.target.value)} />
    </div>
}

function Swipeable(props) {
    const handlers = useSwipeable({
        onSwipedLeft: () => props.onSwipedLeft(),
        onSwipedRight: () => props.onSwipedRight()
    });
    return <div className={props.className} >{props.children}</div>;
}

function ScreenContainer({ children }) {
    return <div className={screenContainerClassName}>
        {children}
    </div>
}

function ScreenHeader({ children, onClose }) {
    return <div className={screenHeaderContainerClassName}>
        <div className={screenHeaderArrowContainerClassName}>
            <i onClick={onClose} className={screenHeaderArrowClassName}></i>
        </div>
        <div className={screenHeaderChildrenContainerClassName}>
            <span className={screenHeaderChildrenClassName}>{children}</span>
        </div>
    </div>
}

function ScreenSubHeader({ children }) {
    return <div className={screenSubHeaderContainerClassName}>
        <div className={screenSubHeaderClassName}>
            <span className={screenSubHeaderChildrenClassName}>{children}</span>
        </div>
    </div>
}

function ScreenBody({ children, className, automaticallyScrollToBottom }) {
    const elementRef = useRef(null);
    useLayoutEffect(() => {
        elementRef.current.scrollTo(0, elementRef.current.scrollHeight);
    }, [children])
    return <div className={`${screenBodyClassName} ${className}`} ref={elementRef}>{children}</div>
}

function ScreenFooter({ children, className }) {
    return <div className={` ${screenFooterClassName}  ${className || ""}`}>{children}</div>
}

function StickyPart({ className, children, stickyClassName, stickTreshold }) {
    const elementRef = useRef(null);
    const listenerElementRef = useRef(null);
    useLayoutEffect(() => {
        const observer = new IntersectionObserver(
            ([e]) => {
                if (e.intersectionRatio === 1) {
                    (stickyClassName || "").split(" ").forEach((classNamePart) => {
                        elementRef.current.classList.remove(classNamePart, e.isIntersecting && e.intersectionRatio > 0 && e.intersectionRatio < 1)

                    });
                } else if (e.intersectionRatio === 0) {
                    (stickyClassName || "").split(" ").forEach((classNamePart) => {
                        elementRef.current.classList.add(classNamePart, e.isIntersecting && e.intersectionRatio > 0 && e.intersectionRatio < 1)

                    });
                }
            }, { threshold: [0, 1] }
        );
        observer.observe(listenerElementRef.current);
        return () => {
            observer.disconnect();
        }
    }, [listenerElementRef.current])

    return <>
        <div ref={listenerElementRef} className={`${stickyPartClassName} ${stickTreshold}`}>&nbsp;</div>
        <div className={` ${className}`} ref={elementRef}>{children}</div>
    </>
}
