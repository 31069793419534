import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCareDescriptionParts } from '../../Actions/CareDescriptionParts';
import { getCareDescriptionGroups } from '../../Actions/CareDescriptionGroups';
import { getDocumentTypes } from '../../Actions/DocumentTypes';
import { saveClient } from '../../Actions/Clients';
import { saveAndGenerate } from '../../Actions/Documents';
import { getFreelancersInClientTeam } from '../../Actions/Freelancers';
import { sendCareMessage } from '../../Actions/Mail';
import CarePlanPrintV2 from './CareplanPrintV2';

import Immutable, { OrderedMap, List, Map } from 'immutable';
import Input from '../Controls/Input';
import CheckBox from '../Controls/CheckBox';
import Textarea from '../Controls/Textarea';
import DateToTimeStampInput from '../Controls/DateToTimeStampInput';
import SingleClientCarePlanPrint from './SingleClientCarePlanPrint';
import SingleClientCarePlanPrintFooter from './SingleClientCarePlanPrintFooter';
import moment from 'moment';
import PdfService from '../../Service/PdfService';
import {generateDisplayName} from "../../Utils/Person";
import swal from "sweetalert2";
import ReactTooltip from 'react-tooltip';

const apiUrl = window.location.href.match('localhost:30') ? "http://localhost:8080" : "";

const mapStateToProps = (state) => {
    
    let caregroups = state.getIn(['caredescriptiongroups','collection'], OrderedMap());
    let careparts = state.getIn(['caredescriptionparts','collection'], OrderedMap());

    let groupedparts = careparts.groupBy(x => x.get('caredescriptiongroupid'));

    // console.log("groupedparts", groupedparts);

    caregroups = caregroups.map((group) => {
        return group.update('items', List(), x => x.push(...groupedparts.get(group.get('id'), OrderedMap()).toList()))
    })

    
    return {
        caredescriptiongroups: caregroups,
        caredescriptionparts: careparts,
        documenttypes: state.getIn(['documenttypes', 'collection'], OrderedMap()),
        clients: state.getIn(['clients','collection'], OrderedMap()),
        freelancer: state.getIn(['freelancers','single'], Map()),
    }
}

const mapDispatchToProps = (dispatch) => ({
    getCareDescriptionGroups: () => (dispatch(getCareDescriptionGroups())),
    getCareDescriptionParts: () => (dispatch(getCareDescriptionParts())),
    getDocumentTypes: () => (dispatch(getDocumentTypes())),
    saveClient: (client) => (dispatch(saveClient(client))),
    saveAndGenerate: (pdfObject) => (dispatch(saveAndGenerate(pdfObject)))
})

class SingleClientCarePlan extends Component {

    constructor(props) {
        super(props);
        this.state = { client: undefined, freelancers: OrderedMap() }
        this.updateClient = this.updateClient.bind(this);
        this.generateCarePlan = this.generateCarePlan.bind(this);
        this.saveCarePlan = this.saveCarePlan.bind(this);
        this.startGeneratePlan = this.startGeneratePlan.bind(this);
    }

    updateClient(client) {
        this.setState({
            client: client,
            generating: ''
        })
    }

    changeValue(field, value) {


        // console.log("SingleClientCarePlan changeValue called");

        this.setState(({ client }) => ({
            client: client.setIn(field, value)
        }))
    }

    componentDidMount() {


        let { getCareDescriptionGroups, getCareDescriptionParts, getDocumentTypes, client } = this.props;

        if(getCareDescriptionGroups) getCareDescriptionGroups();
        if(getCareDescriptionParts) getCareDescriptionParts();
        if(getDocumentTypes) getDocumentTypes();

        this.setState({
            client: this.addNonExistingDependencies(client || OrderedMap())
        })


        getFreelancersInClientTeam(this.props.client.get('id')).then((freelancers) => {
            this.setState({
                freelancers: freelancers.groupBy(x => x.get('id')).map(x => x.first()),
                loadingFreelancers: false
            })
        })
        

    }

    // Om een json veld toe te voegen als deze er niet was
    addNonExistingDependencies(client) {
        if(!client.get('caredescription')) {
            client = client.set('caredescription', Map());
        }
        if(!client.getIn(['caredescription','careplan'])) {
            client = client.setIn(['caredescription','careplan'], Map());
        }
        return client;
    }

    componentDidUpdate(prevProps) {
        if(prevProps.client != this.props.client) {
            // console.log("found a different client on the state");
            this.setState({
                client: this.addNonExistingDependencies(this.props.client)
            })
            getFreelancersInClientTeam(this.props.client.get('id')).then((freelancers) => {
                this.setState({
                    freelancers: freelancers.groupBy(x => x.get('id')).map(x => x.first()),
                    loadingFreelancers: false
                })
            })
        }
    }

    saveCarePlan() {
        let { client } = this.state;
        let { saveClient, caredescriptiongroups, documenttypes, saveAndGenerate } = this.props;

        let careplandate = Number(client.getIn(['caredescription', 'careplan', 'date']) || Date.now())
        if(saveClient) {
            saveClient(client).then(() => {
                swal.fire({
                    'title': 'Zorgplan is opgeslagen',
                    'text': 'De zorgplan is successvol opgeslagen',
                    'type': 'success'
                })
            }).catch(() => {
                swal.fire({
                    'title': 'Een fout bij het opslaan van de zorgplan',
                    'text': 'Er heeft zich een fout voorgedaan bij het opslaan van de zorgplan, probeer het opnieuw! Mocht deze fout zich blijven voordoen neem dan contact op met de planning',
                    'type': 'error'
                })
            })

        }
    }

    getPortalRights(freelancer) {
        let rights = Map()

        if(freelancer && freelancer.get('agreements')) {
        
            let agreements = freelancer.get('agreements');
            agreements.forEach((agreement) => {
                
                if(agreement.get('clientportal')) {


                    agreement.get('clientportal').forEach((value, index) => {
                        if(value) {
                            rights = rights.set(index, value)
                        }
                        
                    })

                }

            })

        }


        return rights;
        
        
    }

    startGeneratePlan() {
        let { client } = this.state;
        let { generateCarePlan } = this;
        if(!client.getIn(['caredescription','careplan','date'])) {
            swal.fire({
                'title': 'Zorgplan niet volledig',
                'text': 'Je moet een geldige datum invoeren voordat je het zorgplan kan genereren',
                'type': 'error'
            })
        } else {
            swal.fire({
                title: 'Zorgplan genereren',
                text: 'Een nieuw zorgplan of een aangepaste versie van het zorgplan wordt hierbij definitief en teamleden en het USC kantoor ontvangen een bericht dat een nieuw zorgplan of een aangepaste versie van het zorgplan beschikbaar is. Weet je zeker dat het klaar is voor publicatie?',
                showCancelButton: true,
                cancelButtonText: "Nee",
                confirmButtonText: "Ja"
            }).then((response) => {
                if(response.value) {
                    generateCarePlan();
                }
            })
        }
    }

    generateCarePlan() {
        let { client, freelancers } = this.state;
        let { saveClient, caredescriptiongroups, documenttypes, saveAndGenerate, freelancer } = this.props;

        let careplandate = Number(client.getIn(['caredescription', 'careplan', 'date']) || Date.now()) 

        documenttypes = (documenttypes || List());
        documenttypes = documenttypes.filter(x => x.get("entity") == "clients");
        let carePlanDocumentType = documenttypes.filter(x => x.get("name").toLowerCase().match('zorgplan')).first();

        let pageFooterStyle = "text-align: right; width:100%; padding-right: 20px; font-size:10px !important; color:#808080;";
        let pageFooter = `<div style="${pageFooterStyle}">Blad <span class="pageNumber"></span> van <span class="totalPages"></span>&nbsp;&nbsp;&nbsp;${moment(careplandate).format('DD-MM-YYYY')} ${client.get('id')}</div>`;

        let pdfObject = {};
        pdfObject.html = PdfService.renderToStatic(<CarePlanPrintV2 caredescriptionparts={caredescriptiongroups} selectedclient={client} />);
        // pdfObject.footer = PdfService.renderToStatic(<SingleClientCarePlanPrintFooter client={client} />);
        pdfObject.filename = "clientinformatie-en-zorgplan-" + client.get('id') + "-" + moment(careplandate).format('DD-MM-YYYY')+"-gegenereerd_op_" + moment().format('DD-MM-YYYY-HH-mm') +".pdf";
        pdfObject.name = `Zorgplan versie ${moment(careplandate).format('DD-MM-YYYY')}`;
        pdfObject.entityid = client.get("id");
        pdfObject.visibleforfreelancer = true;
        pdfObject.entity = "clients";
        pdfObject.typeid = (carePlanDocumentType || Map()).get("id");
        pdfObject.settings = {
            printBackground: true,
            displayHeaderFooter: true,
            preferCSSPageSize: true,
            continuePageCount: true,
            skipPageCalculation: true,
            headerTemplate: '<div></div>',
            footerTemplate: pageFooter,
            margin: {
                top: '0mm', bottom: '0mm', left: '0mm', right: '0mm'
            }
        }

      
        this.setState({
            generating: 'Bezig met genereren...'
        })

        let careplanerror = 'Er heeft zich een fout voorgedaan bij het genereren van het zorgplan, probeer het nogmaals. Helaas zijn je aanpassingen niet opgeslagen. Gebeurt dit een tweede keer, neem dan contact op met U Staat Centraal';

       

        if(saveClient) {
            // console.log("generating the careplan");
            // console.log(saveClient(client));

            saveClient(client).then(() => {
                 
                careplanerror = 'Er heeft zich een fout voorgedaan bij het genereren van het zorgplan, je wijzigingen zijn wel opgeslagen,  maar zijn niet zichtbaar bij het downloaden. Gebeurt dit een tweede keer neem dan contact op met U Staat Centraal';
               
                if(saveAndGenerate) {
                    return saveAndGenerate(pdfObject)
                }
                return true;
            }).then(() => {
                
                let chain = Promise.resolve();
                careplanerror = 'Er heeft zich een fout voorgedaan bij het versturen van een notificatie aan jouw team. Het zorgplan is wel opgeslagen en het aangepaste zorgplan is in te zien bij het downloaden. Neem gelijk contact op met U Staat Centraal';
                let aanhefObject = {'m': 'dhr.', 'v': 'mw.'};
                let aanhef = aanhefObject[(client.get('gender', '') || "").toLowerCase()] || 'dhr/mw';
                
                console.log("freelancers", freelancers);

                freelancers.forEach((freelancerInTeam) => {


                    let rights = this.getPortalRights(freelancerInTeam);
                    if(rights.get('canseeclientinfo')) {
                        let subject = `Nieuw zorgplan - ${aanhef} ${generateDisplayName((client))}`;
                        let message = getCarePlanNotificationMessage(freelancer, freelancerInTeam, client);
                        let to = freelancerInTeam.get('email');
                        chain = chain.then(sendCareMessage(Map({ subject, message, to })));
                    }

                    



                })

                let subject = `Nieuw zorgplan - ${client.get('id')}`;
                let message = getCarePlanNotificationMessage(freelancer, Map({ firstname: "Kantoormedewerker", email: "" }), client);
                let to = "info@u-staat-centraal.nl";
                chain = chain.then(sendCareMessage(Map({ subject, message, to })));

                chain = chain.then(() => {
                     this.setState({
                        generating: ''
                    });
                    return true;
                })

                return chain;

            }).then(() => {
                console.log("generateCarePlan document was created");
                swal.fire({
                    title: 'Het zorgplan is gegenereerd',
                    text: 'Het zorgplan is gegenereerd, een notificatie is verstuurd naar actieve teamleden',
                    type: 'success'
                })
            }).catch((err) => {
                console.log("an error has occurred in generateCarePlan");
                console.log(err);

                swal.fire({
                    title: 'Fout',
                    text: careplanerror,
                    type: 'error'
                })

                this.setState({
                    generating: ''
                })
            })

        }


    }
    



    render() {

        let { caredescriptiongroups, caredescriptionparts, documenttypes, freelancer } = this.props;
        let { client, freelancers } = this.state;

        if(!client) {
            return <data />
        }
    
        return <div className="card single-client-care-plan">

            <div className="card-body">
                 <ReactTooltip />
                <div className="careplan-header">
                    <div className="careplan-buttons">
                        <button className="btn btn-success" data-html="true" data-tip={'Aanpassingen worden opgeslagen en verder nergens getoond, een ander moment kan je verder werken.'} onClick={this.saveCarePlan}>Opslaan</button>&nbsp;&nbsp;
                        <button 
                            className="btn btn-success" 
                            date-html="true" 
                            data-tip={'Een nieuw zorgplan of een aangepaste versie van het zorgplan wordt opgeslagen in het dossier van de client en is daar te downloaden. Iedereen uit het team en het USC kantoor ontvangt in een automatische e-mail hier bericht over.'} 
                            onClick={this.startGeneratePlan} disabled={this.state.generating}>{this.state.generating || 'Genereer'}</button>&nbsp;&nbsp;
                        {/* <button className="btn btn-primary">Concept bekijken</button> */}
                        { client && <CarePlanConceptButton 
                            documenttypes={documenttypes} 
                            client={client}
                            caredescriptiongroups={caredescriptiongroups}
                            caredescriptionparts={caredescriptionparts}
                        /> }
                    </div>
                </div>
                <div className="care-description">
                    <h3>Datum</h3>
                    <div>
                        <DateToTimeStampInput 
                            label="Datum" 
                            value={client.getIn(['caredescription','careplan','date'])} 
                            onChange={this.changeValue.bind(this, ['caredescription', 'careplan','date'])}
                            />
                    </div>
                </div>

                <div className="care-description">
                    <h3>Sociale situatie</h3>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Kinderen / kleinkinderen" 
                            value={client.getIn(['caredescription', 'anamnese', 'social', 'children'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'social', 'children'])}
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Arbeidsverleden, interesses, hobby's" 
                            value={client.getIn(['caredescription', 'anamnese', 'social', 'workhistory'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'social', 'workhistory'])}
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Overige (mantel)zorg, huishoudelijke hulp" 
                            value={client.getIn(['caredescription', 'anamnese', 'social', 'othercare'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'social', 'othercare'])}
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true}
                            value={client.getIn(['caredescription', 'anamnese', 'social', 'partner'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'social', 'partner'])}
                            label="Bijzonderheden partner" />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Bijzonderheden" 
                            value={client.getIn(['caredescription', 'anamnese', 'social', 'notes'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'social', 'notes'])}
                            />
                    </div>


                </div>

                <div className="care-description">
                    <h3>Medische situatie</h3>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            value={client.getIn(['caredescription', 'anamnese', 'medical', 'details'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'medical', 'details'])}
                            label="Ziektebeeld" 
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            value={client.getIn(['caredescription', 'anamnese', 'medical', 'physicalcondition'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'medical', 'physicalcondition'])}
                            label="Lichamelijke conditie" 
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            value={client.getIn(['caredescription', 'anamnese', 'medical', 'mentalcondition'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'medical', 'mentalcondition'])}
                            label="Geestelijke conditie" 
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            value={client.getIn(['caredescription', 'anamnese', 'medical', 'allergies'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'medical', 'allergies'])}
                            label="Bekende allergi&euml;n" 
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            value={client.getIn(['caredescription', 'anamnese', 'medical', 'notes'])}
                            onChange={this.changeValue.bind(this, ['caredescription', 'anamnese', 'medical', 'notes'])}
                            label="Reanimatiewens" 
                            />
                    </div>
                </div>

                {caredescriptiongroups.map((group, i) => <CareDescriptionGroup key={i.toString()} group={group} updateClient={this.updateClient} client={client} updateValue={this.changeValue.bind(this)} />).toList()}

                <div className="care-description">

                    <h3>Taken</h3>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Maandag" 
                            rows={3}  
                            value={client.getIn(['caredescription', 'careplan', 'monday'])} 
                            onChange={this.changeValue.bind(this, ['caredescription', 'careplan', 'monday'])}
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Dinsdag" 
                            rows={3}  
                            value={client.getIn(['caredescription', 'careplan', 'tuesday'])} 
                            onChange={this.changeValue.bind(this, ['caredescription', 'careplan', 'tuesday'])}
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Woensdag" 
                            rows={3}  
                            value={client.getIn(['caredescription', 'careplan', 'wednesday'])} 
                            onChange={this.changeValue.bind(this, ['caredescription', 'careplan', 'wednesday'])}
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Donderdag" 
                            rows={3}  
                            value={client.getIn(['caredescription', 'careplan', 'thursday'])} 
                            onChange={this.changeValue.bind(this, ['caredescription', 'careplan', 'thursday'])}
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Vrijdag" 
                            rows={3}  
                            value={client.getIn(['caredescription', 'careplan', 'friday'])} 
                            onChange={this.changeValue.bind(this, ['caredescription', 'careplan', 'friday'])}
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Zaterdag" 
                            rows={3}  
                            value={client.getIn(['caredescription', 'careplan', 'saturday'])} 
                            onChange={this.changeValue.bind(this, ['caredescription', 'careplan', 'saturday'])}
                            />
                    </div>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Zondag" 
                            rows={3}  
                            value={client.getIn(['caredescription', 'careplan', 'sunday'])} 
                            onChange={this.changeValue.bind(this, ['caredescription', 'careplan', 'sunday'])}
                            />
                    </div>

                </div>

                <div className="care-description">
                    <h3>Bijzonderheden</h3>
                    <div>
                        <Textarea 
                            alwaysShowLabel={true} 
                            label="Bijzonderheden" 
                            rows={3}  
                            value={client.getIn(['caredescription', 'careplan', 'notes'])} 
                            onChange={this.changeValue.bind(this, ['caredescription', 'careplan', 'notes'])}
                            />
                    </div>
                </div>



            </div>

        </div>
    }


}

export default connect(mapStateToProps, mapDispatchToProps)(SingleClientCarePlan);



class CareDescriptionGroup extends Component{

    getGroupFromClient(){
        let {client, group} = this.props;
        let part = group;
        if (!client.getIn(['caredescription', 'careplan', 'parts'])) return undefined;
        return client.getIn(['caredescription', 'careplan', 'parts']).filter((g) => { return g.get('id') == part.get('id')}).first();
    }

    getIndexOfGroup(){
        let {client, group} = this.props;
        let part = group;
        // console.log("getIndexOfGroup", group);
        if (!client.getIn(['caredescription', 'careplan', 'parts'])) return -1;
        return client.getIn(['caredescription', 'careplan', 'parts']).findIndex((g) =>{ return g.get('id') == part.get('id')});
    }

    getIndexOfItem(checkValue){
        let {client, group} = this.props;

        let clientGroup = this.getGroupFromClient();

        if (!clientGroup) return -1;
        return clientGroup.get('items').findIndex(item => item.get('id') == checkValue.get('id'));
    }
    
    toggleItem(item){
        let {updateClient, client, group} = this.props;
        let part = group;
        let clientGender = client.get('gender') == 'V' ? 'Mw' : 'Dhr';
        let indexOfItem = this.getIndexOfItem(item);
        let updatedClient = client;
        if (indexOfItem < 0){
            let clientGroup = this.getGroupFromClient();
            if (!clientGroup){
                if (!updatedClient.getIn(['caredescription', 'careplan', 'parts'])){
                    updatedClient = updatedClient.setIn(['caredescription', 'careplan', 'parts'], Immutable.List([]));
                }
                updatedClient = updatedClient.updateIn(
                    ['caredescription', 'careplan', 'parts'], 
                    arr => arr.push(
                        part.set('items', Immutable.List([]).push(item))
                    )
                );
            }
            else{
                let indexOfGroup = this.getIndexOfGroup();
                updatedClient = updatedClient.updateIn(
                    ['caredescription', 'careplan', 'parts', indexOfGroup, 'items'], 
                    arr => arr.push(
                        item.set('name', 
                            item.get('name')
                            .replace("C ", `${clientGender} `)
                            .replace(" C ", ` ${clientGender} `)
                            .replace(" C", ` ${clientGender}`)
                        )
                    )
                );
            }
        }
        else{
            let index = this.getIndexOfGroup();
            updatedClient = updatedClient.deleteIn(['caredescription', 'careplan', 'parts', index, 'items', indexOfItem]);
        }

        if (updateClient){
            console.log("updated value");
            updateClient(updatedClient);
        }

    }

    updateDescription(itemIndex, description){
        let {client, updateValue} = this.props;
        // console.log("updateDescription client", client);
        let indexOfGroup = this.getIndexOfGroup();
        // console.log("indexOfGroup", indexOfGroup);

        updateValue(['caredescription', 'careplan', 'parts', indexOfGroup, 'items', itemIndex, 'name'], description)
    }

    render(){
        let {group, client} = this.props;
        let part = group;
        let otherKey = 0;
        // console.log("client in render", client);
        // console.log("part", part);
        let rows = (part.get('items') || List()).sort((a, b) => {
            return (Number(a.get('order')) || 1000) - (Number(b.get('order')) || 1000);
        });

        // console.log("rows", rows);

        let grouped = rows.filter(x => x.get("header")).groupBy(x => x.get("header") || "remaining");

        // console.log("grouped", grouped);

        let mappedGroups = [];

        let renderRows = [];
        
        rows.forEach((item, index) => {

            if(item.get("header")) {
                if(mappedGroups.indexOf(item.get("header")) < 0) {
                    
                    let subRows = grouped.get(item.get("header")).map((item, index) => {
                        let indexOfItem = this.getIndexOfItem(item);
                        let indexOfGroup = this.getIndexOfGroup();
                        let value = item.get('name');
                        if (indexOfItem >= 0){
                            value = client.getIn(['caredescription', 'careplan', 'parts', indexOfGroup, 'items', indexOfItem, 'name']) || item.get('name');
                        }
                        return (
                            <div className="care-description-item" key={(otherKey++).toString()}>
                                <div className="col-md-1">
                                   <CheckBox value={indexOfItem >= 0} onChange={this.toggleItem.bind(this, item)} />
                                </div>
                                <div className="col-md-11">
                                    <Textarea className="even-padding" value={value} disabled={indexOfItem < 0} onChange={this.updateDescription.bind(this, indexOfItem)}/>
                                </div>
                            </div>
                        )
                    })

                    renderRows.push(
                        <div className="row m-b-20" key={item.get("header")}>
                            <div className="col-md-11 col-md-offset-1"><h5>{part.get('name')} / {item.get("header")}</h5></div>
                            <div className="col-md-11 col-md-offset-1">
                                {subRows}
                            </div>
                        </div>
                    )

                    // render part with header;
                    mappedGroups.push(item.get("header"));
                }
            } else {
                let indexOfItem = this.getIndexOfItem(item);
                let indexOfGroup = this.getIndexOfGroup();
                let value = item.get('name');
                if (indexOfItem >= 0){
                    value = client.getIn(['caredescription', 'careplan', 'parts', indexOfGroup, 'items', indexOfItem, 'name']) || item.get('name');
                }
                renderRows.push(
                    <div className="care-description-item" key={(otherKey++).toString()}>
                        <div className="col-md-1">
                           <CheckBox value={indexOfItem >= 0} onChange={this.toggleItem.bind(this, item)} />
                        </div>
                        <div className="col-md-11">
                            <Textarea className="even-padding" value={value} disabled={indexOfItem < 0} onChange={this.updateDescription.bind(this, indexOfItem)}/>
                        </div>
                    </div>
                )
            }

        })

        return (
            <div key={part.get('_id')} className="care-description-group">
                <h3>{part.get('name')}</h3>
                {renderRows}
            </div>
        )
    }
}


function CarePlanConceptButton({ caredescriptiongroups, caredescriptionparts, client, documenttypes }) {

    documenttypes = (documenttypes || List());
    documenttypes = documenttypes.filter(x => x.get("entity") == "clients");
    let carePlanDocumentType = documenttypes.filter(x => x.get("name").toLowerCase().match('zorgplan')).first();

    // let pdfObject = {};
    // pdfObject.html = PdfService.renderToStatic(<SingleClientCarePlanPrint caredescriptionparts={caredescriptionparts} client={client} />);
    // pdfObject.footer = PdfService.renderToStatic(<SingleClientCarePlanPrintFooter client={client} />);
    // pdfObject.filename = "clientinformatie-en-zorgplan-" + client.get('id') + "-" + moment(Number(client.getIn(['caredescription', 'careplan', 'date']))).format('DD-MM-YYYY')+"-" + moment().format('HH-mm') +".pdf";
    // pdfObject.name = `Zorgplan versie ${moment(Number(client.getIn(['caredescription', 'careplan', 'date']))).format('DD-MM-YYYY')}`;
    // pdfObject.entityid = client.get("id");
    // pdfObject.entity = "clients";
    // pdfObject.typeid = (carePlanDocumentType || Map()).get("id");

    let pageFooterStyle = "text-align: right; width:100%; padding-right: 20px; font-size:10px !important; color:#808080;";
    let pageFooter = `<div style="${pageFooterStyle}">Blad <span class="pageNumber"></span> van <span class="totalPages"></span>&nbsp;&nbsp;&nbsp;${moment().format('DD-MM-YYYY')} ${client.get('id')}</div>`;

    return <form action="/v5/services/generate-pdf" method="post" className="col-md-3">
        <input name="html" type="hidden" value={PdfService.renderToStatic(<CarePlanPrintV2 selectedclient={client} caredescriptionparts={caredescriptiongroups}  />)} />
        {/* <input type="hidden" name="settings[debugJavascript]" value={true} /> */}
        <input type="hidden" name="settings[printBackground]" value="true" />
        <input type="hidden" name="settings[displayHeaderFooter]" value="true" />
        <input type="hidden" name="settings[preferCSSPageSize]" value="true" />
        <input type="hidden" name="settings[continuePageCount]" value="true" />
        <input type="hidden" name="settings[skipPageCalculation]" value="true" />

        <input type="hidden" name="settings[headerTemplate]" value="<div></div>" />
        <input type="hidden" name="settings[footerTemplate]" value={pageFooter} />

        <input type="hidden" name="settings[margin][top]" value="0mm" />
        <input type="hidden" name="settings[margin][bottom]" value="0mm" />
        <input type="hidden" name="settings[margin][left]" value="0mm" />
        <input type="hidden" name="settings[margin][right]" value="0mm" />

        {/* <input name="footer" type="hidden" value={PdfService.renderToStatic(<CareplanFooter selectedclient={selectedclient} />)} /> */}
        <input name="filename" type="hidden" value={"clientinformatie-en-zorgplan-" + client.get('id') + "-" + moment(Number(client.getIn(['caredescription', 'careplan', 'date']))).format('DD-MM-YYYY-HH-mm') +".pdf"} />
        <input type="submit" data-html="true" data-tip="bekijk hoe het nieuwe zorgplan of deze versie van het zorgplan eruit ziet in een document" value="Concept bekijken" className="btn btn-block btn-primary pull-right" />
    </form>

    // return <form action={`${apiUrl}/services/generate-pdf`} method="post" className="col-md-3">
    //     {/* <ReactTooltip /> */}
    //     <input name="html" type="hidden" value={PdfService.renderToStatic(<SingleClientCarePlanPrint caredescriptionparts={caredescriptiongroups} client={client} />)} />
    //     <input name="footer" type="hidden" value={PdfService.renderToStatic(<SingleClientCarePlanPrintFooter client={client} />)} />
    //     <input name="filename" type="hidden" value={"clientinformatie-en-zorgplan-" + client.get('id') + "-" + moment(Number(client.getIn(['caredescription', 'careplan', 'date']))).format('DD-MM-YYYY')+"-" + moment().format('HH-mm') +".pdf"} />
    //     <input type="submit" data-html="true" data-tip="bekijk hoe het nieuwe zorgplan of deze versie van het zorgplan eruit ziet in een document" value="Concept bekijken" className="btn btn-block btn-primary pull-right" />
    
    // </form>

}

function getCarePlanNotificationMessage(createdByFreelancer, createdForFreelancer, client) {

    let aanhefObject = {'m': 'dhr.', 'v': 'mw.'};
    let aanhef = aanhefObject[(client.get('gender', '') || "").toLowerCase()] || 'dhr/mw';


    return `
        Beste ${createdForFreelancer.get('firstname')},<br /><br />
        
        ${generateDisplayName(createdByFreelancer)} heeft een ge-update zorgplan van ${aanhef} ${generateDisplayName(client)} op Zorgconnect geplaatst.<br /><br />
        
        Je kan het vinden via je eigen portaal in het clientdossier van ${aanhef} ${generateDisplayName(client)}.
        
    `;

}

/*
const mapStateToProps = (state) => ({
    selectedclient: state.get('selectedclient'),
    caredescriptionparts: state.get("caredescriptionparts"),
    documenttypes: state.get("documenttypes")
})

class CareplanActionButtons extends Component {

    generatePdf(pdfObject) {
        // console.log("pdfObject", pdfObject);
        let { delegatePdfGeneration, selectedclient } = this.props;
        saveClient(selectedclient).then(() => {
            return saveAndGenerate(pdfObject);
        })
        

    }

    

    render() {

        let { caredescriptionparts, selectedclient, documenttypes } = this.props;

        documenttypes = (documenttypes || List());
        documenttypes = documenttypes.filter(x => x.get("entity") == "clients");
        let carePlanDocumentType = documenttypes.filter(x => x.get("name").toLowerCase().match('zorgplan')).first();

        let pdfObject = {};
        pdfObject.html = PdfService.renderToStatic(<CareplanPrint caredescriptionparts={caredescriptionparts} selectedclient={selectedclient} />);
        pdfObject.footer = PdfService.renderToStatic(<CareplanFooter selectedclient={selectedclient} />);
        pdfObject.filename = "clientinformatie-en-zorgplan-" + selectedclient.get('id') + "-" + getNlDate(selectedclient.getIn(['caredescription', 'careplan', 'date']))+"-" + moment().format('HH-mm') +".pdf";
        pdfObject.name = `Zorgplan versie ${getNlDate(selectedclient.getIn(['caredescription', 'careplan', 'date']))}`;
        pdfObject.entityid = selectedclient.get("id");
        pdfObject.entity = "clients";
        pdfObject.typeid = (carePlanDocumentType || Map()).get("id");

        return <div className="row m-b-30">
            <div className="col-md-offset-6 col-md-3">

                <button  className="btn btn-primary btn-block pull-right" onClick={this.generatePdf.bind(this, pdfObject)}>Genereer</button> 
            
            </div>
            <form action="/services/generate-pdf" method="post" className="col-md-3">
            
                <input name="html" type="hidden" value={PdfService.renderToStatic(<CareplanPrint caredescriptionparts={caredescriptionparts} selectedclient={selectedclient} />)} />
                <input name="footer" type="hidden" value={PdfService.renderToStatic(<CareplanFooter selectedclient={selectedclient} />)} />
                <input name="filename" type="hidden" value={"clientinformatie-en-zorgplan-" + selectedclient.get('id') + "-" + getNlDate(selectedclient.getIn(['caredescription', 'careplan', 'date']))+"-" + moment().format('HH-mm') +".pdf"} />
                <input type="submit" value="Concept bekijken" className="btn btn-block btn-primary pull-right" />
            
            </form>
        </div>
    }

}

export default connect(mapStateToProps)(CareplanActionButtons);
*/