import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProfilePlanning } from '../../Actions/ProfilePlanning';
import { OrderedMap, Map, List } from 'immutable';
import Calendar from '../Calendar';
import CalendarNavigation from '../Calendar/CalendarNavigation';
import moment from 'moment';

function getDayFromTimestamp(timestamp) {
    return moment(timestamp).format('dd').toUpperCase();
}

function getDateTimeFromTimeStamp(timestamp) {
    return moment(timestamp).format('DD-MM-YYYY HH:mm');
}

function getTimeFromTimeStamp(timestamp) {
    return moment(timestamp).format('HH:mm');
}



const mapStateToProps = (state) => ({
    profileplanning: state.getIn(['profileplanning','collection'], OrderedMap()),
    freelancer: state.getIn(['freelancers','single'], Map())
})

const mapDispatchToProps = (dispatch) => ({
    getProfilePlanning: () => dispatch(getProfilePlanning())
})

class FreelancerPlanning extends Component {


    componentDidMount() {
        let { getProfilePlanning } = this.props;
        getProfilePlanning && getProfilePlanning();
    }

    render() {

        let { profileplanning, freelancer } = this.props;

        profileplanning = profileplanning.filter(x => !x.get('isDeleted'));
        profileplanning = profileplanning.filter(x => 
            x.get('freelancerid') == freelancer.get('id') 
        );

        profileplanning = profileplanning.sort((a,b) => b.get('type') - a.get('type'))
        
        // profileplanning = profileplanning.filter(x => x.get('freelancerid'))

        return <div className="card">

            <div className="card-header">
                <h1>Mijn planning &nbsp;&nbsp;<span className="print-visible-inline print-time">afgedrukt op {moment().format('DD-MM-YYYY HH:mm')}</span>
                    <button className="btn btn-primary print-hidden" onClick={() => window.print()}>Afdrukken</button>
                </h1>
            </div>
            <div className="card-body" style={{padding:"20px"}}>
                <CalendarNavigation />
                <Calendar events={convertFreelancerPlanningToEvents(profileplanning)}>

                    {(events) => <div className="md:hidden">
                        {events && events.size > 0 && <h3>Diensten</h3> || null }
                        <div className="flex flex-col gap-1">
                            {events && events.sort((a,b) =>  a.get('starttime') - b.get('starttime') ).map((pp) => {
                                return <div key={pp.get('id')} className="flex flex-row items-center justify-between">
                                    <div className="text-lg w-5 md:w-max">{getDayFromTimestamp(pp.get('starttime'))}</div>
                                    <div className="text-lg">{getDateTimeFromTimeStamp(pp.get('starttime'))} - {getTimeFromTimeStamp(pp.get('endtime'))}</div>
                                    <div className="">{pp.get('clientid') || 'Vrij'}</div>
                                </div>
                            }).toList() }
                        </div>
                    </div>}

                </Calendar>
                
            </div>
        
        </div>

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(FreelancerPlanning);

// const colors = {
//     "single-filled": "#00db04",
//     "single-not-filled": "#fdff00"
// }

const reasons = {
    "0": "...",
    "2": "Werk",
    "3": "Soc",
    "4": "Hobby",
    "5": "GeNa",
    "6": "Ziek",
    "8": "Vak.",
    "9": "Curs."
}

const colors = {
    "busy-day-1": (op) => `rgba(103,236,0, ${op})`,
    "busy-day-2": (op) => `rgba(161, 0, 0, ${op})`,
    "busy-day-3": (op) => `rgba(74, 169, 0, ${op})`,
    "busy-day-4": (op) => `rgba(240, 255, 0, ${op})`,
    "busy-day-5": (op) => `rgba(161, 0, 0, ${op})`,
    "busy-day-6": (op) => `rgba(161, 0, 0, ${op})`,
    "busy-day-8": (op) => `rgba(161, 0, 0, ${op})`,
    "busy-day-9": (op) => `rgba(161, 0, 0, ${op})`,
}

function convertFreelancerPlanningToEvents(profileplanning) {

    let events = List();

    const createEventFromRecord = (record, infoRecord, tag = "") => {
        let planningEvents = List();
        let event = Map({ 
            id: tag + infoRecord.get('id').toString(),
            event: infoRecord,
            starttime: record.get('starttime'),
            endtime: record.get('endtime'),
            startTimeIndex: record.get('startTimeIndex'),
            durationIndex: record.get('durationIndex'),
            year: record.get('year'),
            week: record.get('week'),
            dow: record.get('dow')
        })

        let op = infoRecord == record ? 1 : 0.5;

        let color = infoRecord.get("type") ? colors["busy-day-" + infoRecord.get("type").toString()](op) : colors["busy-day-1"](op);
        let text = infoRecord.get("clientid") ? infoRecord.get("clientid") : reasons[infoRecord.get("type", 1).toString()];

        event = event.set('background', color );
        event = event.set('label', text);

        let start = event.get('startTimeIndex');
        let duration = event.get('durationIndex');
        let end = start + duration;
        if(end > 96) {
            let first = event.set('durationIndex', 96 - start);
            let second = event.set('startTimeIndex', 0).set('durationIndex', event.get('durationIndex') - first.get('durationIndex'));
            
            let nextDateInfo = getNextYearWeekDow(event.get('year'), event.get('week'), event.get('dow'));
            second = second.set('year', nextDateInfo.year)
            .set('week', nextDateInfo.week)
            .set('dow', nextDateInfo.dow);
            
            planningEvents = planningEvents.push(first);
            planningEvents = planningEvents.push(second.update('id', x => x + "split" + tag));
        } else {
            planningEvents = planningEvents.push(event);
        }

        return planningEvents;
    }

    profileplanning.forEach((pp) => {

        let convertedEvents = createEventFromRecord(pp, pp);
        events = events.merge(convertedEvents);

        if(pp.getIn(['extra','before'])) {
            let beforeEvent = pp.getIn(['extra','before']);
            let extraStarttime = moment(Number(pp.get('starttime'))).subtract(Number(beforeEvent.get('durationIndex')) * 15, 'minutes').format('x');
            let extraEndtime = pp.get('starttime')
            beforeEvent = beforeEvent.set('starttime', extraStarttime);
            beforeEvent = beforeEvent.set('endtime', extraEndtime);
            convertedEvents = createEventFromRecord(beforeEvent, pp, 'before');
            events = events.merge(convertedEvents);
        }

        if(pp.getIn(['extra','after'])) {
            let afterEvent = pp.getIn(['extra','after']);
            let extraStarttime = pp.get('endtime');
            let extraEndtime = moment(Number(pp.get('endtime'))).add(Number(afterEvent.get('durationIndex')) * 15, 'minutes').format('x');
            afterEvent = afterEvent.set('starttime', extraStarttime);
            afterEvent = afterEvent.set('endtime', extraEndtime);
            convertedEvents = createEventFromRecord(afterEvent, pp, 'after');
            events = events.merge(convertedEvents);
        }


    })

    return events;

}

function getNextYearWeekDow(_year, _week, _dow) {
    let date = moment().endOf('w').year(_year).isoWeek(_week).isoWeekday(_dow);
    date.add(1,'d');
    let year = date.year();
    let week = date.isoWeek();
    let dow = date.isoWeekday();
    return {
        year: year,
        week: week,
        dow: dow
    }
}