import { LOAD_CAREDESCRIPTIONGROUPS, UNLOAD_CAREDESCRIPTIONGROUPS, UPDATE_CAREDESCRIPTIONGROUPS } from '../Reducers/CareDescriptionGroups';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';


export function loadCareDescriptionGroups(params) {
    return (dispatch) => {

        dispatch({
            type: LOAD_CAREDESCRIPTIONGROUPS,
            loading: true
        })
        EndpointService.get("/freelancerportal/api/caredescriptiongroups").then((response) => {
            
            dispatch({
                type: LOAD_CAREDESCRIPTIONGROUPS,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function getCareDescriptionGroups(params, reload) {
    return dispatch => {
        // syncing logic
        dispatch(loadCareDescriptionGroups(params));
    }
}

