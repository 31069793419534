import Immutable, { Map } from 'immutable';

export const LOAD_DOCUMENTTYPES = 'LOAD_DOCUMENTTYPES';
export const UNLOAD_DOCUMENTTYPES = 'UNLOAD_DOCUMENTTYPES';
export const UPDATE_DOCUMENTTYPES = 'UPDATE_DOCUMENTTYPES';


export default function DocumentTypesReducer(state, action) {

    switch(action.type) {

        case LOAD_DOCUMENTTYPES:

            if(action.loading) {
                state = state.setIn(['loading'], true);
            }

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            return state;
        case UNLOAD_DOCUMENTTYPES:

            return state;
        case UPDATE_DOCUMENTTYPES:

            return state;
            default:

    }

    return state || Map();

}