import { combineReducers } from 'redux-immutable';
import ClientsReducer from './Clients';
import ProfilePlanningReducer from './ProfilePlanning';
import GeneratedWeeksReducer from './GeneratedWeeks';
import CareDescriptionGroupsReducer from './CareDescriptionGroups';
import CareDescriptionPartsReducer from './CareDescriptionParts';
import DocumentTypesReducer from './DocumentTypes';
import UIReducer from '../UIState';
import FreelancersReducer from './Freelancers';
import PlanningRequestsReducer from './PlanningRequests';
import BookingsReducer from './Bookings';
import IrregularDaysReducer from './IrregularDays';
import ProductCategoriesReducer from './ProductCategories';
import UsersReducer from './Users';
import PortalAddressesReducer from './PortalAddresses';
import PortalUsersReducer from './PortalUsers';
import InfotheekReducer from './Infotheek';
import RedBookEntriesReducer from './RedBookEntries';

export default combineReducers({
    clients: ClientsReducer,
    profileplanning: ProfilePlanningReducer,
    generatedweeks: GeneratedWeeksReducer,
    caredescriptionparts: CareDescriptionPartsReducer,
    caredescriptiongroups: CareDescriptionGroupsReducer,
    documenttypes: DocumentTypesReducer,
    freelancers: FreelancersReducer,
    ui: UIReducer,
    planningrequests: PlanningRequestsReducer,
    bookings: BookingsReducer,
    irregulardays: IrregularDaysReducer,
    productcategories: ProductCategoriesReducer,
    users: UsersReducer,
    portaladdresses: PortalAddressesReducer,
    portalusers: PortalUsersReducer,
    infotheek: InfotheekReducer,
    redbook: RedBookEntriesReducer
})