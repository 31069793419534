
import { Map } from 'immutable';
import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl', { dow: 1 });

export const UI_TOGGLE_MAINMENU = 'UI_TOGGLE_MAINMENU';
export const UI_SET_WEEK_RANGE = 'UI_SET_WEEK_RANGE';

const date = moment();
date.startOf('w');
const startOfWeek = Number(date.format('x'));
date.endOf('w');
const endOfWeek = Number(date.format('x'));

export function toggleMainMenu() {
    return {
        type: UI_TOGGLE_MAINMENU
    }
}

export function setWeekRange(start, end) {
    return {
        type: UI_SET_WEEK_RANGE,
        start: start,
        end: end
    }
}

export default function UIReducer(state, action) {
    switch(action.type) {
        case UI_TOGGLE_MAINMENU:
            state = state.update('mainMenuOpened', x => !x);
        break;
        case UI_SET_WEEK_RANGE:
            state = state.set('weekRangeStart', action.start);
            state = state.set('weekRangeEnd', action.end);
        break;
    }
    return state || Map({
        mainMenuOpened: false,
        weekRangeStart: startOfWeek,
        weekRangeEnd: endOfWeek
    })
}