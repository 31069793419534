import React, { Component } from 'react';
import { getFreelancersInClientTeam } from '../../Actions/Freelancers';
import { generateDisplayName, generateFormalDisplayName, generateSemiFormalDisplayName } from '../../Utils/Person';
import { List, Map, OrderedMap } from 'immutable';
import CheckBox from '../Controls/CheckBox';
import Input from '../Controls/Input';
import Textarea from '../Controls/Textarea';
import { sendCareMessage } from '../../Actions/Mail';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

const mapStateToProps = (state) => ({
    freelancer: state.getIn(['freelancers','single'], Map()),
    portalAddresses: state.getIn(['portaladdresses','collection'], List())
});

const mapDispatchToProps = (dispatch) => ({
    sendCareMessage: (mail) => dispatch(sendCareMessage(mail))
})

function getEmailContacts(client) {

    let contacts = OrderedMap();
    
    if(client.get('email')) {
        contacts = contacts.set(client.get('email'), Map({
            type: 'client',
            gender: client.get('gender'),
            
        }))
    }
    
}

class SingleClientTeamContact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingFreelancers: true,
            selectedOffice: true,
            selectedFreelancers: OrderedMap(),
            subject: "",
             message: "",
            sending: false,
            contacts: OrderedMap(),
            loadingContacts: true
        }
        this.toggleOffice = this.toggleOffice.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.changeSubject = this.changeSubject.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    changeSubject(value) {
        this.setState({
            subject: value
        })
    }

    changeMessage(value) {
        this.setState({
            message: value
        })
    }

    toggleOffice() {
        this.setState({
            selectedOffice: !this.state.selectedOffice
        })
    }

    toggleFreelancer(freelancer) {
        console.log("toggleFreelancer", arguments);
        this.setState(({ selectedFreelancers }) => ({
            selectedFreelancers: selectedFreelancers.update(freelancer.get('id'), x => !x)
        }))
    }

    componentDidMount() {
        let { portalAddresses } = this.props;
        this.loadPortalAddresses(portalAddresses);

    }

    toggleContact(email) {
        console.log("email in toggleContact", email);
        this.setState(({ contacts }) => ({
            contacts: contacts.updateIn([email, 'selected'], false, x => !x)
        }))
    }


    loadPortalAddresses(portalAddresses) {
        let contacts = portalAddresses.reduce((col, x) => col.set(x.get('email'), x), OrderedMap());

        contacts = contacts.map(x => {
            if(x.get('type') == 'freelancer') {
                x = x.set('selected', true);
            }
            return x;
        })

        this.setState({
            contacts: contacts,
            loadingContacts: false
        })
    }

    componentDidUpdate(prevProps) {

        let { portalAddresses } = this.props;
        if(prevProps.portalAddresses != this.props.portalAddresses) {
            this.loadPortalAddresses(portalAddresses);
        }


    }

    sendMessage() {

        let { selectedFreelancers, selectedOffice, freelancers, subject, message, contacts } = this.state;
        let { sendCareMessage, client, freelancer } = this.props;

        // console.log("sending the message");
        // console.log(freelancers);
        // console.log(selectedFreelancers);

        contacts = contacts.filter(x => x.get('selected'));
        // if(selectedOffice) {
        //     contacts = contacts.set("info@u-staat-centraal.nl", Map({ email: "info@u-staat-centraal.nl" }));
        // }

        // selectedFreelancers = selectedFreelancers.map((x,i) => freelancers.get(i));
        // console.log("selectedfreelancers", selectedFreelancers);
        // if(selectedOffice) {

        //     selectedFreelancers = selectedFreelancers.set("USC", Map({ email: "info@u-staat-centraal.nl" }));

        // }

        // console.log("sendMessage()");
        // console.log("state", this.state);
        // console.log("props", this.props);

        this.setState({ sending: true })

    


        let mail = Map({
            subject: subject,
            message: message
        })

        let m = (message || "").replace(/\n/g, '<br />');
        let fromPart = `Vanaf het portaal van`;
        let clientPart = generateFormalDisplayName(client);
        let sendPart = `stuurt ${generateSemiFormalDisplayName(freelancer)}`;
        let endPart = `u het volgende bericht`
        let _message = `<i>${fromPart} ${clientPart} ${sendPart} ${endPart}</i><hr />${m}`;

        mail = mail.setIn(['message'], _message);
        mail = mail.setIn(['subject'], mail.get('subject') + " - Bericht via USC");
        mail = mail.setIn(['to'], 'info@u-staat-centraal.nl');

        if(freelancer.get('email')) {
            mail = mail.setIn(['replyTo'], freelancer.get('email'))
        }


        sendCareMessage(mail.setIn(['cc'], contacts.map((x) => x.get('email')).toList().join(','))).then((response) => {
            // swal("Sucessvol", "Wij hebben uw bericht verzonden.", "success");
            this.setState({
                subject: "",
                message: "",
                selectedOffice: false,
                selectedFreelancers: OrderedMap(),
                sending: false
            });
            swal.fire({
                title: 'Het bericht is verstuurd',
                text: 'Het bericht is verstuurd naar de door jou aangegeven personen',
                type: 'success'
            })
        }).catch((err) => {
            swal.fire({
                title: 'Het bericht is niet verstuurd',
                text: 'Er trad een fout op bij het versturen van het bericht, probeer het later nogmaals',
                type: 'error'
            })
            this.setState({
                sending: false
            })

            //debugger;
        });

    }

    render() {
        
        let { loadingFreelancers, freelancers, contacts, loadingContacts } = this.state;
        let { subject, message } = this.state;
        let { selectedFreelancers, selectedOffice, sending } = this.state;

         if(loadingContacts) {
            return <div className="card"><div className="card-body">Bezig met het ophalen van de contacten en de freelancers uit het team van de client</div></div>
        }

        let canSendMessage = contacts.some(x => x.get('selected')) || selectedOffice;
        canSendMessage = canSendMessage && subject.length > 0;
        canSendMessage = canSendMessage && message.length > 0;

        let clientContacts = contacts.filter(x => x.get('type') == 'client');
        let contactContacts = contacts.filter(x => x.get('type') == 'contact');
        let freelancerContacts = contacts.filter(x => x.get('type') == 'freelancer');

        return <div className="card single-client-team-contact">

            <div className="card-body">
                <h2>Selecteer de persoon of personen aan wie u een bericht wilt sturen</h2>
                
                { freelancerContacts.size > 0 && <React.Fragment>
                    <h3>Teamleden</h3>
                    <div className="team-members">

                        { (freelancerContacts || List()).toList().map((freelancer, index) => {

                            return <CheckBox key={index.toString()} 
                                value={freelancer.get('selected')}
                                onChange={this.toggleContact.bind(this, freelancer.get('email'))}
                                className="email-checkbox">
                                {freelancer.get('freelancerid')} - {generateSemiFormalDisplayName(freelancer)}<br />
                                {freelancer.get('email')}
                            </CheckBox>

                        })
                        }

                    </div>
                </React.Fragment> }

                { contactContacts.size > 0 && <React.Fragment>
                    <h3>Contactpersonen</h3>
                    <div className="team-members">
                        { (contactContacts || List()).toList().map((person, index) => {
                            return <CheckBox key={index.toString()} 
                                value={person.get('selected')}
                                onChange={this.toggleContact.bind(this, person.get('email'))}
                                className="email-checkbox">
                                {generateFormalDisplayName(person)}<br />
                                {person.get('email')}
                            </CheckBox>
                        })
                        }
                    </div>
                </React.Fragment> }

                { clientContacts.size > 0 && <React.Fragment>
                    <h3>Client</h3>
                    <div className="team-members">
                        { (clientContacts || List()).toList().map((person, index) => {
                            return <CheckBox key={index.toString()} 
                                value={person.get('selected')}
                                onChange={this.toggleContact.bind(this, person.get('email'))}
                                className="email-checkbox">
                                {generateFormalDisplayName(person)}<br />
                                {person.get('email')}
                            </CheckBox>
                        })
                        }
                    </div>
                </React.Fragment> }


                <h3>Kantoor</h3>
                <div className="other-receivers">
                    <CheckBox 
                        value={!!selectedOffice}
                        onChange={this.toggleOffice}
                        className="email-checkbox">
                        U Staat Centraal<br />
                        (info@u-staat-centraal)
                    </CheckBox>
                </div>

                <h3>Bericht</h3>
                <div className="message">
                    {/* <input type="text" /> */}
                    <Input 
                        value={subject}
                        onChange={this.changeSubject}
                        type="text" 
                        label="Onderwerp" 
                        className="message-subject"/>
                    <Textarea 
                        value={message}
                        onChange={this.changeMessage}
                        label="Inhoud" 
                        rows="7" 
                        className="message-text" />
                </div>

                <div className="message-buttons">
                    <button onClick={this.sendMessage} disabled={!canSendMessage || sending} className="btn btn-success pull-right">
                        { sending ? <span>Bezig met versturen...</span> : <span>Bericht versturen</span> }

                    </button>
                </div>

            </div>
            
        </div>

    }


}

/*
    button colors:
    blue: #2196F3
    groen: #4CAF50
    rood: #F44336
    geel: #FF9800
*/

export default connect(mapStateToProps, mapDispatchToProps)(SingleClientTeamContact);

// import React, { Component } from 'react';
// import { getFreelancersInClientTeam } from '../../Actions/Freelancers';
// import { generateDisplayName } from '../../Utils/Person';
// import { List, OrderedMap, Map } from 'immutable';
// import CheckBox from '../Controls/CheckBox';
// import Input from '../Controls/Input';
// import Textarea from '../Controls/Textarea';
// import { sendCareMessage } from '../../Actions/Mail';
// import { connect } from 'react-redux';

// const mapStateToProps = (state) => ({
//     client: state.getIn(['clients','single'], Map())
// });

// const mapDispatchToProps = (dispatch) => ({
//     sendCareMessage: (mail) => dispatch(sendCareMessage(mail))
// })

// class SingleClientTeamContact extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             loadingFreelancers: true,
//             selectedOffice: false,
//             selectedFreelancers: OrderedMap(),
//             subject: "",
//             message: ""
//         }
//         this.toggleOffice = this.toggleOffice.bind(this);
//         this.changeMessage = this.changeMessage.bind(this);
//         this.changeSubject = this.changeSubject.bind(this);
//         this.sendMessage = this.sendMessage.bind(this);
//     }

//     changeSubject(value) {
//         this.setState({
//             subject: value
//         })
//     }

//     changeMessage(value) {
//         this.setState({
//             message: value
//         })
//     }

//     toggleOffice() {
//         this.setState({
//             selectedOffice: !this.state.selectedOffice
//         })
//     }

//     toggleFreelancer(freelancer) {
//         console.log("toggleFreelancer", arguments);
//         this.setState(({ selectedFreelancers }) => ({
//             selectedFreelancers: selectedFreelancers.update(freelancer.get('id'), x => !x)
//         }))
//     }



//     componentDidMount() {
//         if(this.props.client && this.props.client.get('id')) {
//             getFreelancersInClientTeam(this.props.client.get('id')).then((freelancers) => {
//                 this.setState({
//                     freelancers: freelancers.groupBy(x => x.get('id')).map(x => x.first()),
//                     loadingFreelancers: false
//                 })
//             })
//         }
//     }

//     componentDidUpdate(prevProps) {

//         if(
//             (!prevProps.client || !prevProps.client.get('id')) &&
//             (this.props.client && this.props.client.get('id'))
//         ) {

//             getFreelancersInClientTeam(this.props.client.get('id')).then((freelancers) => {
//                 this.setState({
//                     freelancers: freelancers.groupBy(x => x.get('id')).map(x => x.first()),
//                     loadingFreelancers: false
//                 })
//             })

//         }


//     }

//     sendMessage() {

//         let { selectedFreelancers, selectedOffice, freelancers, subject, message } = this.state;
//         let { sendCareMessage, client } = this.props;

//         console.log("sending the message");
//         console.log(freelancers);
//         console.log(selectedFreelancers);

//         selectedFreelancers = selectedFreelancers.map((x,i) => freelancers.get(i));
//         console.log("selectedfreelancers", selectedFreelancers);
//         if(selectedOffice) {

//             selectedFreelancers = selectedFreelancers.set("USC", Map({ email: "info@u-staat-centraal.nl" }));

//         }

//         let mail = Map({
//             subject: subject,
//             message: message
//         })
//         mail = mail.setIn(['message'], `<i>Vanaf het portaal van U Staat Centraal stuurde ${generateDisplayName(client)} u het volgende bericht bij ${client.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '}${generateDisplayName(client)}</i><hr />${mail.getIn(['message'])}`);
//         mail = mail.setIn(['subject'], mail.get('subject') + " - Bericht via USC")
//         sendCareMessage(mail.setIn(['to'], selectedFreelancers.map((x) => x.get('email')))).then((response) => {
//             // swal("Sucessvol", "Wij hebben uw bericht verzonden.", "success");
//             this.setState({
//                 subject: "",
//                 message: "",
//                 selectedOffice: false,
//                 selectedFreelancers: OrderedMap()
//             });
//         }).catch((err) => {
//             debugger;
//         });

//         // let {currentUser, selectedclient} = this.props;
//         // let {mail} = this.state;
//         // if (!mail.get('recipients') || mail.get('recipients').size < 1){
//         //     swal('Fout', "U moet de mail minimaal naar een persoon sturen.", "error");
//         // }
//         // else{
//         //     mail = mail.setIn(['message'], `<i>Vanaf het portaal van U Staat Centraal stuurde ${generateDisplayName(currentUser)} u het volgende bericht bij ${selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '}${generateDisplayName(selectedclient)}</i><hr />${mail.getIn(['message'])}`);
//         //     mail = mail.setIn(['subject'], mail.get('subject') + " - Bericht via USC")
//         //     sendCareMessage(mail.setIn(['to'], mail.get('recipients').map((x) => x.get('email')))).then((response) => {
//         //         swal("Sucessvol", "Wij hebben uw bericht verzonden.", "success");
//         //         this.setState({
//         //             mail: Immutable.Map({})
//         //         });
//         //     }).catch((err) => {
//         //         debugger;
//         //     });
//         // }

//     }

//     render() {
        
//         let { loadingFreelancers, freelancers, selectedOffice, selectedFreelancers, subject, message } = this.state;

//         if(loadingFreelancers) {
//             return <div className="card"><div className="card-body">Bezig met het ophalen van de freelancers uit het team van de client</div></div>
//         }

//         let canSendMessage = selectedFreelancers.some(x => x) || selectedOffice;
//         canSendMessage = canSendMessage && subject.length > 0;
//         canSendMessage = canSendMessage && message.length > 0;


//         return <div className="card single-client-team-contact">

//             <div className="card-body">
//                 <h2>Selecteer de persoon of personen aan wie u een bericht wilt sturen</h2>
//                 <h3>Teamleden</h3>
//                 <div className="team-members">

//                     { (freelancers || List()).toList().map((freelancer, index) => {

//                         return <CheckBox key={index.toString()} 
//                             value={!!selectedFreelancers.get(freelancer.get('id'))}
//                             onChange={this.toggleFreelancer.bind(this, freelancer)}
//                             className="email-checkbox">
//                             {generateDisplayName(freelancer)}<br />
//                             {freelancer.get('email')}
//                         </CheckBox>

//                         // return <label key={index.toString()}>
//                         //     <input type="checkbox" />
//                         //     <div>{generateDisplayName(freelancer)}<br />
//                         //         {freelancer.get('email')}
//                         //     </div>
//                         // </label>
//                     })
//                     }

//                 </div>
//                 <h3>Kantoor</h3>
//                 <div className="other-receivers">
//                     <CheckBox 
//                         value={!!selectedOffice}
//                         onChange={this.toggleOffice}
//                         className="email-checkbox">
//                         U Staat centraal<br />
//                         (info@u-staat-centraal)
//                     </CheckBox>
//                 </div>

//                 <h3>Bericht</h3>
//                 <div className="message">
//                     {/* <input type="text" /> */}
//                     <Input 
//                         value={subject}
//                         onChange={this.changeSubject}
//                         type="text" 
//                         label="Onderwerp" 
//                         className="message-subject"/>
//                     <Textarea 
//                         value={message}
//                         onChange={this.changeMessage}
//                         label="Inhoud" 
//                         rows="7" 
//                         className="message-text" />
//                 </div>

//                 <div className="message-buttons">
//                     <button onClick={this.sendMessage} disabled={!canSendMessage} className="btn btn-success pull-right">Bericht versturen</button>
//                 </div>

//             </div>
            
//         </div>

//     }


// }

// /*
//     button colors:
//     blue: #2196F3
//     groen: #4CAF50
//     rood: #F44336
//     geel: #FF9800
// */

// export default connect(mapStateToProps, mapDispatchToProps)(SingleClientTeamContact);