import { LOAD_DOCUMENTTYPES, UNLOAD_DOCUMENTTYPES, UPDATE_DOCUMENTTYPES } from '../Reducers/DocumentTypes';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';


export function loadDocumentTypes(params) {
    return (dispatch) => {

        dispatch({
            type: LOAD_DOCUMENTTYPES,
            loading: true
        })
        EndpointService.get("/freelancerportal/api/documenttypes").then((response) => {
            
            dispatch({
                type: LOAD_DOCUMENTTYPES,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function getDocumentTypes(params, reload) {
    return dispatch => {
        // syncing logic
        dispatch(loadDocumentTypes(params));
    }
}

