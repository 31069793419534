import React, { Component } from 'react';
import Calendar from '../Calendar';
import CalendarNavigation from '../Calendar/CalendarNavigation';
import ProfilePlanning from '../../Reducers/ProfilePlanning';
import { List, Map, OrderedMap } from 'immutable';
import moment from 'moment';
import Modal from '../Modal';
import { generateDisplayName } from '../../Utils/Person';
import Textarea from '../Controls/Textarea';
import { connect } from 'react-redux';
import { savePlanningRequest, getPlanningRequests } from '../../Actions/PlanningRequests';
import swal from 'sweetalert2';
import { sendCareMessage } from '../../Actions/Mail';
import { seWeekRange } from '../../UIState';

const mapStateToProps = (state) => ({
    freelancer: state.getIn(['freelancers','single'], Map()),
    planningrequests: state.getIn(['planningrequests', 'collection'], OrderedMap())
});

const mapDispatchToProps = (dispatch) => ({
    savePlanningRequest: (planningRequest) => dispatch(savePlanningRequest(planningRequest)),
    getPlanningRequests: () => dispatch(getPlanningRequests()),
    sendCareMessage: (mail) => dispatch(sendCareMessage(mail))
});

class SingleClientPlanning extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentEvent: undefined,
            planningRequest: Map()
        }
        this.onEventSelect = this.onEventSelect.bind(this);
        this.closeServiceRequestModal = this.closeServiceRequestModal.bind(this);
        this.onRequestChange = this.onRequestChange.bind(this);
        this.onRequestSave = this.onRequestSave.bind(this);
    }

    componentDidMount() {
        let { getPlanningRequests } = this.props;
        getPlanningRequests && getPlanningRequests();
        
    }

    onRequestSave() {
        let { savePlanningRequest, freelancer, client, sendCareMessage } = this.props;
        let { planningRequest, currentEvent } = this.state;
        planningRequest = planningRequest
            .set('starttime', currentEvent.get('starttime'))
            .set('endtime', currentEvent.get('endtime'))
            .set('freelancerid', currentEvent.get('freelancerid'))
            .set('profileplanningrecordid', currentEvent.get('id'))
            .set('details', Map({

                "service": Map({
                    starttime: currentEvent.get('starttime'),
                    endtime: currentEvent.get('endtime'),
                    clientid: client.get('id')
                })

            }));

        savePlanningRequest && savePlanningRequest(planningRequest).then((saved) => {

            let serviceStartDate = moment(currentEvent.get('starttime')).tz('europe/amsterdam').format('DD-MM-YYYY');
            let serviceEndDate = moment(currentEvent.get('endtime')).tz('europe/amsterdam').format('DD-MM-YYYY');
        
            let serviceStartTime = moment(currentEvent.get('starttime')).tz('europe/amsterdam').format('HH:mm');
            let serviceEndTime = moment(currentEvent.get('endtime')).tz('europe/amsterdam').format('HH:mm');

            let mail = Map({
                subject: "Planningsverzoek Zorgconnect",
                to: freelancer.get('email'),
                cc: "info@u-staat-centraal.nl",
                message: `
                    Beste ${freelancer.get('firstname')},<br /><br />

                    In Zorgconnect heb jij je ingeschreven voor de volgende dienst:<br />
                    <strong>${serviceStartDate} van ${serviceStartTime} tot ${serviceEndTime} 
                    bij ${client.get('gender') == 'M' ? 'dhr.' : 'mw.'} ${generateDisplayName(client)}</strong>
                    <br /><br />
                    Dit is een automatisch gegenereerd e-mailbericht.
                    Via e-mail hoor je z.s.m. van ons of je cliënt van deze aangeboden dienst gebruik zal maken.
                    <br /><br />
                    Met vriendelijke groet,<br /><br />
                    
                    
                    U Staat Centraal
                `
            })

            sendCareMessage(mail).then(() => {
                swal.fire({
                    title: 'Planningsverzoek',
                    text: 'Je planningsverzoek is goed ontvangen, je ontvangt ook een bevestigingsmail',
                    type: 'success'
                });
            }).catch(() => {
                swal.fire({
                    title: 'Planningsverzoek',
                    text: 'Je planningsverzoek is succesvol ontvangen, echter er trad een fout op bij het sturen van de bevestigingsmail',
                    type: 'error'
                });
            })


            

            this.setState({
                currentEvent: undefined,
                planningRequest: Map()
            });

        }).catch((err) => {
            swal.fire({
                title: 'Planningsverzoek',
                text: 'Er trad een fout op bij het indienen van je planningsverzoek, probeer het nogmaals. Herhaalt deze fout zich neem dan contact op met kantoor',
                type: 'error'
            })
        })
    }

    onRequestChange(request) {
        this.setState({
            planningRequest: request
        })
    }

    closeServiceRequestModal() {
        this.setState(state => {
            return {
                currentEvent: undefined,
                planningRequest: Map()
            }
        })
    }



    onEventSelect(event) {
        console.log("there was an event selected", event);
        let { planningrequests } = this.props;
        planningrequests = planningrequests.groupBy(x => x.get('profileplanningrecordid'));
        if(planningrequests.get(event.get('id'), List()).size < 1) {
            this.setState({
                currentEvent: event
            })
        } 
        
    }

    render() {

        let { profileplanning, client, planningrequests } = this.props;
        let { currentEvent, planningRequest } = this.state;
        
        console.log("planningrequests", planningrequests);
        // console.log("serviceRequestModal", serviceRequestModal);

        return <div className="card">

            <div className="card-body">

                <PlanningRequesModal 
                    request={planningRequest} 
                    event={currentEvent} 
                    client={client} 
                    onClose={this.closeServiceRequestModal} 
                    onRequestChange={this.onRequestChange}
                    onSave={this.onRequestSave}
                    />

               <CalendarNavigation />
               <Calendar 
                    // profileplanning={profileplanning} 
                    onEventSelect={this.onEventSelect}
                    events={convertClientPlanningToEvents(profileplanning, planningrequests)}

                >
                    {(events) => <div className='md:hidden'>
                        {events && events.size > 0 && <h3>Diensten</h3> || null }
                        <div className="flex flex-col gap-1">
                            {events && events.sort((a,b) =>  a.get('starttime') - b.get('starttime') ).map((pp) => {
                                return <div key={pp.get('id')} className="flex flex-row items-center justify-between">
                                    <div className="text-lg w-5">{getDayFromTimestamp(pp.get('starttime'))}</div>
                                    <div className="text-lg">{getDateTimeFromTimeStamp(pp.get('starttime'))} - {getTimeFromTimeStamp(pp.get('endtime'))}</div>
                                    <div className="">{pp.get('freelancerid') || 'Open'}</div>
                                </div>
                            }).toList() }
                        </div>
                    </div>}
                </Calendar>

           </div>

        </div>

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SingleClientPlanning);

function getDayFromTimestamp(timestamp) {
    return moment(timestamp).format('dd').toUpperCase();
}

function getDateTimeFromTimeStamp(timestamp) {
    return moment(timestamp).format('DD-MM-YYYY HH:mm');
}

function getTimeFromTimeStamp(timestamp) {
    return moment(timestamp).format('HH:mm');
}

class PlanningRequesModal extends Component {

    render() {

        let { event, onClose, client, request, onRequestChange, onSave } = this.props;

        let serviceDate = event && moment(event.get('starttime')).format('DD-MM-YYYY');
        let starttimeString = event && moment(event.get('starttime')).format('HH:mm');
        let endtimeString = event && moment(event.get('endtime')).format('HH:mm');


        return <Modal open={!!event} onClose={onClose}>
            <div className="modal-body">
                
                <h2>Ik wil mij inschrijven voor deze dienst.</h2>
                <h4>Dienst:</h4>
                <p>
                    <strong>Client:</strong> {generateDisplayName(client)}<br />
                    <strong>Datum:</strong> {serviceDate}<br />
                    <strong>Starttijd:</strong> {starttimeString}<br />
                    <strong>Eindtijd:</strong> {endtimeString}

                </p>
                <h4>Opmerkingen (optioneel)</h4>




                <Textarea 
                    label='Opmerkingen'
                    value={request.get('remarks')} 
                    onChange={(value) => { onRequestChange(request.set('remarks', value )) }} />
                
                

            </div>
            <div className="modal-footer">
                <button className="btn btn-primary" onClick={onClose}>Sluiten</button>&nbsp;&nbsp;
                <button className="btn btn-success" onClick={onSave}>Opslaan</button>
            </div>
        </Modal>

    }
}


const colors = {
    "single-filled": (op = 1) => `rgba(0, 219, 4,${op})`,
    "single-not-filled": (op = 1) => `rgba(253, 255, 0,${op})`
}

function convertClientPlanningToEvents(profileplanning, planningrequests) {

    let events = List();

    console.log("contents of planningrequests in convertClientPlanningToEvents", planningrequests);

    planningrequests = planningrequests.filter(x => !x.get('assigned'));
    planningrequests = planningrequests.groupBy(x => x.get('profileplanningrecordid'));

    const createEventFromRecord = (record, infoRecord, tag = "") => {
        let planningEvents = List();
        let event = Map({ 
            id: tag + infoRecord.get('id').toString(),
            event: infoRecord,
            starttime: record.get('starttime'),
            endtime: record.get('endtime'),
            startTimeIndex: record.get('startTimeIndex'),
            durationIndex: record.get('durationIndex'),
            year: record.get('year'),
            week: record.get('week'),
            dow: record.get('dow')
        })

        let op = infoRecord == record ? 1 : 0.5;
        event = event.set('background', infoRecord.get('freelancerid') ? colors['single-filled'](op) : colors['single-not-filled'](op) );
        event = event.set('label', infoRecord.get('freelancerid', "OPEN") || "OPEN");

        if(planningrequests.get(infoRecord.get('id'), List()).filter(x => !x.get('processed')).size > 0 && !infoRecord.get('freelancerid')) {
            event = event.set('label', 'in  behandeling')
        }

        let start = event.get('startTimeIndex');
        let duration = event.get('durationIndex');
        let end = start + duration;
        if(end > 96) {
            let first = event.set('durationIndex', 96 - start);
            let second = event.set('startTimeIndex', 0).set('durationIndex', event.get('durationIndex') - first.get('durationIndex'));
            
            let nextDateInfo = getNextYearWeekDow(event.get('year'), event.get('week'), event.get('dow'));
            second = second.set('year', nextDateInfo.year)
            .set('week', nextDateInfo.week)
            .set('dow', nextDateInfo.dow);
            
            planningEvents = planningEvents.push(first);
            planningEvents = planningEvents.push(second.update('id', x => x + "split" + tag));
        } else {
            planningEvents = planningEvents.push(event);
        }

        return planningEvents;
    }

    profileplanning.forEach((pp) => {

        let convertedEvents = createEventFromRecord(pp, pp);
        events = events.merge(convertedEvents);

        if(pp.getIn(['extra','before'])) {
            let beforeEvent = pp.getIn(['extra','before']);
            let extraStarttime = moment(Number(pp.get('starttime'))).subtract(Number(beforeEvent.get('durationIndex')) * 15, 'minutes').format('x');
            let extraEndtime = pp.get('starttime')
            beforeEvent = beforeEvent.set('starttime', extraStarttime);
            beforeEvent = beforeEvent.set('endtime', extraEndtime);
            convertedEvents = createEventFromRecord(beforeEvent, pp, 'before');
            events = events.merge(convertedEvents);
        }

        if(pp.getIn(['extra','after'])) {
            let afterEvent = pp.getIn(['extra','after']);
            let extraStarttime = pp.get('endtime');
            let extraEndtime = moment(Number(pp.get('endtime'))).add(Number(afterEvent.get('durationIndex')) * 15, 'minutes').format('x');
            afterEvent = afterEvent.set('starttime', extraStarttime);
            afterEvent = afterEvent.set('endtime', extraEndtime);
            convertedEvents = createEventFromRecord(afterEvent, pp, 'after');
            events = events.merge(convertedEvents);
        }
        // let event = Map({ 
        //     id: pp.get('id').toString(),
        //     event: pp,
        //     starttime: pp.get('starttime'),
        //     endtime: pp.get('endtime'),
        //     startTimeIndex: pp.get('startTimeIndex'),
        //     durationIndex: pp.get('durationIndex'),
        //     year: pp.get('year'),
        //     week: pp.get('week'),
        //     dow: pp.get('dow')
        // })

        // event = event.set('background', pp.get('freelancerid') ? colors['single-filled'] : colors['single-not-filled'] );
        // event = event.set('label', pp.get('freelancerid', "OPEN") || "OPEN");

        // if(planningrequests.get(pp.get('id'), List()).filter(x => !x.get('processed')).size > 0 && !pp.get('freelancerid')) {
        //     event = event.set('label', 'in  behandeling')
        // }

        // let start = event.get('startTimeIndex');
        // let duration = event.get('durationIndex');
        // let end = start + duration;
        // if(end > 96) {
        //     let first = event.set('durationIndex', 96 - start);
        //     let second = event.set('startTimeIndex', 0).set('durationIndex', event.get('durationIndex') - first.get('durationIndex'));
            
        //     let nextDateInfo = getNextYearWeekDow(event.get('year'), event.get('week'), event.get('dow'));
        //     second = second.set('year', nextDateInfo.year)
        //     .set('week', nextDateInfo.week)
        //     .set('dow', nextDateInfo.dow);
            
        //     events = events.push(first);
        //     events = events.push(second.update('id', x => x + "split"));
        // } else {
        //     events = events.push(event);
        // }

    })

    return events;

}

function getNextYearWeekDow(_year, _week, _dow) {
    let date = moment().year(_year).isoWeek(_week).isoWeekday(_dow);
    date.add(1,'d');
    let year = date.year();
    let week = date.isoWeek();
    let dow = date.isoWeekday();
    return {
        year: year,
        week: week,
        dow: dow
    }
}

/*

import React, { Component } from 'react';
import {connect} from 'react-redux';
import Immutable, { List, Map, OrderedMap } from 'immutable';
import FloatingSelect from 'Core/Controls/FloatingSelect';
import FloatingTextarea from 'Core/Controls/FloatingTextarea';
import { savePlanningRequest } from 'Usc/Modules/PlanningRequest/Actions';
import Dialog from 'Core/Controls/Dialog';
import swal from 'sweetalert2';
import moment from 'moment';
import ReactTooltip from 'react-tooltip'
import {generateDisplayName, getNlDate} from 'Core/Utils/Display';
import { sendCareMessage } from '../../actions';

import { days, convertTimeToIndex, convertIndexToTime } from 'Usc/Modules/ProfileRecords/Components/ProfileRecordDialog';

const mapStateToProps = (state) => ({
    selectedfreelancer: state.get("selectedfreelancer"),
    selectedclient: state.get('selectedclient')
})

Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}

class PlanningRequestDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            planningrequest: Map()
        };

        this.savePlanningRequest = this.savePlanningRequest.bind(this);
       
    }

    componentDidMount() {
    }

    savePlanningRequest() {
        let { onSave, selectedfreelancer, profilePlanningRecord, selectedclient } = this.props;
        let { planningrequest } = this.state;

        planningrequest = planningrequest
            .set('starttime', profilePlanningRecord.get('starttime'))
            .set('endtime', profilePlanningRecord.get('endtime'))
            .set('freelancerid', selectedfreelancer.get('id'))
            .set('profileplanningrecordid', profilePlanningRecord.get('id'));

        savePlanningRequest(planningrequest).then(() => {
            
            let serviceStartDate = moment(profilePlanningRecord.get('starttime')).format('DD-MM-YYYY');
            let serviceEndDate = moment(profilePlanningRecord.get('endtime')).format('DD-MM-YYYY');
        
            let serviceStartTime = moment(profilePlanningRecord.get('starttime')).format('HH:mm');
            let serviceEndTime = moment(profilePlanningRecord.get('endtime')).format('HH:mm');

            let mail = Map({
                subject: "Planningsverzoek Zorgconnect",
                to: selectedfreelancer.get('email'),
                cc: "info@u-staat-centraal.nl",
                message: `
                    Beste ${selectedfreelancer.get('firstname')},<br /><br />

                    In Zorgconnect heb jij je ingeschreven voor de volgende dienst:<br />
                    <strong>${serviceStartDate} van ${serviceStartTime} tot ${serviceEndTime} 
                    bij ${selectedclient.get('gender') == 'M' ? 'dhr.' : 'mw.'} ${generateDisplayName(selectedclient)}</strong>
                    <br /><br />
                    Dit is een automatisch gegenereerd e-mailbericht.
                    Via e-mail hoor je z.s.m. van ons of je cliënt van deze aangeboden dienst gebruik zal maken.
                    <br /><br />
                    Met vriendelijke groet,<br /><br />
                    
                    
                    U Staat Centraal
                `
            })

            sendCareMessage(mail)
            
            this.setState({
                planningrequest: Map()
            }, () => {
                if(onSave) {
                    onSave();
                }
            })
        })
        
    }

    changePlanningRequest(field, value) {

        this.setState(({ planningrequest }) => ({
            planningrequest: planningrequest.set(field, value)
        }))

    }


    render() {
        
        let { profilePlanningRecord, selectedfreelancer, selectedclient, onClose } = this.props;
        let  { planningrequest } = this.state;

        if(!profilePlanningRecord) {
            return <div />;
        }

        let serviceDate = moment(profilePlanningRecord.get('starttime')).format('DD-MM-YYYY');
        let starttimeString = moment(profilePlanningRecord.get('starttime')).format('HH:mm');
        let endtimeString = moment(profilePlanningRecord.get('endtime')).format('HH:mm');
        let clientid = profilePlanningRecord.get('clientid');
        // let dialogTitle = `Dienst (${clientid}) op ${serviceDate} van ${starttimeString} tot ${endtimeString}`;

        let dialogTitle = "Ik wil mij inschrijven voor deze dienst.";
        

        return <Dialog size='sm' isVisible={!!profilePlanningRecord} title={dialogTitle} buttons={[
            (
                <button key={1} className="btn btn-default" onClick={onClose}>Annuleren</button>
            ),(
                <button key={2} className="btn btn-success" onClick={this.savePlanningRequest}>Inschrijving indienen</button>
            )]}>

            <div>
                
               
                <h4>Dienst:</h4>
                <p>
                    Client: {generateDisplayName(selectedclient)}<br />
                    Datum: {serviceDate}<br />
                    Starttijd: {starttimeString}<br />
                    Eindtijd: {endtimeString}

                </p>
                <h4>Opmerkingen (optioneel)</h4>
                <FloatingTextarea 
                    label='Opmerkingen'
                    value={planningrequest.get('remarks')} 
                    onChange={this.changePlanningRequest.bind(this, 'remarks')} />
                

            </div>

        </Dialog>

    }


}

export function isFreelancerAvailable(freelancerId, currentProfilePlanningRecord, profilePlanningRecords) {

    // console.log("i isFreelancerAvailable was called with the following freelancer", freelancerId);

    if(!freelancerId) {
        return true;
    }

    let freelancerAvailable = true;

    profilePlanningRecords = profilePlanningRecords.filter(x => !x.get("deleted"));
    profilePlanningRecords = profilePlanningRecords.filter(x => x.get("freelancerid") == freelancerId);
    
    profilePlanningRecords.forEach((profilePlanningRecord) => {

        if(profilePlanningRecord == currentProfilePlanningRecord) {
            return;
        }

        if(profilePlanningRecord.get("id") == currentProfilePlanningRecord.get("id")) {
            return;
        }

        let mpr = currentProfilePlanningRecord;
        let pr = profilePlanningRecord;

        let cc1 = mpr.get("starttime") >= pr.get("starttime");
        let cc2 = mpr.get("endtime") <= pr.get("endtime");
        let cc3 = mpr.get("starttime") <= pr.get("starttime");
        let cc4 = mpr.get("endtime") > pr.get("starttime");
        let cc5 = mpr.get("starttime") < pr.get("endtime");

        let timeConflicting = (
            (cc1 && cc2) ||
            (cc3 && cc4) ||
            (cc1 && cc5)
        );

        if(timeConflicting) {
            freelancerAvailable = false;
        }


    })

    return freelancerAvailable;
    
}


export default connect(mapStateToProps, null)(PlanningRequestDialog);

*/