import React, { Component } from 'react';
import ReactSelect from 'react-select';


class Select extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        let { onChange } = this.props;
        if(onChange) {
            onChange(value.value);
        }
    }


    render() {

        let { value, placeholder, options } = this.props;

        // console.log("Select", this.props);

        if(options) {
            options.forEach(option => {
                if(option.value == value) {
                    value = option;
                }
            }) 
        }

        return <ReactSelect 
            value={value} 
            placeholder={placeholder} 
            options={options} 
            onChange={this.onChange} />

    }   
}

export default Select;