import EndpointService from '../Service/EndpointService';
import moment from 'moment';
import Immutable, { fromJS } from 'immutable';

export function saveAndGenerate(pdfObject) {
    
    return dispatch => {
        

        return EndpointService.post(`/freelancerportal/api/documentmanagement/V5/${pdfObject.entity}/pdf`, pdfObject) 

            // LoaderService.executeTask({
            //     execute: EndpointService.post.bind(EndpointService, `http://localhost:8080/freelancerportal/api/documentmanagement/${pdfObject.entity}/pdf`, pdfObject),
            //     message: "Bezig met het genereren van het document...",
            //     resolve: (dataPromise) => {
            //         dataPromise.then(() => {
            //             resolve();
            //             // swal('Het document is opgeslagen', 'Het document is successvol opgeslagen', 'success');
            //         }).catch((err) => {
            //             reject(err);
            //             // swal('Er heeft zich een fout voorgedaan ', 'Er heeft zich een fout voorgedaan bij het opslaan van het document', 'error');
            //         })
                    
            //     }
            // });
        
    }

    

}

export function getFreelancerDocuments() {

    return dispatch => {
        return new Promise((resolve, reject) => {
            return EndpointService.get(`/freelancerportal/api/documents`).then((response) => {
                resolve(fromJS(response.data))
            }).catch(reject);
        })
    }

}

export function getClientDocuments(clientid) {

    return dispatch => {

        return new Promise((resolve, reject) => {
            return EndpointService.get(`/freelancerportal/api/client/documents/${clientid}`).then((response) => {
                resolve(fromJS(response.data));
            }).catch(reject);
        })

        
    }

}