import React, { Component } from 'react';
import CareplanPage from './CareplanPage';
// import Document from 'Usc/Modules/FreelancerInvoicing/Components/Pages/Document';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class CarePlanPrintV2 extends Component {

    render() {

        let { selectedclient, caredescriptionparts } = this.props;

       
        let currentlyOnLocalhost = document.location.href.match('zorgconnect.dev');
        let host = "https://app2.u-staat-centraal.nl";

        

        return <html>
            <head>
                <link rel="stylesheet" media="all" type="text/css" href={`${host}/print/no-calculate-style.css`} />
            </head>
            <body>
                <CareplanPage 
                    selectedclient={selectedclient} caredescriptionparts={caredescriptionparts} />
            </body>
        </html>

    }

}

export default CarePlanPrintV2;

export function Document({ relativeUrl, children, orientation = 'portrait' }) {


    return <html>
	    <head>

            { orientation == 'portrait' && <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/style.css`} /> }
            { orientation == 'landscape' && <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/orientation-landscape.css`} /> }
            <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/invoice.css`} />
            <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/invoice-specification.css`} />
            <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/payment-overview.css`} />
            <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/service-invoice.css`} />
            <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/service-specification.css`} />
            <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/freelancer-year.css`} />
            <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/individual-client-report.css`} />
            <link rel="stylesheet" media="all" type="text/css" href={`${relativeUrl}/print/coordination-list.css`} />
        </head>
        <body>

            {children}


        </body>
    </html>

}