import { LOAD_CLIENTS, UNLOAD_CLIENTS, UPDATE_CLIENTS } from '../Reducers/Clients';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';

export function loadClients(params) {
    return (dispatch) => {

        dispatch({
            type: LOAD_CLIENTS,
            loading: true
        })
        return EndpointService.get("/freelancerportal/api/clients").then((response) => {
            
            dispatch({
                type: LOAD_CLIENTS,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function saveClient(client) {
    return (dispatch) => {

        return EndpointService.put('/freelancerportal/api/client', client.toJS()).then((response) => {

            return client;

        })

    }
}

export function getClients(params, reload) {
    return dispatch => {
        // syncing logic
        dispatch(loadClients(params));
    }
}

