import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OrderedMap, Map, List } from 'immutable';
import { generateDisplayName, generateFormalDisplayName, generateSemiFormalDisplayName } from '../../Utils/Person';
import { getClients } from '../../Actions/Clients';
import { getProfilePlanning } from '../../Actions/ProfilePlanning';
import Calendar from '../Calendar';
import CalendarNavigation from '../Calendar/CalendarNavigation';
import { NavLink, Switch, Route } from 'react-router-dom';
import SingeClientPlanning from './SingleClientPlanning';
import SingleClientTeamContact from './SingleClientTeamContact';
import SingleClientCarePlan from './SingleClientCarePlan';
import SingleClientDocuments from './SingleClientDocuments';
import './Styles/SingleClient.scss';
import { getPortalAddresses } from '../../Actions/PortalAddresses';
import { setWeekRange } from '../../UIState';
import { TheReadBookButton } from '../TheRedBook';
import moment from 'moment';
import { withMissedEntries } from '../../Context/MissedEntries';
import { useContext } from 'react';
import PWAContext from '../../PWA/Context/PWAContext';
import EndpointService from '../../Service/EndpointService';

const apiUrl = window.location.href.match('js.local') ? "https://zf.local.perfectstate.nl" : "";

const mapStateToProps = (state) => ({
    weekRangeStart: state.getIn(['ui','weekRangeStart']),
    weekRangeEnd: state.getIn(['ui','weekRangeEnd']),
    clients: state.getIn(['clients','collection'], OrderedMap()),
    profileplanning: state.getIn(['profileplanning','collection'], OrderedMap()),
    freelancer: state.getIn(['freelancers', 'single'], Map()),
    portalAddresses: state.getIn(['portaladdresses','collection'], List()),
})

const mapDispatchToProps = (dispatch) => ({
    getClients: () => dispatch(getClients()),
    getProfilePlanning: (params) => dispatch(getProfilePlanning(params)),
    setWeekRange: (start, end) => dispatch(setWeekRange(start, end)),
    getPortalAddresses: (clientid) => dispatch(getPortalAddresses(clientid))
})

class SingleClient extends Component {

    componentDidMount() {
        let { getClients, getProfilePlanning } = this.props;
        let { setWeekRange } = this.props;
        console.log("SingleClient componentDidMount");
        console.log("props", this.props);

        let { match: { params: { start, end, clientid }}, weekRangeStart, weekRangeEnd, getPortalAddresses } = this.props;
        if(
            (start || end) && 
            start != weekRangeStart || end != weekRangeEnd
        ) {
            console.log("setting week range start : start",  start);
            console.log("setting week range start : weekRangeSTart", weekRangeStart );
            console.log("setting week range end : end",  end);
            console.log("setting week range end : weekRangeEnd", weekRangeEnd );
            setWeekRange && setWeekRange(
                Number(start || weekRangeStart), 
                Number(end || weekRangeEnd)
            );
        }
        getClients && getClients();
        getProfilePlanning && getProfilePlanning();
        getPortalAddresses && getPortalAddresses(clientid)

        console.log("componentDidMount SingleClient", this.props);

    }

    componentDidUpdate(prevProps) {

        let { match: { params: { start, end, clientid }}, weekRangeStart, weekRangeEnd, getPortalAddresses } = this.props;
        let { setWeekRange } = this.props;
        let currentStart = start;
        let currentEnd = end;
        let currentWeekRangeStart = weekRangeStart;
        let currentWeekRangeEnd = weekRangeEnd;

        if(prevProps.match.params.clientid != clientid) {

             getPortalAddresses && getPortalAddresses(clientid);
        }

        {
            let { match: { params: { start, end }}, weekRangeStart, weekRangeEnd } = prevProps;
            if(
                (currentStart || currentEnd) &&
                (currentStart != start || currentEnd != end)
                && 
                (currentStart != currentWeekRangeStart || currentEnd != currentWeekRangeEnd)
            ) {
                console.log("setting week range start : currentStart",  currentStart);
            console.log("setting week range start : currentWeekRangeStart", currentWeekRangeStart );
            console.log("setting week range end : currentEnd",  currentEnd);
            console.log("setting week range end : currentWeekRangeEnd", currentWeekRangeEnd );
                setWeekRange && setWeekRange(
                    Number(currentStart || currentWeekRangeStart), 
                    Number(currentEnd || currentWeekRangeEnd)
                );
            }
        }
    }

    render() {

        let { portalAddresses, profileplanning, clients, freelancer,  match: { path, params: { clientid, start, end } } } = this.props;

        let client = clients.get(clientid);

        if(!client) {
            return <data />
        }

        console.log("client", client);

        let canSeeWeekPlanning = true;
        let canSeeTeamContact = true;
        let canSeeCareplan = false;
        let canSeeDocuments = false;
        let canSeeClientInfo = false;
        let canSeeTheRedBook = false;
        if(freelancer && client) {

            let agreements = client.get('agreements', List()).filter(x => x.get('isActive'));
            canSeeCareplan = agreements.some(
                x => x.get('freelancerid') == freelancer.get('id') &&
                x.getIn(['clientportal','cangeneratecareplan'])
            );
            canSeeDocuments = agreements.some(
                x => x.get('freelancerid') == freelancer.get('id') && 
                x.getIn(['clientportal','canseedocuments'])
            )
            canSeeClientInfo = agreements.some(
                x => x.get('freelancerid') == freelancer.get('id') &&
                    x.getIn(['clientportal','canseeclientinfo'])
            )
            canSeeTheRedBook = agreements.some(
                x => x.get('freelancerid') == freelancer.get('id') &&
                x.getIn(['clientportal','canaccesstheredbook'])
            )

        }


        profileplanning = profileplanning.filter(x => !x.get('isDeleted'));
        profileplanning = profileplanning.filter(x => x.get('clientid') == clientid);

        console.log("SingleClient props", this.props);

        let maxService = Number(moment().format('x'));
        let redbookplanning = profileplanning.filter(x => x.get('freelancerid') == freelancer.get('id')).toList();

        redbookplanning = redbookplanning.filter(x => x.get('starttime') < maxService);
        redbookplanning = redbookplanning.sort((a,b) => b.get('endtime') - a.get('endtime'));

        return <div className="card single-client">
        
            <div className="card-header">
                <h1>{generateFormalDisplayName(client)}</h1>

            </div>

            <div className="card-navigation">

                {canSeeWeekPlanning && <NavLink to={`/clients/${clientid}`} exact activeClassName="selected">Weekplanning</NavLink> }
                { canSeeTeamContact && <NavLink to={`/clients/${clientid}/teamcontact`} activeClassName="selected">Teamcontact</NavLink> }
                { canSeeCareplan && <NavLink to={`/clients/${clientid}/careplan`} activeClassName="selected">Anamnese & zorgplan</NavLink> }
                { canSeeDocuments && <NavLink to={`/clients/${clientid}/documents`} activeClassName="selected">Documenten</NavLink> }
            
            </div>

            <div className="card-body client-view">

                <div className="client-view-side">
                    <h2>Client gegevens:</h2>
                    <div>
                        <strong>Kenmerk:</strong> {client.get('id')}<br />
                        <strong>Voorletters:</strong> {client.get('initials')}<br />
                        <strong>Achternaam:</strong> {client.get('lastname')}<br />
                        <strong>Geboortedatum:</strong> {client.get('dateofbirthstring')}<br />
                        <strong>BSN:</strong> {client.get('bsn')}
                    </div>
                    <h2>Zorgadres:</h2>
                    <div>
                        {client.getIn(['address','street'])} {client.getIn(['address','number'])} {client.getIn(['address','addition'])}<br />
                        {client.getIn(['address','postalcode'])} {client.getIn(['address','location'])}
                    </div>
                    <h2>Contact</h2>
                    <div>
                        <ul>
                            {(() => {
                                let contactLists = [];

                                if (client.get('phonenumbers')){
                                    client.get('phonenumbers').forEach((phonenumber) => {
                                        let numberInfo = undefined;
                                        if (phonenumber.get('type') && phonenumber.get('type') != ''){
                                            numberInfo = ` (${phonenumber.get('type')})`;
                                        }
                                        contactLists.push((
                                            <li key={phonenumber.get('number')}>
                                                <i className="zmdi zmdi-phone"></i>
                                                {phonenumber.get('number')}{numberInfo}
                                            </li>
                                        ));
                                    });
                                }
                        
                                if (client.get('email')){
                                    contactLists.push((
                                        <li key={client.get('email')}>
                                            <i className="zmdi zmdi-email"></i>
                                            {client.get('email')}
                                        </li>
                                    ));
                                }

                                return contactLists;

                            })()}
                        </ul>
                    </div>
                    <h2>Teamleden</h2>
                    <div>
                        
                            {portalAddresses.filter(x => x.get('type') == 'freelancer').groupBy(x => x.get('email')).map((group) => {
                                let contactLists = [];

                                let freelancer = group.first();
                                if (freelancer.get('phonenumbers')){
                                    freelancer.get('phonenumbers').forEach((phonenumber) => {
                                        let numberInfo = undefined;
                                        if (phonenumber.get('type') && phonenumber.get('type') != ''){
                                            numberInfo = ` (${phonenumber.get('type')})`;
                                        }
                                        contactLists.push((
                                            <li key={phonenumber.get('number')}>
                                                <i className="zmdi zmdi-phone"></i>
                                                {phonenumber.get('number')}{numberInfo}
                                            </li>
                                        ));
                                    });
                                }
                

                                // return ;
                                
                                return <React.Fragment>
                                    <strong>{freelancer.get('freelancerid')} - {generateSemiFormalDisplayName(freelancer)}</strong>
                                    {contactLists.length > 0 && <ul>{contactLists}</ul>} <br />
                                </React.Fragment>

                                

                            }).toList()}
                    </div>
                    { canSeeTheRedBook && <TheReadBookButton profileplanning={redbookplanning} client={client} freelancer={freelancer} /> }
                    { canSeeClientInfo && <React.Fragment>
                        <h2>Zorgplan</h2>
                        <div>
                            <CareplanButton client={client}>Downloaden</CareplanButton>
                        </div>
                    </React.Fragment>}
                </div>
                <div className="client-view-content">
                    <Switch>
                        <Route path={`${path}/teamcontact`} render={(props) => <SingleClientTeamContact client={client} />} />
                        <Route path={`${path}/careplan`} render={() => <SingleClientCarePlan client={client} />} />
                        <Route path={`${path}/documents`} render={() => <SingleClientDocuments client={client} />} />
                        <Route path={`${path}`} render={props => <SingeClientPlanning client={client} profileplanning={profileplanning} />} />
                    </Switch>
                </div>
            </div>
        </div>
    }
}

async function fetchDocument(url) {

    let response = await EndpointService.get(url, { responseType: 'blob' });
    return response



}

function CareplanButton({ client, children }) {

    const { isPWA } = useContext(PWAContext);
    const baseUrl = `freelancerportal/files/careplan/${client.get('id')}`;
    const url = `${apiUrl}/${baseUrl}`;

    if(!isPWA) {
        return <a href={url} className="btn btn-primary" target="_blank">{children}</a>
    }

    const onClick = async () => {
        if(!url) {
            return;
        }
        const response = await fetchDocument('/' + baseUrl);
        const blobUrl = URL.createObjectURL(response.data);
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = baseUrl.split('/').pop();
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
    }

    return <a className="btn btn-primary" onClick={onClick}>{children}</a>
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleClient);

