import EndpointService from '../Service/EndpointService';


export function sendCareMessage(mail){

    return dispatch => {

         // if (Configuration.DEBUG){
            // mail = mail.setIn(['to'], 'omarzorgui@gmail.com').setIn(['subject'], "TEST: " + mail.get('subject'));
            // mail = mail.setIn(['cc'], 'omarzorgui@gmail.com').setIn(['subject'], "CCTEST: " + mail.get('subject'));
        // }
        return new Promise((resolve, reject) => {
    
            EndpointService.post('/freelancerportal/api/mail', mail.toJS()).then(() => {
                resolve();
            }).catch(reject);
    
            // LoaderService.executeTask({
            //     execute: EndpointService.post.bind(EndpointService, '/api/clients/sendCareMessage', mail.toJS()),
            //     message: "Bezig met het versturen van de email.",
            //     resolve: (promise) => {
            //         promise.then(resolve).catch(reject);
            //     }
            // });
        })

    }


   
}