import React, { Component } from 'react';

class Home extends Component {


    render() {

        return <div className="card">

            <div className="card-body">

            
            </div>
        
        </div>

    }

}

export default Home;