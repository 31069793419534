import React, { Component } from 'react';
import moment from 'moment';
import {generateDisplayName} from '../Utils/Person';


class ClientBookingConfirmedMail extends Component{
    render(){
        let {selectedfreelancer, selectedbooking, selectedclient} = this.props;

        let sb = selectedbooking;

        let startTimeString = !sb.getIn(['cancelled']) ? sb.getIn(['agreedtimes', 'start']) : sb.getIn(['agreedtimes', 'initialStart']);
        if(sb.getIn(['agreedtimes', 'start']) != sb.getIn(['agreedtimes', 'initialStart']) && !sb.get('cancelled')) {
            startTimeString += ` (${sb.getIn(['agreedtimes', 'reasonstartdeviation'])})`;
        }

        let endTimeString = !sb.getIn(['cancelled']) ? sb.getIn(['agreedtimes', 'end']) : sb.getIn(['agreedtimes', 'initialEnd']);
        if(sb.getIn(['agreedtimes', 'end']) != sb.getIn(['agreedtimes', 'initialEnd']) && !sb.get('cancelled')) {
            endTimeString += ` (${sb.getIn(['agreedtimes', 'reasonenddeviation'])})`;
        }

        let sst = Number(sb.getIn(['details','planningrecord','starttime'])); // service start time
        let set = Number(sb.getIn(['details','planningrecord','endtime']));  // service end time

        let ssm = moment(sst);
        let sem = moment(set);
        let slaapdienst = "";
        if(
            (ssm.hour() >= 23 || ssm.hour() < 7) ||
            (sem.hour() > 23 || sem.hour() < 7 )
        ) {
           slaapdienst = `<tr><td><b>Zorgmomenten in nachtdienst</b></td><td>${sb.getIn(['agreedtimes', 'caremoments'])} (tussen 23 en 7 uur)</td></tr>`;
        }


        /*
	if(!empty($dataMail->Ac_Acc_Voorgesch1_Omschr) OR !empty($dataMail->Ac_Acc_Voorgesch1_Bedrag)){
		$voorgeschoten1 = "<tr><td style='width:200px;'>".$dataMail->Ac_Acc_Voorgesch1_Omschr."</td><td>&euro; ".$dataMail->Ac_Acc_Voorgesch1_Bedrag."</td></tr>";	}
	if(!empty($dataMail->Ac_Acc_Voorgesch2_Omschr) OR !empty($dataMail->Ac_Acc_Voorgesch2_Bedrag)){
		$voorgeschoten2 = "<tr><td>".$dataMail->Ac_Acc_Voorgesch2_Omschr."</td><td>&euro; ".$dataMail->Ac_Acc_Voorgesch2_Bedrag."</td></tr>";	}
	if(!empty($dataMail->Ac_Acc_Voorgesch3_Omschr) OR !empty($dataMail->Ac_Acc_Voorgesch3_Bedrag)){
		$voorgeschoten3 = "<tr><td>".$dataMail->Ac_Acc_Voorgesch3_Omschr."</td><td>&euro; ".$dataMail->Ac_Acc_Voorgesch3_Bedrag."</td></tr>";	}
	if(empty($voorgeschoten1) AND empty($voorgeschoten2) AND empty($voorgeschoten3)){
		$voorgeschoten1 = "<tr><td>-</td></tr>"; }
        */

        let voorgeschoten = (function(){
            if (sb.getIn(['agreedtimes', 'backpayments'])){
                let descriptions = sb.getIn(['agreedtimes', 'backpayments']).map((backpayment, index) => {
                    return (
                        <tr key={index}>
                            <td>{backpayment.get('description', backpayment.get('type'))}</td>
                            <td>&euro; {backpayment.get('amount')}</td>
                        </tr>
                    );
                });
                if(descriptions.size < 1) {
                    descriptions = <tr><td>-</td></tr>
                }
                return (
                    <table style={{border:"none",padding:"0",borderCollapse: "collapse"}}>
                        {descriptions}
                    </table>
                )
            }
        }).call(this)


        let aanhef = { 'M': 'Meneer', 'V': 'Mevrouw' };
        let aanhefKort = {'M': 'Dhr', 'V': 'Mw' };

        let frl = selectedfreelancer;
        let cli = selectedclient;

        let freelancerLastname = frl.get('insertion') ? frl.get('insertion') + " " + frl.get('lastname') : frl.get('lastname');
        let clientName = generateDisplayName(cli);

        let kilometers = sb.getIn(['agreedtimes', 'kilometers']);

        return (
            <html>
                
                <head>
                </head>
	
	            <body style={{fontFamily:'Arial',fontSize:'13px'}}>
	                <font face="arial" style={{fontSize:'14px'}}>
                        Goedendag,<br />
                        <br />
                            Onderstaande dienst is zojuist geaccordeerd door {aanhef[frl.get('gender')]} {freelancerLastname}<br />
                            Indien u vragen of opmerkingen heeft over de inhoud van deze mail neemt u dan contact op met U Staat Centraal?
                        
                        {
                            !sb.get('cancelled') && <React.Fragment>
                                <table style={{fontSize:'14px', border:'none'}}>
                                    <tr><td><b>Zorgverlener</b></td><td>{aanhef[frl.get('gender')]} {frl.get('firstname')} {freelancerLastname}</td></tr>
                                    <tr><td><b>Client</b></td><td>{aanhef[cli.get('gender')]} {clientName}</td></tr>
                                    <tr><td style={{width:'225px'}}><b>Datum</b></td><td>{moment(sb.get('instancedate')).format('DD / MM / YYYY')}</td></tr>
                                    <tr><td><b>Begintijd</b></td><td>{startTimeString}</td></tr>
                                    <tr><td><b>Eindtijd</b></td><td>{endTimeString}</td></tr>
                                    <tr><td><b>Duur:</b></td><td>{sb.getIn(['agreedtimes','calculatedHours'])} uur</td></tr>
                                    <tr><td><br /></td></tr>
                                    <tr><td><b>Kilometers t.b.v. zorg</b></td><td>{kilometers || 0 } km</td></tr>
                                    {slaapdienst}
                                    { voorgeschoten && <tr><td style={{verticalAlign:'top'}}><b>Voorgeschoten bedrag(en)</b></td><td>{voorgeschoten}</td></tr> }

                                </table><br />
                                Met vriendelijke groet,<br />
                                <br />
                                Het kantoorteam<br />
                                U Staat Centraal<br />
                                071 - 56 222 90
                            </React.Fragment>
                        }

                        {
                            sb.get('cancelled') && <React.Fragment>
                                <table style={{fontSize:'14px', border:'none'}}>
                                    <tr><td><b>Zorgverlener</b></td><td>{aanhef[frl.get('gender')]} {frl.get('firstname')} {freelancerLastname}</td></tr>
                                    <tr><td><b>Client</b></td><td>{aanhef[cli.get('gender')]} {clientName}</td></tr>
                                    <tr><td style={{width:'225px'}}><b>Datum</b></td><td>{moment(sb.get('instancedate')).format('DD / MM / YYYY')}</td></tr>
                                    <tr><td><b>Begintijd</b></td><td>{sb.getIn(['agreedtimes', 'initialStart'])}</td></tr>
                                    <tr><td><b>Eindtijd</b></td><td>{sb.getIn(['agreedtimes', 'initialEnd'])}</td></tr>
                                    <tr><td><br /></td></tr>
                                    <tr><td><b>Dienst vond niet plaats</b></td></tr>
                                </table><br />
                                Met vriendelijke groet,<br />
                                <br />
                                Het kantoorteam<br />
                                U Staat Centraal<br />
                                071 - 56 222 90
                            </React.Fragment>
                        }
                    
        {/* 
                        <p>
                            <strong>Freelancer: </strong> {generateDisplayName(selectedfreelancer)} ({selectedfreelancer.get('id')})<br />
                            <strong>Client: </strong> {generateDisplayName(selectedclient)} ({selectedclient.get('id')})<br />
                            <strong>Dag:</strong> {moment(selectedbooking.get('instancedate')).format('dddd DD MMMM YYYY')}<br />
                            <strong>Startijd: </strong> {!selectedbooking.getIn(['cancelled']) ? selectedbooking.getIn(['agreedtimes', 'start']) : selectedbooking.getIn(['agreedtimes', 'initialStart'])}
                            {selectedbooking.getIn(['agreedtimes', 'start']) != selectedbooking.getIn(['agreedtimes', 'initialStart']) && !selectedbooking.get('cancelled') ? ` (${selectedbooking.getIn(['agreedtimes', 'reasonstartdeviation'])})` : ""}
                            <br />
                            <strong>Eindtijd: </strong> {!selectedbooking.getIn(['cancelled']) ? selectedbooking.getIn(['agreedtimes', 'end']) : selectedbooking.getIn(['agreedtimes', 'initialEnd'])}
                            {selectedbooking.getIn(['agreedtimes', 'end']) != selectedbooking.getIn(['agreedtimes', 'initialEnd']) && !selectedbooking.get('cancelled') ? ` (${selectedbooking.getIn(['agreedtimes', 'reasonenddeviation'])})` : ""}
                            <br />
                            {(function(){
                                if (!selectedbooking.get('cancelled')){
                                    return (
                                        <data>
                                            <strong>Aantal zorgmomenten: </strong> {selectedbooking.getIn(['agreedtimes', 'caremoments'])}<br />
                                            <strong>Kilometers t.b.v. zorg: </strong> {selectedbooking.getIn(['agreedtimes', 'kilometers'])}
                                        </data>
                                    )

                                }
                                else{
                                    return (
                                        <data>
                                            <strong>Dienst geannuleerd: </strong> {selectedbooking.getIn(['cancelled']) ? `Ja (${selectedbooking.get('reasoncancellation')})`: 'Nee'}
                                        </data>
                                    )
                                }
                            }).call(this)}


                            {(function(){
                                if (selectedbooking.getIn(['agreedtimes', 'backpayments'])){
                                    let descriptions = selectedbooking.getIn(['agreedtimes', 'backpayments']).map((backpayment, index) => {
                                        return (
                                            <p key={index}>
                                                Bedrag: {backpayment.get('amount')}<br />
                                                Categorie: {backpayment.get('type')}<br />
                                                Omschrijving: {backpayment.get('description')}
                                            </p>
                                        );
                                    });

                                    return (
                                        <data>
                                            <br /><br />
                                            <strong>Voorgeschoten bedragen</strong>
                                            {descriptions}
                                        </data>
                                    )
                                }
                            }).call(this)}
                        </p>

                        <p><br />Met vriendelijke groet,<br />Het kantoorteam</p> */}
                    </font>
                </body>
            </html>
        )
    }
}

export default ClientBookingConfirmedMail;
