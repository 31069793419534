import Immutable, { Map, Iterable, fromJS } from 'immutable';

export const LOAD_PORTALUSERS = 'LOAD_PORTALUSERS';
export const UNLOAD_PORTALUSERS = 'UNLOAD_PORTALUSERS';
export const UPDATE_PORTALUSERS = 'UPDATE_PORTALUSERS';


export default function PortalUsers(state, action) {

    switch(action.type) {

        case LOAD_PORTALUSERS:

            if(action.loading) {
                state = state.setIn(['loading'], true);
            }

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            return state;
        case UNLOAD_PORTALUSERS:

            return state;
        case UPDATE_PORTALUSERS:

            if(action.single) {
                let single = Iterable.isIterable(action.single) ? action.single : fromJS(action.singe); 
                state = state.setIn(['collection', single.get('id')], single);
            }

            return state;

            default:
    }

    return state || Map();

}