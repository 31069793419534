import React, { Component } from 'react';
import Input from './Input';
import moment from 'moment';

export default class DateToTimeStampInput extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            value: "",
            stringValue: ""
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        let { onChange } = this.props;
        console.log("onChange called on DateToTimeStampInput");
        console.log(value);
        console.log(value.substr(0, 10));
        if(value.length > 9 && moment(value.substr(0, 10), 'DD-MM-YYYY').isValid()) {
            console.log("valid date found changing date");
            onChange(Number(moment(value.substr(0, 10), 'DD-MM-YYYY').format('x'))); 
        } else {
            this.setState({
                stringValue: value
            })
        }

    }

    componentDidMount() {
        let { value } = this.props;
        if(value) {
            let stringValue = moment(Number(value)).format('DD-MM-YYYY');
            this.setState({ value, stringValue })
        }
       
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value != this.props.value) {
            let value = this.props.value;
            if(value) {
                let stringValue = moment(Number(value)).format('DD-MM-YYYY');
                this.setState({ value, stringValue })
            }
        }
    }



    render() {

        let { value, label, type, className, disabled } = this.props;
        let { stringValue } = this.state;

        let isValidValue = stringValue.length > 9 && moment(stringValue.substr(0, 10), 'DD-MM-YYYY').isValid();

        if(!isValidValue) {
            className = (className || "") + " error";
        }

        return <Input 
        {...this.props}
            className={className}
            value={stringValue} 
            onChange={this.onChange}
            

            />


    }

}