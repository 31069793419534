import React, { Component } from 'react';
import './Styles/Textarea.scss';

export default class Textarea extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        let { onChange } = this.props;
        let value = event.target.value;
        if(onChange) {
            onChange(value);
        }
    }


    render() {

        let { value, label, rows, disabled, className, alwaysShowLabel } = this.props;

        return <div className={`textarea ${className || ""}`}>
            {/* <div className="input-label">{label}</div> */}
            { alwaysShowLabel && value && <div className="textarea-label">{label}</div>}
            <textarea disabled={disabled} placeholder={label} value={value} onChange={this.onChange} rows={rows} />
        </div>



    }

}