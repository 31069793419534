import React, { Component } from 'react';
import moment from 'moment';
import {generateDisplayName} from '../Utils/Person';

class FreelancerBookingConfirmedMail extends Component{
    render(){
        let {selectedfreelancer, selectedbooking, selectedclient} = this.props;

        return (
            <data>
                <p>Beste {selectedfreelancer.get('firstname')},</p>
                <p>Onderstaande dienst is zojuist door jou geaccordeerd.</p>
                <p>
                    Wij verzoeken je vriendelijk onderstaande gegevens te controleren.
                    Wil je bij onjuistheden contact met ons opnemen?
                    Doe dit door dit bericht met onderstaand overzicht door te sturen aan U Staat Centraal.
                    Geef in je mailbericht duidelijk aan wat gewijzigd en/of aangevuld moet worden en waarom.
                </p>
                <p>
                    <strong>Freelancer: </strong> {generateDisplayName(selectedfreelancer)} ({selectedfreelancer.get('id')})<br />
                    <strong>Client: </strong> {selectedclient && generateDisplayName(selectedclient)} ({selectedclient.get('id')})<br />
                    <strong>Dag:</strong> {moment(selectedbooking.get('instancedate')).format('dddd DD MMMM YYYY')}<br />
                    <strong>Starttijd: </strong> {!selectedbooking.getIn(['cancelled']) ? selectedbooking.getIn(['agreedtimes', 'start']) : selectedbooking.getIn(['agreedtimes', 'initialStart'])}
                    {selectedbooking.getIn(['agreedtimes', 'start']) != selectedbooking.getIn(['agreedtimes', 'initialStart']) && !selectedbooking.get('cancelled') ? ` (${selectedbooking.getIn(['agreedtimes', 'reasonstartdeviation'])})` : ""}
                    <br />
                    <strong>Eindtijd: </strong> {!selectedbooking.getIn(['cancelled']) ? selectedbooking.getIn(['agreedtimes', 'end']) : selectedbooking.getIn(['agreedtimes', 'initialEnd'])}
                    {selectedbooking.getIn(['agreedtimes', 'end']) != selectedbooking.getIn(['agreedtimes', 'initialEnd']) && !selectedbooking.get('cancelled') ? ` (${selectedbooking.getIn(['agreedtimes', 'reasonenddeviation'])})` : ""}
                    <br />
                    { !selectedbooking.get('cancelled') && <span><strong>Duur: </strong> {selectedbooking.getIn(['agreedtimes','calculatedHours'])} uur<br /></span> }
                    {(function(){
                        if (!selectedbooking.get('cancelled')){
                            return (
                                <data>
                                    <strong>Aantal zorgmomenten: </strong> {selectedbooking.getIn(['agreedtimes', 'caremoments']) || 0}<br />
                                    <strong>Kilometers t.b.v. zorg: </strong> {selectedbooking.getIn(['agreedtimes', 'kilometers']) || 0}
                                </data>
                            )

                        }
                        else{
                            return (
                                <data>
                                    <strong>Dienst geannuleerd: </strong> {selectedbooking.getIn(['cancelled']) ? `Ja (${selectedbooking.get('reasoncancellation')})`: 'Nee'}
                                </data>
                            )
                        }
                    }).call(this)}


                    {(function(){
                        if (selectedbooking.getIn(['agreedtimes', 'backpayments'])){
                            let descriptions = selectedbooking.getIn(['agreedtimes', 'backpayments']).map((backpayment, index) => {
                                return (
                                    <p key={index}>
                                        Bedrag: {backpayment.get('amount')}<br />
                                        Categorie: {backpayment.get('type')}<br />
                                        Omschrijving: {backpayment.get('description')}
                                    </p>
                                );
                            });

                            return (
                                <data>
                                    <br /><br />
                                    <strong>Voorgeschoten bedragen</strong>
                                    {descriptions}
                                </data>
                            )
                        }
                    }).call(this)}
                </p>

                <p><br />Met vriendelijke groet,<br /><br />
                Het kantoorteam<br />
                U Staat Centraal<br />
                071 - 56 222 90</p>
            </data>
        )
    }
}

export default FreelancerBookingConfirmedMail;
