import Immutable, { Map, Iterable } from 'immutable';

export const LOAD_SINGLE_FREELANCER = 'LOAD_SINGLE_FREELANCER';
export const UPDATE_SINGLE_FREELANCER = 'LOAD_SINGLE_FREELANCER';
export const LOAD_WHO_IS_WHO_FREELANCERS = 'LOAD_WHO_IS_WHO_FREELANCERS';
export const UPDATE_WHO_IS_WHO_FREELANCERS = 'UPDATE_WHO_IS_WHO_FREELANCERS';

export default function FreelancersReducer(state, action) {

    switch(action.type) {
        case LOAD_WHO_IS_WHO_FREELANCERS:
                state = state.setIn(['whoiswho_loading'], true);
            return state;
        case UPDATE_WHO_IS_WHO_FREELANCERS:
                state = state.setIn(['whoiswho_loading'], false);
                state = state.setIn(['whoiswho'], Immutable.fromJS(action.collection));
            return state;
        case LOAD_SINGLE_FREELANCER:

            if(action.loading) {
                state = state.setIn(['loading'], true);
            }

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            if(action.single) {
                state = state.setIn(['single'], Iterable.isIterable(action.single) ? action.single : Immutable.fromJS(action.single))
            }

            return state;
        case UPDATE_SINGLE_FREELANCER:
            if(action.single) {
                state = state.setIn(['single'], Iterable.isIterable(action.single) ? action.single : Immutable.fromJS(action.single))
            }
            return state;
            default:

    }

    return state || Map();

}