import EndpointService from '../Service/EndpointService';
import { fromJS } from 'immutable';
import { 
    LOAD_SINGLE_FREELANCER, 
    UPDATE_SINGLE_FREELANCER,
    LOAD_WHO_IS_WHO_FREELANCERS,
    UPDATE_WHO_IS_WHO_FREELANCERS

} from '../Reducers/Freelancers';

// /freelancerportal/api/currentfreelancer

export function getWhoIsWho() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: LOAD_WHO_IS_WHO_FREELANCERS });

            EndpointService.get('/freelancerportal/api/who-is-who').then((response) => {

                dispatch({
                    type: UPDATE_WHO_IS_WHO_FREELANCERS,
                    collection: response.data
                });
                resolve()

            }).catch(reject)

        })
        // return EndpointService.get('/freelancerportal/api/who-is-who');
    }
}

export function updateSocialDetails(freelancer) {

    return (dispatch) => {

        return EndpointService.post('/freelancerportal/api/social-settings', freelancer.toJS()).then((response) => {

            return freelancer;

        })

    }

}

export function updatePassword(freelancer) {

    return (dispatch) => {

        return EndpointService.post('/freelancerportal/api/change-password', freelancer.toJS()).then((response) => {

            return freelancer;

        })

    }

}

export function getCurrentFreelancer() {

    return dispatch => {

        return new Promise((resolve, reject) => {

            dispatch({ type: LOAD_SINGLE_FREELANCER });

            EndpointService.get('/freelancerportal/api/currentfreelancer').then((response) => {

                dispatch({
                    type: UPDATE_SINGLE_FREELANCER,
                    single: fromJS(response.data)
                })

            }).catch(() => {

            })


        })


    }


}

export function getFreelancersInClientTeam(clientid) {

    return new Promise((resolve, reject) => {

        EndpointService.post('/freelancerportal/api/freelancersinclientteam', { clientid: clientid }).then((response) => {
            resolve(fromJS(response.data));
        }).catch(reject)

    })


}