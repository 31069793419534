import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setWeekRange } from '../../UIState';
import ReactSelect from 'react-select';
import moment from 'moment';
import './Styles/CalendarNavigation.scss';

const mapStateToProps = (state) => ({
    weekRangeStart: state.getIn(['ui','weekRangeStart']),
    weekRangeEnd: state.getIn(['ui','weekRangeEnd'])
});

const mapDispatchToProps = (dispatch) => ({
    setWeekRange: (start, end) => dispatch(setWeekRange(start, end))
});

class CalendarNavigation extends Component {

    constructor(props) {
        super(props);
        this.onChangeWeek = this.onChangeWeek.bind(this);
        this.previousWeek = this.previousWeek.bind(this);
        this.nextWeek = this.nextWeek.bind(this);
    }

    onChangeWeek(option) {
        let { setWeekRange } = this.props;
        let start = Number(moment(option.value).startOf('w').format('x'));
        let end = Number(moment(option.value).endOf('w').format('x'));
        setWeekRange(start, end);
    }

    nextWeek() {
        let { weekRangeStart, setWeekRange } = this.props;
        console.log("function nextWeek was called");
        let start = Number(moment(weekRangeStart).add(1, 'w').startOf('w').format('x'));
        let end = Number(moment(weekRangeStart).add(1, 'w').endOf('w').format('x'));
        setWeekRange(start, end);
    }

    previousWeek() {
        let { weekRangeStart, setWeekRange } = this.props;
        let start = Number(moment(weekRangeStart).subtract(1, 'w').startOf('w').format('x'))
        let end = Number(moment(weekRangeStart).subtract(1, 'w').endOf('w').format('x'));
        setWeekRange(start, end);
    }

    render() {

        let { weekRangeStart, weekRangeEnd } = this.props;

        let options = createWeekOptions(weekRangeStart);
        console.log("options", options);
        console.log(weekRangeStart);

        let selectedWeekOption = options.filter(x => x.value == weekRangeStart)[0];

        return <div className="calendar-navigation">
        
            <div className="navigation-left">
                <a href="javascript:;" onClick={this.previousWeek}><i className="zmdi zmdi-chevron-left"></i></a>
            </div>

            <div className="navigation">
            
                <ReactSelect options={options} value={selectedWeekOption} onChange={this.onChangeWeek} />
            
            </div>

            <div className="navigation-right">
                <a href="javascript:;" onClick={this.nextWeek}><i className="zmdi zmdi-chevron-right"></i></a>
            </div>

            

        </div>

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarNavigation);

function createWeekOptions(weekRangeStart) {
    let options = [];
    let date = moment(weekRangeStart);
    date.subtract(2, 'w');
    let iterations = 12;
    while(iterations >= 0) {
        let weekNr = date.isoWeek();
        let start = Number(date.format('x'));
        let startString = date.format('DD-MM-YYYY');
        date.endOf('w');
        let endString = date.format('DD-MM-YYYY');
        options.push({
            value: start,
            label: `${startString} t/m ${endString} - ${weekNr}`
        });
        date.add(1, 'w');
        date.startOf('w');
        iterations--;
    }
    return options;
}
