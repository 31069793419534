import React, { Component } from 'react';
import { OrderedMap, Map } from 'immutable';
import { connect } from 'react-redux';
import { getClients } from '../../Actions/Clients';
import { getProfilePlanning } from '../../Actions/ProfilePlanning';
import { getGeneratedWeeks } from '../../Actions/GeneratedWeeks';
import moment from 'moment';
import './Styles/Clients.scss';
import { generateDisplayName } from '../../Utils/Person';
import { NavLink } from 'react-router-dom';

const mapStateToProps = (state) => ({
    clients: state.getIn(['clients','collection'], OrderedMap()),
    profileplanning: state.getIn(['profileplanning', 'collection'], OrderedMap()),
    generatedWeeks: state.getIn(['generatedweeks','collection'], OrderedMap())
})

const mapDispatchToProps = (dispatch) => ({
    // getClients: () => dispatch(getClients()),
    getProfilePlanning: () => dispatch((getProfilePlanning())),
    getGeneratedWeeks: () => dispatch(getGeneratedWeeks())
})

const isInFuture = (year, week) => {
    let currentYear = moment().year();
    let currentWeek = moment().isoWeek();

    if(year > currentYear) {
        return true;
    }

    if(week >= currentWeek && year == currentYear) {
        return true;
    }

    return false;

}


class ClientProfileList extends Component {


    componentDidMount() {
        let { getClients, getProfilePlanning, getGeneratedWeeks } = this.props;
        // getClients && getClients();
        getProfilePlanning && getProfilePlanning();
        getGeneratedWeeks && getGeneratedWeeks();
    }

    render() {

        let { clients, profileplanning, generatedWeeks } = this.props;

        console.log("Clients");
        console.log("profileplanning", profileplanning);

        clients = clients.filter(x => !x.get('nocaredelivery'));

        profileplanning = profileplanning.filter(x => x.get('type') == 1);
        profileplanning = profileplanning.filter(x => !x.get('isDeleted'));

        let groupedProfilePlanning = profileplanning.groupBy(x => x.get('clientid'))
        groupedProfilePlanning = groupedProfilePlanning.filter((v,i) => clients.get(i));

        generatedWeeks = generatedWeeks.filter((week) => isInFuture(week.get('year'), week.get('week')));
        profileplanning = profileplanning.filter(x => isInFuture(x.get('year'), x.get('week')));


        return <div className="card clients">

            <div className="card-header">
                <h1>Clienten</h1>
            </div>

            <div className="card-body">


                <div className="week-overview">

                    { clients.map((client) => {

                        return <div key={client.get('id')}>

                            <div className="clients-weeks">
                                <NavLink to={`/clients/${client.get('id')}`} className="clients-week" style={{maxWidth:"none", textAlign: "left"}}>

                                        {client.get('gender') == 'M' ? 'Dhr.' : 'Mw.'} {generateDisplayName(client)}

                                </NavLink>

                            </div>



                        </div>

                    }).toList()}


                </div>

            </div>

        </div>


    }

}



export default connect(mapStateToProps, mapDispatchToProps)(ClientProfileList);