import React from 'react';
import Immutable, { List } from 'immutable';
import { generateDisplayName } from '../../Utils/Person';
import moment from 'moment';
//import {generateDisplayName, getNlDate} from 'Core/Utils/Display';


const header = {
    fontFamily: 'Arial',
    fontSize: '12px',
    color: '#800000'
}
const subHeader = {
    fontFamily: 'Arial',
    fontSize: '10px',
    color: '#800000',
    marginBottom: '0px'
}
const subSubHeader = {
    fontFamily: 'Arial',
    fontSize: '10px',
    color: '#000000',
    marginBottom: '0px',
    marginLeft: "0px"
}

const liHeaderGroupStyle = {
    listStyleType: "none",
    marginLeft: "-10px",
    paddingBottom: "10px",
    paddingTop: "10px"
}

const block50 = {
    float: 'left',
    fontFamily: 'Arial',
    fontSize: '9px',
    width: '45%',
    margin: '10px'
}
const infoBlock = {
    display: 'inline-block',
    width: '100%',
    clear: 'both'
}
const floatRight = {
    display: 'block',
    float: 'right'
}
const floatLeft = {
    display: 'block',
    float: 'left'
}
const fullBlockInner = {
    display: 'block',
    width: '100%',
    clear: 'both',
    fontFamily: 'Arial',
    fontSize: '9px'
}

const preStyle = {
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    margin: '0px',
    fontFamily: 'Arial',
    fontSize: '9px',
    padding: '0px',
    whiteSpace: 'pre-wrap'
}

const preTaskStyle = {
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    margin: '0px 0px 0px 20px',
    fontFamily: 'Arial',
    fontSize: '9px',
    padding: '0px',
    whiteSpace: 'pre-wrap'
}

const fullBlock = {
    display: 'block',
    width: '90%',
    maxWidth: '90%',
    clear: 'both',
    margin: '10px',
    fontFamily: 'Arial',
    fontSize: '9px'
}
const liStyle = {
    fontFamily: 'Arial',
    fontSize: '9px'
}
const pageBreak = {
    pageBreakAfter: 'always'
}
const underline = {
    textDecoration: 'underline'
}

class SingleClientCarePlanPrint extends React.Component {
    getGroupFromParts(group){
        let {caredescriptionparts} = this.props;
        if (!caredescriptionparts) return undefined;

        return caredescriptionparts.filter((part) => {
            return part.get('id') == group.get('id');
        }).first();
    }

    isGroupAvailable(group){
        return this.getGroupFromParts(group);
    }

    isItemAvailable(group, item){
        return (group.get('items') || List()).filter((groupitem) => {
            return item.get('id') == groupitem.get('id');
        }).size > 0;
    }

    getItemFromGroup(group, item) {
        return group.get('items').filter((groupitem) => {
            return item.get('id') == groupitem.get('id');
        }).first();
    }

    renderFieldIfAvailable(field) {
        let toRender = field;
        if(field == 0) toRender = "";
        if(!field) toRender = "";
        return toRender;
    }

    renderFieldWithBr(field) {
        let rendered = this.renderFieldIfAvailable(field);
        return rendered ? <data>rendered<br /></data> : rendered;
    }
    
    

    render() {
        let { client } = this.props;
        let selectedclient = client;

        let { renderFieldIfAvailable, renderFieldWithBr } = this;

        return (
            <data>
                <h1 style={header}>Cli&euml;ntinformatie voor {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient)}</h1>
                <div style={infoBlock}>
                    <h2 style={subHeader}>Gegevens cli&euml;nt</h2>
                    <p style={block50}>
                        <span>{selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient)}<br/></span>
                        <span>
                            {renderFieldIfAvailable(selectedclient.getIn(['address', 'street']))} {renderFieldIfAvailable(selectedclient.getIn(['address', 'number']))} {renderFieldIfAvailable(selectedclient.getIn(['address', 'addition']))}
                            { (selectedclient.getIn(['address', 'street']) || selectedclient.getIn(['address','number']) || selectedclient.get(['address', 'addition'])) && <br /> || "" }

                            {(() => {
                                if((selectedclient.getIn(['address', 'postalcode']) || selectedclient.getIn(['address','location'])))
                                return <data>{renderFieldIfAvailable(selectedclient.getIn(['address', 'postalcode']))}{selectedclient.getIn(['address', 'postalcode']) && <data>&nbsp;&nbsp;</data> || ""}{renderFieldIfAvailable(selectedclient.getIn(['address', 'location']))}<br /></data>;
                                return ""
                            })()}
                            
                            {(selectedclient.get('phonenumbers')||[]).map((phonenumber) => {
                                let numberInfo = "";
                                if (phonenumber.get('type') && phonenumber.get('type') != ''){
                                    numberInfo = ` (${phonenumber.get('type')})`;
                                }
                                return <span key={phonenumber.get('number')}>{phonenumber.get('number') + numberInfo}<br /></span>;
                            })}
                        Verzekering {selectedclient.get('insurance')} nummer {selectedclient.get('insurancenumber')}
                        </span>
                    </p>
                    <p style={block50}>
                        {/* Geboortedatum {getNlDate(selectedclient.get('dateofbirth'))}<br /> */}
                        Geboortedatum {selectedclient.get('dateofbirthstring')}<br />
                    BSN {selectedclient.get('bsn')}<br />
                {selectedclient.get('invoiceforsvb') && selectedclient.get('svbnumber') && `Klantnr. SVB ${selectedclient.get('svbnumber')}` || ""}
                    </p>
                </div>

                {(function(){
                    if (selectedclient.getIn(['partner', 'iswidow']) || selectedclient.getIn(['partner', 'lastname'])){
                        return (
                            <div style={infoBlock}>
                                <h2 style={subHeader}>Partner</h2>

                                {(function(){
                                    if (selectedclient.getIn(['partner', 'iswidow'])){
                                        return (
                                            <p style={block50}>
                                                {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} is { selectedclient.get('gender') == 'M' ? 'weduwnaar' : 'weduwe' } sinds {selectedclient.getIn(['partner', 'widowsince'])}
                                            </p>
                                        )
                                    }
                                    else{
                                        return (
                                            <data>
                                                <p style={block50}>
                                                    {selectedclient.getIn(['partner', 'gender']) == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient.getIn(['partner']))}
                                                </p>
                                                <p style={block50}>
                                                    {/* Geboortedatum {getNlDate(selectedclient.getIn(['partner', 'dateofbirth']))} */}
                                                    Geboortedatum {selectedclient.getIn(['partner', 'dateofbirthstring'])}
                                                </p>
                                            </data>
                                        )
                                    }
                                }).call(this)}
                            </div>
                        )
                    }
                }).call(this)}


                <div style={infoBlock}>
                    <h2 style={subHeader}>Contactpersonen</h2>
                    {selectedclient.getIn(['contactpersons']).map((contactperson, index) => {
                        return (
                            <p key={index} style={block50}>
                                {contactperson.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '}{generateDisplayName(contactperson)}<br />
                                {renderFieldIfAvailable(contactperson.getIn(['street']))} {renderFieldIfAvailable(contactperson.getIn(['number']))} {renderFieldIfAvailable(contactperson.getIn(['addition']))}
                                { (contactperson.getIn(['street']) || contactperson.getIn(['number']) || contactperson.get(['addition'])) && <br /> || "" }
                                
                                {(() => {
                                    if((contactperson.getIn(['postalcode']) || contactperson.getIn(['location'])))
                                    return <data>{contactperson.get('postalcode', '')}{contactperson.getIn(['postalcode']) && <data>&nbsp;&nbsp;</data> || ""}{contactperson.get('location', '')}<br /></data>;
                                    return ""
                                })()}
                                
                                {contactperson.get('phonenumber', '')} {contactperson.get('mobilenumber', '')}
                                { (contactperson.getIn(['phonenumber']) || contactperson.getIn(['mobilenumber'])) && <br /> || "" }
                                {contactperson.get('email', '')}
                                { contactperson.get('email') && <br /> || ""}
                                {contactperson.get('type') == 'overig' ? contactperson.get('relationship', '') : contactperson.get('type', '')} {contactperson.get('firstname', '')}
                            </p>
                        )
                    })}
                </div>
                {(function(){
                    if (selectedclient.get('doctor')){
                        return (
                            <div style={infoBlock}>
                                <h2 style={subHeader}>Huisarts</h2>
                                <p style={block50}>
                                    Dr. {generateDisplayName(selectedclient.get('doctor'))}
                                    <br />
                                    {selectedclient.getIn(['doctor', 'street'], '')} {selectedclient.getIn(['doctor', 'number'], '') == 0 ? "" : selectedclient.getIn(['doctor', 'number'], '')} {selectedclient.getIn(['doctor', 'addition'], '')}
                                    <br />
                                    {selectedclient.getIn(['doctor', 'postalcode'], '')}&nbsp;&nbsp;{selectedclient.getIn(['doctor', 'location'], '')}
                                    <br />
                                    {(selectedclient.getIn(['doctor', 'contactdetails', 'phonenumbers']) || []).map((phonenumber) => {
                                        let typeInformation = "";
                                        if (phonenumber.get('type') && phonenumber.get('type') != ''){
                                            typeInformation = ` (${phonenumber.get('type')})`;
                                        }
                                        return <span key={phonenumber.get('number')}>{phonenumber.get('number', '')}{typeInformation}<br /></span>
                                    })}
                                </p>
                            </div>
                        );
                    }
                }).call(this)}
                {(function(){
                    if (selectedclient.get('pharmacy')){
                        return (
                            <div style={infoBlock}>
                                <h2 style={subHeader}>Apotheek</h2>
                                <p style={block50}>
                                    {selectedclient.getIn(['pharmacy', 'name'])}
                                    <br />
                                    {selectedclient.getIn(['pharmacy', 'street'])} {selectedclient.getIn(['pharmacy', 'number']) == 0 ? "" : selectedclient.getIn(['pharmacy', 'number'])} {selectedclient.getIn(['pharmacy', 'addition'])}
                                    <br />
                                    {selectedclient.getIn(['pharmacy', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['pharmacy', 'location'])}
                                    <br />
                                    {selectedclient.getIn(['pharmacy', 'phonenumbers'])}
                                </p>
                                <p style={block50}>
                                    <strong>Openingstijden</strong><br />
                                    {(selectedclient.getIn(['pharmacy', 'openingtimes']) || []).map((openingtime) => {
                                        return (
                                            <data key={openingtime.get('dayname')}>
                                                <span>{openingtime.get('starttime')} - {openingtime.get('endtime')} {openingtime.get('dayname')} <br /></span>
                                            </data>
                                        );
                                    })}
                                </p>
                                <div style={fullBlock}>
                                    Bel in geval van spoed buiten deze openingstijden de eigen apotheek om te weten welke de dienstdoende apotheek is.
                                </div>
                            </div>
                        );
                    }
                }).call(this)}

                {(function(){
                    if (selectedclient.getIn(['generalpractice1'])){
                        return (
                            <div style={infoBlock}>
                                <h2 style={subHeader}>Huisartsenpost</h2>
                                <p style={block50}>
                                    {selectedclient.getIn(['generalpractice1', 'name'])}
                                    <br />
                                    {selectedclient.getIn(['generalpractice1', 'street'])} {selectedclient.getIn(['generalpractice1', 'number']) == 0 ? "" : selectedclient.getIn(['generalpractice1', 'number'])} {selectedclient.getIn(['generalpractice1', 'addition'])}
                                    <br/>
                                    {selectedclient.getIn(['generalpractice1', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['generalpractice1', 'location'])}
                                    <br/>
                                    {selectedclient.getIn(['generalpractice1', 'phonenumbers'])}
                                </p>
                            </div>
                        )
                    }
                }).call(this)}


                <div style={infoBlock}>
                    <h2 style={subHeader}>Belangrijke telefoonnummers</h2>
                    <p style={fullBlock}>
                        Vegro 0900 - 388 77 66
                        <br />
                        Alarm 112 (voor ambulance, politie en brandweer)
                        <br />
                        Politie 0900 - 8844 (geen spoed)
                        <br />
                        USC Tijdens kantoor uren 071 - 56 222 90. Buiten kantooruren 06 - 450 99 490
                    </p>
                </div>

                <div style={infoBlock}>
                    <h2 style={subHeader}>Administratie</h2>
                    {(function(){
                        let careStartDate = "";
                        if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'carestartdate'])){
                            // careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'carestartdate']));
                            careStartDate = moment(Number(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'carestartdate']))).format('DD-MM-YYYY');
                        }

                        if (selectedclient.getIn(['caredescription', 'indication', 'wlz', 'carestartdate'])){
                            //careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'carestartdate']));
                            careStartDate = moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'carestartdate']))).format('DD-MM-YYYY');
                        }


                        if (selectedclient.getIn(['caredescription', 'indication', 'wmo', 'carestartdate'])){
                            // careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'carestartdate']));
                            careStartDate = moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'carestartdate']))).format('DD-MM-YYYY');
                        }

                        if (selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'carestartdate'])){
                            // careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'carestartdate']));
                            careStartDate = moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'carestartdate']))).format('DD-MM-YYYY');
                        }

                        if (selectedclient.getIn(['caredescription', 'indication', 'carestartdate'])){
                            // careStartDate = getNlDate(selectedclient.getIn(['caredescription', 'indication', 'carestartdate']))
                            careStartDate = moment(Number(selectedclient.getIn(['caredescription', 'indication', 'carestartdate']))).format('DD-MM-YYYY');
                        }

                        return (
                            <p style={fullBlock}>
                                <strong>Startdatum zorg</strong>
                                <br />
                                {careStartDate}
                            </p>
                        )
                    }).call(this)}
                    <div style={fullBlock}>
                        {(function(){
                            if (selectedclient.getIn(['invoice', 'hasInvoicingAddress'])){
                                return (
                                    <p>
                                        <strong>Factuuradres</strong>
                                        <br />
                                        {selectedclient.getIn(['invoiceaddress','name'])}
                                        <br />
                                        {selectedclient.getIn(['invoiceaddress', 'street'])} {selectedclient.getIn(['invoiceaddress', 'number']) == 0 ? "" : selectedclient.getIn(['invoiceaddress', 'number'])} {selectedclient.getIn(['invoiceaddress', 'addition'])}
                                        <br />
                                        {selectedclient.getIn(['invoiceaddress', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['invoiceaddress', 'location'])}
                                    </p>
                                )
                            }
                            else{
                                return (
                                    <p>
                                        <strong>Factuuradres</strong>
                                        <br />
                                        {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient)}
                                        <br />
                                        {selectedclient.getIn(['address', 'street'])} {selectedclient.getIn(['address', 'number']) == 0 ? "" : selectedclient.getIn(['address', 'number'])} {selectedclient.getIn(['address', 'addition'])}
                                        <br />
                                        {selectedclient.getIn(['address', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['address', 'location'])}
                                    </p>
                                )
                            }
                        }).call(this)}

                        {(function(){
                            if (selectedclient.get('needsintermediar')){
                                return (
                                    <p>
                                        <strong>Tekenbevoegde voor overeenkomsten</strong>
                                        <br />
                                        {selectedclient.getIn(['intermediar', 'gender']) == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient.getIn(['intermediar']))}
                                        <br />
                                        {selectedclient.getIn(['intermediar', 'street'])} {selectedclient.getIn(['intermediar', 'number']) == 0 ? "" : selectedclient.getIn(['intermediar', 'number'])} {selectedclient.getIn(['intermediar', 'addition'])}
                                        <br />
                                        {selectedclient.getIn(['intermediar', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['intermediar', 'location'])}
                                    </p>
                                )
                            }
                            else{
                                return (
                                    <p>
                                        <strong>Tekenbevoegde voor overeenkomsten</strong>
                                        <br />
                                        {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} is zelf bevoegd.
                                    </p>
                                )
                            }
                        }).call(this)}

                    </div>

                    {(function(){
                        if (selectedclient.getIn(['administrationbyother'])){
                            return (
                                <div style={infoBlock}>
                                    <p style={fullBlock}>
                                        <strong>Gewaarborgde hulp</strong>
                                        <br />
                                        {selectedclient.getIn(['administrativecontact', 'gender']) == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient.getIn(['administrativecontact']))}
                                        <br />
                                        {selectedclient.getIn(['administrativecontact', 'street'])} {selectedclient.getIn(['administrativecontact', 'number']) == 0 ? "" : selectedclient.getIn(['administrativecontact', 'number'])}{selectedclient.getIn(['administrativecontact', 'addition'])}
                                        <br />
                                        {selectedclient.getIn(['administrativecontact', 'postalcode'])}&nbsp;&nbsp;{selectedclient.getIn(['administrativecontact', 'location'])}
                                        <br />
                                        {selectedclient.getIn(['administrativecontact', 'phonenumber'])} {selectedclient.getIn(['administrativecontact', 'mobilenumber'])}
                                    </p>
                                </div>
                            )
                        }
                    }).call(this)}

                    {(function(){
                        let indicationZvw = "";
                        let indicationWlz = "";
                        let indicationIndividual = "";
                        let indicationWmoHome = "";
                        if (selectedclient.getIn(['caredescription','indication','hasZvw'])){
                            let pgbStartDate = "";
                            let startDateIndication = "";
                            let endDateIndication = "";
                            let pvHours = "";
                            let vpHours = "";
                            if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'pgbstartdate'])){
                                pgbStartDate = (
                                    // <span>Startdatum Pgb {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'pgbstartdate']))}<br /></span>
                                    <span>Startdatum Pgb {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'pgbstartdate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }
                            if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'startdate'])){
                                startDateIndication = (
                                    // <span>Startdatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'startdate']))}<br /></span>
                                    <span>Startdatum indicatie {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'startdate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }
                            if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'enddate'])){
                                endDateIndication = (
                                    // <span>Einddatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'enddate']))}<br /></span>
                                    <span>Einddatum indicatie {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'zvw', 'enddate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }
                            if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'pv'])){
                                pvHours = (
                                    <data>
                                        Persoonlijke verzorging {selectedclient.getIn(['caredescription', 'indication', 'zvw', 'pv'])} uur per week
                                        <br />
                                    </data>
                                )
                            }
                            if (selectedclient.getIn(['caredescription', 'indication', 'zvw', 'vp'])){
                                vpHours = (
                                    <data>
                                        Verpleging {selectedclient.getIn(['caredescription', 'indication', 'zvw', 'vp'])} uur per week
                                        <br />
                                    </data>
                                )
                            }

                            indicationZvw = (
                                <div style={infoBlock}>
                                    <p style={fullBlock}>
                                        <strong>Indicatie</strong>
                                        <br />
                                        Indicatie Zvw
                                        <br />
                                        Op basis van {selectedclient.getIn(['caredescription', 'indication', 'zvw', 'type']) == 'pgb' ? 'Pgb' : 'ZIN'}
                                        <br />
                                        {pvHours}
                                        {vpHours}
                                        {pgbStartDate}
                                        {startDateIndication}
                                        {endDateIndication}
                                    </p>
                                </div>
                            )
                        }
                        if (selectedclient.getIn(['caredescription','indication','hasWlz'])){
                            let pgbStartDate = "";
                            let startDateIndication = "";
                            let endDateIndication = "";
                            if (selectedclient.getIn(['caredescription', 'indication', 'wlz', 'pgbstartdate'])){
                                pgbStartDate = (
                                    // <span>Startdatum Pgb {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'pgbstartdate']))}<br /></span>
                                    <span>Startdatum Pgb {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'pgbstartdate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }

                            if (selectedclient.getIn(['caredescription', 'indication', 'wlz', 'startdate'])){
                                startDateIndication = (
                                    // <span>Startdatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'startdate']))}<br /></span>
                                    <span>Startdatum indicatie {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'startdate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }
                            if (selectedclient.getIn(['caredescription', 'indication', 'wlz', 'enddate'])){
                                endDateIndication = (
                                    // <span>Einddatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'enddate']))}<br /></span>
                                    <span>Einddatum indicatie {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wlz', 'enddate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }

                            indicationWlz = (
                                <div style={infoBlock}>
                                    <p style={fullBlock}>
                                        <strong>Indicatie</strong><br />
                                        Indicatie Wlz.<br />
                                        {selectedclient.getIn(['caredescription', 'indication', 'wlz', 'type']) == 'pgb' ? 'Hele indicatie in Pgb' : 'Deel van indicatie in Pgb'}
                                        <br />
                                        ZZP {selectedclient.getIn(['caredescription', 'indication', 'wlz', 'zzp'])}
                                        <br />
                                        {pgbStartDate}
                                        {startDateIndication}
                                        {endDateIndication}
                                    </p>
                                </div>
                            )
                        }
                        if (selectedclient.getIn(['caredescription','indication','hasWmo'])) {
                            let pgbStartDate = "";
                            let startDateIndication = "";
                            let endDateIndication = "";
                            if (selectedclient.getIn(['caredescription', 'indication', 'wmo', 'pgbstartdate'])){
                                pgbStartDate = (
                                    // <span>Startdatum Pgb {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'pgbstartdate']))}<br /></span>
                                    <span>Startdatum Pgb {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'pgbstartdate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }

                            if (selectedclient.getIn(['caredescription', 'indication', 'wmo', 'startdate'])){
                                startDateIndication = (
                                    // <span>Startdatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'startdate']))}<br /></span>
                                    <span>Startdatum indicatie {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'startdate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }
                            if (selectedclient.getIn(['caredescription', 'indication', 'wmo', 'enddate'])){
                                endDateIndication = (
                                    // <span>Einddatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'enddate']))}<br /></span>
                                    <span>Einddatum indicatie {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wmo', 'enddate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }

                            indicationIndividual = (
                                <div style={infoBlock}>
                                    <p style={fullBlock}>
                                        <strong>Indicatie</strong>
                                        <br />
                                        Indicatie Wmo (Individuele begeleiding)
                                        <br />
                                        {selectedclient.getIn(['caredescription', 'indication', 'wmo', 'type']) == 'pgb' ? 'Op basis van Pgb' : 'Op basis van ZIN'}
                                        <br />
                                        Individuele begeleiding {selectedclient.getIn(['caredescription', 'indication', 'wmo', 'zzp'])} uur per week
                                        <br />
                                        {pgbStartDate}
                                        {startDateIndication}
                                        {endDateIndication}
                                    </p>
                                </div>
                            )
                        }
                        if (selectedclient.getIn(['caredescription','indication','hasWmoHome'])){
                            let pgbStartDate = "";
                            let startDateIndication = "";
                            let endDateIndication = "";
                            if (selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'pgbstartdate'])){
                                pgbStartDate = (
                                    // <span>Startdatum Pgb {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'pgbstartdate']))}<br /></span>
                                    <span>Startdatum Pgb {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'pgbstartdate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }
                            if (selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'startdate'])){
                                startDateIndication = (
                                    // <span>Startdatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'startdate']))}<br /></span>
                                    <span>Startdatum indicatie {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'startdate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }
                            if (selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'enddate'])){
                                endDateIndication = (
                                    // <span>Einddatum indicatie {getNlDate(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'enddate']))}<br /></span>
                                    <span>Einddatum indicatie {moment(Number(selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'enddate']))).format('DD-MM-YYYY')}<br /></span>
                                )
                            }

                            indicationWmoHome = (
                                <div style={infoBlock}>
                                    <h2 style={subHeader}></h2>
                                    <p style={fullBlock}>
                                        <strong>Indicatie</strong>
                                        <br />
                                        Indicatie Wmo (Individuele begeleiding)
                                        <br />
                                        {selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'type']) == 'pgb' ? 'Op basis van Pgb' : 'Op basis van ZIN'}
                                        <br />
                                        Individuele begeleiding {selectedclient.getIn(['caredescription', 'indication', 'wmohome', 'zzp'])} uur per week
                                        <br />
                                        {pgbStartDate}
                                        {startDateIndication}
                                        {endDateIndication}
                                    </p>
                                </div>
                            )
                        }
                        return (
                            <data>
                                {indicationZvw}
                                {indicationWlz}
                                {indicationIndividual}
                                {indicationWmoHome}
                            </data>
                        )
                    }).call(this)}

                </div>


                <div style={pageBreak}></div>
                <h1 style={header}>Zorgplan voor {selectedclient.get('gender') == 'M' ? 'Dhr. ' : 'Mw. '} {generateDisplayName(selectedclient)}</h1>
                <div style={infoBlock}>
                    <h2 style={subHeader}>Sociale situatie</h2>
                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'children'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Kinderen / Kleinkinderen</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'children'])}</pre>
                                </div>
                            );
                        }
                    }).call(this)}
                    {(function(){

                        // console.log("rendering anamnese part arbeidsverleden, interesses en hobby's");
                        // console.log(selectedclient.getIn(['caredescription', 'anamnese', 'social', 'workhistory']))

                        if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'workhistory'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Arbeidsverleden, interesses, hobby's</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'workhistory'])}</pre>
                                </div>
                            )
                        }
                    }).call(this)}
                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'othercare'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Overige (mantel)zorg, huishoudelijke hulp</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'othercare'])}</pre>
                                </div>
                            )
                        }
                    }).call(this)}
                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'partner'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Bijzonderheden partner</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'partner'])}</pre>
                                </div>
                            )
                        }
                    }).call(this)}
                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'anamnese', 'social', 'notes'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Bijzonderheden</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'social', 'notes'])}</pre>
                                </div>
                            )
                        }
                    }).call(this)}
                </div>

                <div style={infoBlock}>
                    <h2 style={subHeader}>Medische situatie</h2>
                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'details'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Ziektebeeld</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'details'])}</pre>
                                </div>
                            );
                        }
                    }).call(this)}
                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'physicalcondition'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Lichamelijke conditie</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'physicalcondition'])}</pre>
                                </div>
                            )
                        }
                    }).call(this)}
                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'mentalcondition'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Geestelijke conditie</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'mentalcondition'])}</pre>
                                </div>
                            )
                        }
                    }).call(this)}
                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'allergies'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Allergi&euml;n</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'allergies'])}</pre>
                                </div>
                            )
                        }
                    }).call(this)}

                    <div style={fullBlock}>
                        <strong>Reanimatie bij hartstilstand</strong>
                        <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'cpr']) ? 'Ja' : 'Nee. Svp verklaring hieromtrent in dossier doen.'}</pre>
                    </div>

                    {(function(){
                        if (selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'notes'])){
                            return (
                                <div style={fullBlock}>
                                    <strong>Bijzonderheden</strong>
                                    <pre style={preStyle}>{selectedclient.getIn(['caredescription', 'anamnese', 'medical', 'notes'])}</pre>
                                </div>
                            )
                        }
                    }).call(this)}
                </div>

                {(function(){
                    //Alle zorgplan onderdelen.
                    if (selectedclient.getIn(['caredescription', 'careplan', 'parts'])){
                        try{
                            
                            let parts = (selectedclient.getIn(['caredescription', 'careplan', 'parts']) || []);
                            parts = parts.sort((a,b) => {
                                return Number(a.get("migrationnumber", a.get("migrationNumber", 100000))) - Number(b.get("migrationnumber", b.get("migrationNumber", 100000)))
                            })
                            parts = parts.filter(this.isGroupAvailable.bind(this));
                            parts = parts.map((part) => {
                                let originalPart = this.getGroupFromParts(part);
                                return part.set("items", part.get("items").filter(this.isItemAvailable.bind(this, originalPart)));
                            })

                            parts = parts.map(part => {
                                return part.set("items", part.get("items").map((item) => {
                                    let originalPart = this.getGroupFromParts(part);
                                    let originalItem = this.getItemFromGroup(originalPart, item);
                                    return item.set("sortOrder", originalItem.get("order") || 999999);
                                }))
                            })

                            parts = parts.map(part => {


                                return part.set("items", part.get("items").sort((a,b) => {
                                    return Number(a.get("sortOrder")) - Number(b.get("sortOrder"));
                                }))

                            })

                            parts = parts.map((part, pIndex) => {
                                let grouped = part.get("items").groupBy(x => x.get("header"));
                                let processedGroupArray = [];
                                let partItems = [];
                                part.get("items").forEach((item) => {
                                    let renderItems = [];
                                    let headerUlStyle = {
                                        paddingLeft: "20px",
                                        listStyleType: "disc"
                                    }
                                    if(item.get("header")) {
                                        if(processedGroupArray.indexOf(item.get('header')) < 0 ) {
                                            let subs = grouped.get(item.get('header')).map((item) => (<li key={item.get('name')} style={liStyle}>{item.get('name')}</li>));
                                            partItems.push(<li key={pIndex.toString()} style={liHeaderGroupStyle}><h4 style={{marginTop:"0px",...subSubHeader}}>{item.get('header')}</h4><ul style={headerUlStyle} key={item.get("header")}>{subs}</ul></li>);
                                            processedGroupArray.push(item.get("header"))
                                        }
                                    } else {
                                        partItems.push(<li key={item.get('name')} style={liStyle}>{item.get('name')}</li>)
                                    }
                                })
                                if (partItems.length > 0){

                                    const ulGroupStyle = {
                                        paddingLeft: "20px"
                                    }

                                    return (
                                        <div style={infoBlock} key={pIndex.toString()}>
                                            <h2 style={subHeader}>{part.get('name')}</h2>
                                            <ul style={ulGroupStyle}>
                                                {partItems}
                                            </ul>
                                        </div>
                                    )
                                }
                            });

                            return parts;
                        }
                        catch(e){
                            console.log("an error occurred???");
                            console.log(e);
                        }
                    }
                }).call(this)}

                {(function() { 
                    let safetyMeasures = selectedclient.getIn(['caredescription','safetymeasures']);
                    if(safetyMeasures && safetyMeasures.size > 0) {

                        const ulGroupStyle = {
                            paddingLeft: "20px"
                        }

                        return <div style={infoBlock}>
                            <h2 style={subHeader}>Veiligheidsmaatregelen</h2>
                            <ul style={ulGroupStyle}>
                                { safetyMeasures.map((safetyMesasure) => <li key={safetyMesasure.get('name')} style={liStyle}>{safetyMesasure.get('name')}</li>) }
                    
                            </ul>
                            <em style={{fontSize:"9px"}}>* Zie document veiligheidsmaatregelen</em>
                        </div>

                    }

                }).call(this)}

                {(function(){
                    let mondayTasks = "";
                    let tuesdayTasks = "";
                    let wednesdayTasks = "";
                    let thursdayTasks = "";
                    let fridayTasks = "";
                    let saturdayTasks = "";
                    let sundayTasks = "";

                    let oneFilled = false;
                    if (selectedclient.getIn(['caredescription', 'careplan', 'monday']) && selectedclient.getIn(['caredescription', 'careplan', 'monday']).trim() != ""){
                        mondayTasks = (
                            <p style={fullBlock}>
                                <strong>Maandag</strong>
                                <pre style={preTaskStyle}>{selectedclient.getIn(['caredescription', 'careplan', 'monday'])}</pre>
                            </p>
                        );
                        oneFilled = true;
                    }
                    if (selectedclient.getIn(['caredescription', 'careplan', 'tuesday']) && selectedclient.getIn(['caredescription', 'careplan', 'tuesday']).trim() != ""){
                        tuesdayTasks = (
                            <p style={fullBlock}>
                                <strong>Dinsdag</strong>
                                
                                <pre style={preTaskStyle}>{selectedclient.getIn(['caredescription', 'careplan', 'tuesday'])}</pre>
                            </p>
                        );
                        oneFilled = true;
                    }
                    if (selectedclient.getIn(['caredescription', 'careplan', 'wednesday']) && selectedclient.getIn(['caredescription', 'careplan', 'wednesday']).trim() != ""){
                        wednesdayTasks = (
                            <p style={fullBlock}>
                                <strong>Woensdag</strong>
                                <pre style={preTaskStyle}>{selectedclient.getIn(['caredescription', 'careplan', 'wednesday'])}</pre>
                            </p>
                        );
                        oneFilled = true;
                    }
                    if (selectedclient.getIn(['caredescription', 'careplan', 'thursday']) && selectedclient.getIn(['caredescription', 'careplan', 'thursday']).trim() != ""){
                        thursdayTasks = (
                            <p style={fullBlock}>
                                <strong>Donderdag</strong>
                                <pre style={preTaskStyle}>{selectedclient.getIn(['caredescription', 'careplan', 'thursday'])}</pre>
                            </p>
                        );
                        oneFilled = true;
                    }
                    if (selectedclient.getIn(['caredescription', 'careplan', 'friday']) && selectedclient.getIn(['caredescription', 'careplan', 'friday']).trim() != ""){
                        fridayTasks = (
                            <p style={fullBlock}>
                                <strong>Vrijdag</strong>
                                <pre style={preTaskStyle}>{selectedclient.getIn(['caredescription', 'careplan', 'friday'])}</pre>
                            </p>
                        );
                        oneFilled = true;
                    }
                    if (selectedclient.getIn(['caredescription', 'careplan', 'saturday']) && selectedclient.getIn(['caredescription', 'careplan', 'saturday']).trim() != ""){
                        saturdayTasks = (
                            <p style={fullBlock}>
                                <strong>Zaterdag</strong>
                                <pre style={preTaskStyle}>{selectedclient.getIn(['caredescription', 'careplan', 'saturday'])}</pre>
                            </p>
                        );
                        oneFilled = true;
                    }
                    if (selectedclient.getIn(['caredescription', 'careplan', 'sunday']) && selectedclient.getIn(['caredescription', 'careplan', 'sunday']).trim() != ""){
                        sundayTasks = (
                            <p style={fullBlock}>
                                <strong>Zondag</strong>
                                <pre style={preTaskStyle}>{selectedclient.getIn(['caredescription', 'careplan', 'sunday'])}</pre>
                            </p>
                        );
                        oneFilled = true;
                    }


                    if (oneFilled){
                        return (
                            <div style={infoBlock}>
                                <h2 style={subHeader}>Taken</h2>
                                {mondayTasks}
                                {tuesdayTasks}
                                {wednesdayTasks}
                                {thursdayTasks}
                                {fridayTasks}
                                {saturdayTasks}
                                {sundayTasks}
                            </div>
                        )
                    }
                }).call(this)}

                {(function(){
                    if (selectedclient.getIn(['caredescription', 'careplan', 'notes'])){
                        return (
                            <div style={infoBlock}>
                                <h2 style={subHeader}>Bijzonderheden</h2>
                                <p style={fullBlock}>
                                    <pre style={preTaskStyle}>{selectedclient.getIn(['caredescription', 'careplan', 'notes'])}</pre>
                                </p>
                            </div>
                        )
                    }
                }).call(this)}



            </data>

        )

    }
}
/*

<div style={infoBlock}>
    <p style={fullBlock}>
        Er is goedkeuring, dat er namens {selectedclient.get('gender') == 'M' ? 'dhr' : 'mw'} in het belang van {selectedclient.get('gender') == 'M' ? 'dhr' : 'mw'} overleg plaatsvindt over de
        gezondheids- en zorgsituatie met andere betrokkenen zoals huisarts, specialist, fysiotherapeut,
        thuiszorginstelling, GGZ, CIZ, Zorgkantoor en familieleden. Alsmede is er goedkeuring,
        dat een auditor van een keurmerkinstituut inzage heeft in de administratieve behandeling
        van het digitale dossier van {selectedclient.get('gender') == 'M' ? 'dhr' : 'mw'} , dit ter controle voor het kwaliteitskeurmerk van USC.
    </p>
</div>

*/
export default SingleClientCarePlanPrint;
