import React, { Component, useEffect } from 'react';
import './App.scss';
import { HashRouter as Router } from 'react-router-dom';
import Content from './Content';
import { connect } from 'react-redux';
import { getCurrentFreelancer } from './Actions/Freelancers';
import { getClients } from './Actions/Clients';
import { getCurrentUser } from './Actions/Users';
import { getPortalUsers } from './Actions/PortalUsers';
import { withConnection } from './Context/Connection';
import swal from 'sweetalert2';


import MainMenu from './MainMenu';
import MenuToggle from './MenuToggle';

String.prototype.convertToNumber = function() {
    return Number(this.replace(/\./g,',').replace(/,([0-9]{0,2})$/, '.$1').replace(/\,/g, ''))
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    getCurrentFreelancer: () => dispatch(getCurrentFreelancer()),
    getClients: () => dispatch(getClients()),
    getCurrentUser: () => dispatch(getCurrentUser()),
    getPortalUsers: () => dispatch(getPortalUsers())
})


class App extends Component {


    constructor(props) {
        super(props);
        this.systemComesBackOnline = this.systemComesBackOnline.bind(this);
    }

    systemComesBackOnline() {
        let { getCurrentFreelancer, getClients, getCurrentUser, getPortalUsers } = this.props;
        getCurrentFreelancer && getCurrentFreelancer();
        getClients && getClients();
        getCurrentUser && getCurrentUser();
        getPortalUsers && getPortalUsers();
        
       let unfinishedEntries = [];
        if(localStorage) {
            for(var key in localStorage) {

                if(typeof key === 'string' && key.startsWith('entry-')) {
                    unfinishedEntries.push(key.replace(/entry\-(.*?)/, '$1'));
                    

                }

            }
        }

        if(unfinishedEntries.length > 0) {
            swal.fire({
                text: `Terug naar niet afgeronde rapportage bij client ${unfinishedEntries[0]}`,
           
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ja',
                cancelButtonText: 'Nee'
            }).then((result) => {
                if (result.value) {
                    window.location.hash = '#/clients/' + unfinishedEntries[0];
                } else {
                    console.log("canceleed");
                    // localStorage.removeItem(`entry-${unfinishedEntries[0]}`);
                }
            })
        }


    }

    componentDidMount() {

        let { getCurrentFreelancer, getClients, getCurrentUser, getPortalUsers } = this.props;
        getCurrentFreelancer && getCurrentFreelancer();
        getClients && getClients();
        getCurrentUser && getCurrentUser();
        getPortalUsers && getPortalUsers();

        window.addEventListener('online', this.systemComesBackOnline);

    }

    componentWillUnmount() {
        window.removeEventListener('online', this.systemComesBackOnline);
    }
    // account
    // accounts-outline
    // border-color


    // account Mijn planning
    // accounts-outline Cliënten / Planning
    // border-color Uren accorderen

    render() {
        let { connected } = this.props;
        return (
            <Router >
                <div className="portal">

                    <div className="header">

                        <MenuToggle />
                        <h2>U Staat centraal zorgconnect</h2>
                        
                    </div>
                    { !connected && <div className="bg-red-400 p-2">
                        Let op! Er is momenteel geen internetverbinding, de functies van Zorgconnect werken alleen met internetverbinding.
                    </div> }

                    <div className="main-container">

                        <MainMenu  />


                        <Content />
                    </div>
                </div>
            </Router>
        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withConnection(App));
