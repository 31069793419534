import styled from 'styled-components';
import { SlideFromLeft, SlideToLeft } from './Keyframes/SlideFromLeft';

export const Menu = styled.div`
    flex: 0;
    animation: ${SlideToLeft} 0.1s linear;
    background: #FFFFFF;
    
    &.open {
        min-width: 300px;
        animation: ${SlideFromLeft} 0.1s linear;
    }

    &.closed {
        min-width: 0px;
        animation: ${SlideToLeft} 0.1s linear;
    }
    
`