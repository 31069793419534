import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Components/Home';
import FreelancerPlanning from './Components/FreelancerPlanning';
import SingleClient from './Components/Clients/SingleClient';
import Clients from './Components/Clients';
import ClientProfileList from "./Components/Clients/ClientProfileList";
import Bookings from './Components/Bookings';
import FreelancerDocuments from './Components/Documents';
import Settings from './Components/Settings';
import WhoIsWho from './Components/WhoIsWho';
import Infotheek from './Components/Infotheek';

class Content extends Component {

    render() {
        return <div className="content">
            <Switch>
                <Route path="/planning" component={FreelancerPlanning} />
                <Route path="/bookings" component={Bookings} />
                <Route path="/clients/:clientid/:start(\d+)?/:end(\d+)?" component={SingleClient} />
                <Route path="/clients" component={Clients} />
                <Route path="/all-clients" component={ClientProfileList} />
                <Route path="/documents" component={FreelancerDocuments} />
                <Route path="/settings" component={Settings} />
                <Route path="/who-is-who" component={WhoIsWho} />
                <Route path="/infotheek" component={Infotheek} />
                <Route component={Clients} />
            </Switch>
        </div>
    }
}

export default Content;