import Immutable, { Map } from 'immutable';

export const LOAD_PROFILEPLANNING = 'LOAD_PROFILEPLANNING';
export const UNLOAD_PROFILEPLANNING = 'UNLOAD_PROFILEPLANNING';
export const UPDATE_PROFILEPLANNING = 'UPDATE_PROFILEPLANNING';


export default function ProfilePlanning(state, action) {

    switch(action.type) {

        case LOAD_PROFILEPLANNING:

            if(action.loading) {
                state = state.setIn(['loading'], true);
            }

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            return state;
        case UNLOAD_PROFILEPLANNING:

            return state;
        case UPDATE_PROFILEPLANNING:

            return state;
            default:

    }

    return state || Map();

}