import { LOAD_UNBOOKEDPLANNING, UNLOAD_UNBOOKEDPLANNING, UPDATE_UNBOOKEDPLANNING } from '../Reducers/Bookings';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';
import { fromJS } from 'immutable';

export function loadUnbookedPlanning() {
    return (dispatch) => {

        dispatch({
            type: LOAD_UNBOOKEDPLANNING,
            loading: true
        })
        EndpointService.get("/freelancerportal/api/unbookedplanning").then((response) => {
            
            console.log("response", response);

            dispatch({
                type: LOAD_UNBOOKEDPLANNING,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })
    }
}

export function saveBookings(bookings) {



    return dispatch => {

        return new Promise((resolve, reject) => {

            

            EndpointService
            .post('/freelancerportal/api/bookings', bookings.toJS())
            .then((response) => {

                console.log("the bookings were saved");
                console.log(response.data);
                resolve();

            }).catch(reject);

        })

    }

}

export function getUnbookedPlanning(reload) {
    return dispatch => {
        dispatch(loadUnbookedPlanning());
    }
}