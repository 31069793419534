import React, { Component } from 'react';
import { getClientDocuments } from '../../Actions/Documents';
import { connect } from 'react-redux';
import { List } from 'immutable';
import moment from 'moment';

const mapDispatchToProps = (dispatch) => ({
    getClientDocuments: (clientid) => dispatch(getClientDocuments(clientid))
})

const apiUrl = window.location.href.match('localhost:30') ? "http://localhost:8080" : "";

class SingleClientDocuments extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        if(this.props.client) {
            getClientDocuments &&this.loadClientDocuments();
        }
    }

    loadClientDocuments() {
        let { client, getClientDocuments } = this.props;
        getClientDocuments && getClientDocuments(client.get('id')).then((documents) => {
            this.setState({
                documents: documents,
                loaded: true
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {

        let { getClientDocuments } = this.props;

        if(prevProps.client != this.props.client) {
            this.loadClientDocuments();
        }

        if(prevState.loaded && !this.state.loaded) {
            this.loadClientDocuments();
        }

    }

    render() {
        let { documents } = this.state;
        console.log("documents", documents);

        documents = (documents || List()).sort((a,b) => {
            // Ik zag dat er nog helemaal niet gefilter wordt op type id hier maar mischien dat, dat bij de clienten nog niet nodig is.
            // if(a.get('typeid') != b.get('typeid')) {
            //     return a.get('typeid') - b.get('typeid');
            // }
            return Number(b.get('created')) - Number(a.get('created'));
        });

        return <div className="card single-client-documents">
        
            <div className="card-body">
            
                <div className="documents document-table">

                    <div className="documents-header document-table-row">
                        
                        <div className="document-table-item">Document</div>
                        <div className="document-table-item">Toelichting</div>
                        <div className="document-table-item">Geldig t/m</div>
                        <div className="document-table-item">Bestanden</div>
                        

                    </div>


                    {
                        (documents || List()).map((document) => {

                            return <div className="document-table-row" key={document.get('id').toString()}>

                                <div className="document-table-item">
                                    <div className="document-table-item-label">Document:</div>
                                    <div className="document-table-item-content">
                                        <strong>{document.getIn(['type','name'])}</strong><br />
                                        {document.get('name')}
                                    </div>
                                </div>
                                <div className="document-table-item">
                                    <div className="document-table-item-label">Toelichting:</div>
                                    <div className="document-table-item-content">
                                        {document.getIn(['type','userdefinedenddate']) ? (<span>{moment(document.get('validuntil')).format('DD-MM-YYYY')}</span>) : (<span>Onbeperkt</span>)}
                                    </div>
                                </div>
                                <div className="document-table-item">
                                    <div className="document-table-item-label">Geldig t/m:</div>
                                    <div className="document-table-item-content">
                                        {(document.getIn(['type', 'metadescriptors', 'descriptors']) || []).map((descriptor, index) => {
                                            return (
                                                <span key={index}>
                                                    <br/>
                                                    <strong>{descriptor.get('name')}:</strong>{document.getIn(['meta', descriptor.get('name')])}
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="document-table-item">
                                    <div className="document-table-item-label">Bestanden:</div>
                                    <div className="document-table-item-content">
                                        <ul>
                                            {(document.get('files') || []).map((file, filename) => {
                                                return (
                                                    <li key={filename}>
                                                        <a target="_blank" href={`${apiUrl}/freelancerportal/files/clients/${document.get('entityid')}/${document.get('id')}/${filename}`}>{filename}</a>
                                                    </li>
                                                )
                                            }).toList()}
                                        </ul>
                                    </div>
                                </div>


                            </div>

                        })
                    }

                    

                </div>

            </div>

        </div>
    }


}
/*
    <tr key={document.get('id')}>
        <td>{document.getIn(['type', 'name'])}</td>
        <td>{document.get('name')}</td>
        <td>
            {document.getIn(['type','userdefinedenddate']) ? (<span>{getNlDate(document.get('validuntil'))}</span>) : (<span>Onbeperkt</span>)}
            {(document.getIn(['type', 'metadescriptors', 'descriptors']) || []).map((descriptor, index) => {
                return (
                    <span key={index}>
                        <br/>
                        <strong>{descriptor.get('name')}:</strong>{document.getIn(['meta', descriptor.get('name')])}
                    </span>
                );
            })}
            <br /><strong>Zichtbaar voor:</strong> {access}
        </td>
        <td>
            <ul>
                {(document.get('files') || []).map((file, filename) => {
                    return (
                        <li key={filename}>
                            <a target="_blank" href={`/files/clients/${document.get('entityid')}/${document.get('id')}/${filename}`}>{filename}</a>
                        </li>
                    )
                })}
            </ul>
        </td>
    </tr>
*/
export default connect(null, mapDispatchToProps)(SingleClientDocuments);