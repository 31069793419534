import styled, { keyframes } from 'styled-components';
/*

    0% {
        width: 0px;
        min-width: 0px;
    }
    100% {
        width: 300px;
        min-width: 300px;
    }

*/
export const SlideFromLeft = keyframes`
    0% {
        width: 0px;
        min-width: 0px;
    }
    100% {
        width: 300px;
        min-width: 300px;
    }
`

export const SlideToLeft = keyframes`
    0% {
        width: 300px;
        min-width: 300px;
    }
    100% {
        width: 0px;
        min-width: 0px;
    }
`