import Immutable, { Map } from 'immutable';

export const LOAD_CAREDESCRIPTIONPARTS = 'LOAD_CAREDESCRIPTIONPARTS';
export const UNLOAD_CAREDESCRIPTIONPARTS = 'UNLOAD_CAREDESCRIPTIONPARTS';
export const UPDATE_CAREDESCRIPTIONPARTS = 'UPDATE_CAREDESCRIPTIONPARTS';


export default function CareDescriptionPartsReducer(state, action) {

    switch(action.type) {

        case LOAD_CAREDESCRIPTIONPARTS:

            if(action.loading) {
                state = state.setIn(['loading'], true);
            }

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            return state;
        case UNLOAD_CAREDESCRIPTIONPARTS:

            return state;
        case UPDATE_CAREDESCRIPTIONPARTS:

            return state;
            default:

    }

    return state || Map();

}