import { LOAD_PLANNINGREQUESTS, UNLOAD_PLANNINGREQUESTS, UPDATE_PLANNINGREQUESTS } from '../Reducers/PlanningRequests';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';
import { fromJS } from 'immutable';


export function loadPlanningRequests(params) {
    return (dispatch) => {

        dispatch({
            type: LOAD_PLANNINGREQUESTS,
            loading: true
        })
        return EndpointService.get("/freelancerportal/api/planningrequests").then((response) => {
            
            dispatch({
                type: LOAD_PLANNINGREQUESTS,
                loading: false,
                collection: response.data,
                lastSynced: Number(moment().subtract(5, 'm').format('x'))
            })
            

        }).catch(() => {

        })


    }
}

export function savePlanningRequest(planningRequest) {
    return (dispatch) => {

        return EndpointService.post('/freelancerportal/api/planningrequest', planningRequest.toJS()).then((response) => {
            let record = fromJS(response.data.saved);
            dispatch({
                type: UPDATE_PLANNINGREQUESTS,
                single: record
            })
            return record;

        })

    }
}



export function getPlanningRequests(reload) {
    return dispatch => {
        // syncing logic
        dispatch(loadPlanningRequests());
    }
}

