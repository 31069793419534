import Immutable, { Map, Iterable } from 'immutable';

export const LOAD_SINGLE_USER = 'LOAD_SINGLE_USER';
export const UPDATE_SINGLE_USER = 'LOAD_SINGLE_USER';


export default function UsersReducer(state, action) {

    switch(action.type) {

        case LOAD_SINGLE_USER:

            if(action.loading) {
                state = state.setIn(['loading'], true);
            }

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            if(action.single) {
                state = state.setIn(['single'], Iterable.isIterable(action.single) ? action.single : Immutable.fromJS(action.single))
            }

            return state;
        case UPDATE_SINGLE_USER:
            if(action.single) {
                state = state.setIn(['single'], Iterable.isIterable(action.single) ? action.single : Immutable.fromJS(action.single))
            }
            return state;
            default:

    }

    return state || Map();

}