import Immutable, { Map } from 'immutable';

export const LOAD_REDBOOK_ENTRIES = 'LOAD_REDBOOK_ENTRIES';
export const UNLOAD_REDBOOK_ENTRIES = 'UNLOAD_REDBOOK_ENTRIES';
export const UPDATE_REDBOOK_ENTRIES = 'UPDATE_REDBOOK_ENTRIES';


export default function RedBookEntriesReducer(state, action) {

    switch(action.type) {

        case LOAD_REDBOOK_ENTRIES:

            
                state = state.setIn(['loading'], !!action.loading);

            if(action.collection) {
                state = state.setIn(['collection'], Immutable.fromJS(action.collection)); //.groupBy(x => x.get('id')).map(x => x.first()))
            }

            if(action.lastSynced) {
                state = state.setIn(['lastSynced'], action.lastSynced);
            }

            if(action.error) {
                state = state.setIn(['error'], action.error);
            }

            return state;
        case UNLOAD_REDBOOK_ENTRIES:

            return state;
        case UPDATE_REDBOOK_ENTRIES:

            return state;
            default:

    }

    return state || Map();

}