export function parseUserAgent(ua) {
    // Basic browser detection
    const browserRegexes = [
        { 
            name: 'Chrome', 
            regex: /(?:Chrome|CriOS)\/(\d+\.\d+)/,
            exclusions: [ /OPR\//, /Edg\//, /UCBrowser\//, /SamsungBrowser\//, /FxiOS\//, /GSA\// ]
        },
        { name: 'Samsung Internet', regex: /SamsungBrowser\/(\d+\.\d+)/ },
        { 
            name: 'Safari', 
            regex: /Version\/(\d+\.\d+).*Safari/,
            exclusions: [ /Chrome\//, /Edg\//, /UCBrowser\//, /SamsungBrowser\//, /FxiOS\//, /GSA\// ]
        },
        { name: 'Firefox', regex: /(?:Firefox|FxiOS)\/(\d+\.\d+)/ },
        { name: 'Edge', regex: /Edg\/(\d+\.\d+)/ },
        { name: 'Opera', regex: /OPR\/(\d+\.\d+)/ },
        { name: 'UC Browser', regex: /UCBrowser\/(\d+\.\d+)/ },
        { name: 'Google Search App', regex: /GSA\/(\d+\.\d+\.\d+)/ },
    ];



    // Device detection
    const deviceRegexes = [
        { type: 'iPhone', regex: /iPhone/ },
        { type: 'iPad', regex: /iPad/ },
        { type: 'Android', regex: /Android/ },
        { type: 'Macintosh', regex: /Macintosh/ },
        { type: 'Windows', regex: /Windows/ },
        { type: 'Linux', regex: /Linux/ },
        { type: 'Chrome OS', regex: /CrOS/}
    ];

    // Detect browser and version
    let browserName = 'Unknown';
    let browserVersion = 'Unknown';
    for (const { name, regex, exclusions = [] } of browserRegexes) {


        const isExcluded = exclusions.some(exclusionRegex => ua.match(exclusionRegex));
        if (!isExcluded) {
            let match = ua.match(regex);
            if (match) {
                browserName = name;
                browserVersion = match[1];
                break;
            }
        }
        
    }

    // Detect device
    let deviceType = 'Unknown';
    for (const { type, regex } of deviceRegexes) {
        if (regex.test(ua)) {
            deviceType = type;
            break;
        }
    }

    return { browserName, browserVersion, deviceType };
}

export function isIPhoneAndSafari(ua) {
    const { browserName, deviceType } = parseUserAgent(ua);
    return browserName === 'Safari' && deviceType === 'iPhone';
}

export function isIPadAndSafari(ua) {
    const { browserName, deviceType } = parseUserAgent(ua);
    return browserName === 'Safari' && deviceType === 'iPad';
}

export function IsAndroidAndChrome(ua) {
    const { browserName, deviceType } = parseUserAgent(ua);
    return browserName === 'Chrome' && deviceType === 'Android';
}