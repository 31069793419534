import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getWhoIsWho } from '../../Actions/Freelancers';
import { getSubjects, getArticles, getAttachments } from '../../Actions/Infotheek';
import {generateDisplayName} from "../../Utils/Person";
import { List } from 'immutable';
import { apiUrl } from '../../Service/EndpointService';
import './Styles/Infotheek.scss';
import moment from 'moment';

// const apiUrl = window.location.href.match(':30') ? "https://freelancerportal.zorgconnect.dev" : "";

export default function Infotheek() {

    const dispatch = useDispatch();
    const subjects = useSelector(state => state.getIn(['infotheek','subjects','collection']));
    const subjectsLoading = useSelector(state => state.getIn(['infotheek','subjects','loading']));
    const articles = useSelector(state => state.getIn(['infotheek','articles','collection']));
    const articlesLoading = useSelector(state => state.getIn(['infotheek','articles','loading']));
    const attachments = useSelector(state => state.getIn(['infotheek','attachments','collection']));
    const attachmentsLoading = useSelector(state => state.getIn(['infotheek','attachments','loading']));
    const [selectedArticle, setSelectedArticle] = useState(null);

    useEffect(() => {
        if(!subjects && !subjectsLoading) {
            dispatch(getSubjects());
        }
        if(!articles && !articlesLoading) {
            dispatch(getArticles());
        }
        if(!attachments && !attachmentsLoading) {
            dispatch(getAttachments());
        }
    })

    
    //getWhoIsWho

    return <div className="card">

        <div className="card-header">
            <h1>Infotheek</h1>
        </div>

        <div className="card-body">

            <div className="infotheek">

               <Subjects onArticleSelect={(article) => setSelectedArticle(article)} />
               { selectedArticle && 
                    <ArticleDialog 
                        article={selectedArticle}
                        onClose={() => setSelectedArticle(null)} /> 
                }
            </div>


        </div>

    </div>

}

function Subjects({ onArticleSelect }) {

    const subjects = useSelector(state => state.getIn(['infotheek','subjects','collection']));
    const [expandedSubject, setExpandedSubject] = useState(null);

    return <div className="subjects">

        { (subjects || List()).toList().map((subject) => {

            return <Subject onArticleSelect={onArticleSelect}
                subject={subject}
                key={subject.get('id').toString()}
                active={subject.get('id') == expandedSubject}
                onToggle={() => setExpandedSubject(subject.get('id'))}
                />
        })}


    </div>


}

function Subject({ subject, active, onToggle, onArticleSelect }) {
    
    let articles = useSelector(state => state.getIn(['infotheek','articles','collection']));

    if(!active) {
        return <div className="subject">
            <div className="toggle" onClick={onToggle}>
                {subject.get('name')}
            </div>
        </div>
    }

    
    articles = (articles || List()).filter(x => x.get('subjectid') == subject.get('id'));

    articles = (articles || List()).sort((a,b) => {
        let aDate = moment(a.getIn(['meta','createdDate']), 'DD-MM-YYYY');
        let bDate = moment(b.getIn(['meta','createdDate']), 'DD-MM-YYYY');
        let aStamp = 0;
        let bStamp = 0;
        if(aDate.isValid()) {
            aStamp = Number(aDate.format('x'));
        }
        if(bDate.isValid()) {
            bStamp = Number(bDate.format('x'));
        }
        return bStamp - aStamp;
    })

    return <div className="subject expanded">
         <div className="toggle" onClick={onToggle}>
            {subject.get('name')}
        </div>
        <Articles articles={articles} onSelect={onArticleSelect} />
    </div>
    

} 

function Articles({ articles, onSelect }) {
    return <div className="articles">
        { articles.map((article) => {
            return <div className="article" onClick={() => onSelect(article)} key={article.get('id').toString()}>
                { article.get('name')}
            </div>
        }).toList()}
    </div>
}

function ArticleDialog({ article, onClose }) {

    // target="_blank" href={`${apiUrl}/freelancerportal/files/infotheek/${attachment.get('id')}/${attachment.get('filename')}`}

    return <Fragment>
        <div className="dialog-backdrop" onClick={onClose}></div>
        <div className="article-dialog">
            <div className="article-dialog-header">
                <button className="btn btn-danger pull-right" onClick={onClose} >Sluiten</button>
            </div>
            <div className="article-dialog-body">
                <h3>{article.get('name')}</h3>
                <div dangerouslySetInnerHTML={{ __html: article.get('content') }} />
                { (article.get('attachments') || List()).size > 0 && <Fragment>
                    <h3>Bijlagen</h3>
                    <div className="attachments">
                        {article.get('attachments').map((attachment, index) => {
                            return <a 
                                target="_blank" href={`${apiUrl}/freelancerportal/files/infotheek/${attachment.get('id')}/${attachment.get('filename')}`}
                                className="attachment" key={index.toString()}>
                                <strong>{attachment.get('name')}</strong><br />
                                <em>{attachment.get('filename')}</em>
                            </a>

                        })}
                    </div>
                </Fragment> }
            </div>
        </div>
    </Fragment>

}