
import axios from 'axios';

export const apiUrl = window.location.href.match('local.perfectstate.nl') ? "https://zf.local.perfectstate.nl" : "";
export const portalUrl = window.location.href.match('local.perfectstate.nl') ? "https://zp.local.perfectstate.nl" : "https://portal.zorgconnect.u-staat-centraal.nl";
// const apiUrl = "";
// import endpointConfiguration from 'Configuration';

const axiosConfig = {
    withCredentials: true
}

class EndpointService{

    constructor() {
        this.isPWA = false;
        const mqStandAlone = '(display-mode: standalone)';
        if(
            navigator?.standalone ||
            window.matchMedia(mqStandAlone).matches
        ) {
            this.isPWA = true;
        }
       
    }

    getCustomPWAConfig() {
        if(this.isPWA) {
            return {
                ...axiosConfig,
                headers: {
                    'PWA-Source': "zf"
                }
            }
        } else {
            return axiosConfig;
        }
    }

    getCurrentPWAString() {
        return this.isPWA ? "zf" : "";
    }

    setIsPWA(value) {
        this.isPWA = value;
    }


    get(url, extraConfig = {}){
        let config = this.getCustomPWAConfig();
        return axios.get(apiUrl + url, {...config, ...extraConfig});

    }

    post(url, params, extraConfig = {}){
        let config = this.getCustomPWAConfig();
        return axios.post(apiUrl + url, params, {...config, ...extraConfig})

    }

    delete(url){
        // return axios.delete(url);
    }

    put(url, params){
        return axios.put(apiUrl + url, params, this.getCustomPWAConfig());
    }

    processResult(response){
        // return response.data;
    }

    createDataProxy(entity){
        // return new DataProxy(entity);
    }
}

export default new EndpointService();
