import Immutable, { Map, Iterable, fromJS } from 'immutable';

export const LOAD_SUBJECTS = 'LOAD_SUBJECTS';
export const UNLOAD_SUBJECTS = 'UNLOAD_SUBJECTS';
export const UPDATE_SUBJECTS = 'UPDATE_SUBJECTS';

export const LOAD_ARTICLES = 'LOAD_ARTICLES';
export const UNLOAD_ARTICLES = 'UNLOAD_ARTICLES';
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES';

export const LOAD_ATTACHMENTS = 'LOAD_ATTACHMENTS';
export const UNLOAD_ATTACHMENTS = 'UNLOAD_ATTACHMENTS';
export const UPDATE_ATTACHMENTS = 'UPDATE_ATTACHMENTS';

export default function Infotheek(state, action) {
    switch(action.type) {
        case LOAD_SUBJECTS:
            if(action.loading) {
                state = state.setIn(['subjects','loading'], true);
            }
            if(action.collection) {
                state = state.setIn(['subjects','collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
                state = state.setIn(['subjects','loading'], false);
            }
            if(action.lastSynced) {
                state = state.setIn(['subjects','lastSynced'], action.lastSynced);
            }
            return state;
        case UNLOAD_SUBJECTS:
            return state;
        case UPDATE_SUBJECTS:
            if(action.single) {
                let single = Iterable.isIterable(action.single) ? action.single : fromJS(action.singe); 
                state = state.setIn(['subjects','collection', single.get('id')], single);
            }
            return state;
        case LOAD_ARTICLES:
            if(action.loading) {
                state = state.setIn(['articles','loading'], true);
            }
            if(action.collection) {
                state = state.setIn(['articles','collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
                state = state.setIn(['articles','loading'], false);
            }
            if(action.lastSynced) {
                state = state.setIn(['articles','lastSynced'], action.lastSynced);
            }
            return state;
        case UNLOAD_ARTICLES:
            return state;
        case UPDATE_ARTICLES:
            if(action.single) {
                let single = Iterable.isIterable(action.single) ? action.single : fromJS(action.singe); 
                state = state.setIn(['articles','collection', single.get('id')], single);
            }
            return state;
        case LOAD_ATTACHMENTS:
            if(action.loading) {
                state = state.setIn(['attachments','loading'], true);
            }
            if(action.collection) {
                state = state.setIn(['attachments','collection'], Immutable.fromJS(action.collection).groupBy(x => x.get('id')).map(x => x.first()))
                state = state.setIn(['attachments','loading'], false);
            }
            if(action.lastSynced) {
                state = state.setIn(['attachments','lastSynced'], action.lastSynced);
            }
            return state;
        case UNLOAD_ATTACHMENTS:
            return state;
        case UPDATE_ATTACHMENTS:
            if(action.single) {
                let single = Iterable.isIterable(action.single) ? action.single : fromJS(action.singe); 
                state = state.setIn(['attachments','collection', single.get('id')], single);
            }
            return state;
        default:
    }
    return state || Map();
}