import ReactServer from 'react-dom/server';
import EndpointService from './EndpointService';

class PdfService{
    renderToStatic(Component){
        return ReactServer.renderToStaticMarkup(Component);
    }

    // generatePDF(options, filename){
    //     let toGenerate = ReactServer.renderToStaticMarkup(options.content);
    //     let footer = ReactService.renderToStaticMarkup(options.footer);

    //     return new Promise((resolve, reject) => {
    //         LoaderService.executeTask({
    //             execute: EndpointService.post.bind(EndpointService, '/services/generate-pdf', { html: toGenerate, filename: filename, footer: footer }),
    //             message: "Bezig met het aanmaken van de download.",
    //             resolve: (promise) => {
    //                 promise.then(resolve).catch(reject);
    //             }
    //         });
    //     });
    // }

    // renderToStaticV2(options, filename) {

    //     let toGenerate = ReactServer.renderToStaticMarkup(options.content);
    //     let footer = ReactService.renderToStaticMarkup(options.footer);

    //     return new Promise((resolve, reject) => {
    //         LoaderService.executeTask({
    //             execute: EndpointService.post.bind(EndpointService, '/v2/services/generate-pdf', { html: toGenerate, filename: filename, footer: footer, settings: {
    //                 encoding: "UTF-8",
    //                 marginLeft: "1.25cm",
    //                 marginTop: "1.25cm",
    //                 marginRight: "1.25cm",
    //                 marginBottom: "1.25cm",
    //                 footerCenter: "Dit is een test",
    //                 debugJavascript: true

    //             } }),
    //             message: "Bezig met het aanmaken van de download.",
    //             resolve: (promise) => {
    //                 promise.then(resolve).catch(reject);
    //             }
    //         });
    //     })
    // }
}

export default new PdfService();
