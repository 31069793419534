import { LOAD_REDBOOK_ENTRIES, UNLOAD_REDBOOK_ENTRIES, UPDATE_REDBOOK_ENTRIES } from '../Reducers/RedBookEntries';
import EndpointService from '../Service/EndpointService';
import moment from 'moment';


export function getRedBookEntries(params) {
    
    let url = `/freelancerportal/api/redbook${params.clientid ? `/${params.clientid}` : ''}`;
    return (dispatch) => {
        dispatch({
            type: LOAD_REDBOOK_ENTRIES,
            loading: true
        })
        EndpointService.get(url).then((response) => {

            dispatch({
                type: LOAD_REDBOOK_ENTRIES,
                loading: false,
                lastSynced: Number(moment().subtract(5, 'm').format('x')),
                error: false,
                collection: response.data
            })
        }).catch((err) => {
            dispatch({
                type: LOAD_REDBOOK_ENTRIES,
                loading: false,
                error: true
            })
        })

    }

}

export function saveRedBookEntry(redbookEntry) {
    return EndpointService.post('/freelancerportal/api/redbook', redbookEntry).then((response) => {
        return response.data;
    })
}

export function registerRead(readData) {
    return EndpointService.post(`/freelancerportal/api/redbook/read`, readData).then((response) => {
        return response.data;
    })
}